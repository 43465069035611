import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
//import HomeIcon from '@material-ui/icons/Home';
//import axios from 'axios';
//import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../../api/Axios';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
      //display: 'flex'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

const SchoolView = ({ props, className, ...rest }) => {
  const classes = useStyles();
  const { state } = useLocation();
  const location = useLocation();

  console.log(location, 'school view location');
  const navigate = useNavigate();
  const redirect_school = (schoolId, schoolName) => {
    navigate('/app/manage/sections', { state: { schoolId, schoolName } });
  };
  const redirect_sub_school = schoolId => {
    //navigate('/app/manage/subs_school');
    navigate('/app/manage/subscriptionDetail', { state: { schoolId } });
  };

  const redirectAssignPlanSchoolSection = (schoolId, schoolName) => {
    navigate('/app/manage/assignPlanSchoolSectionView', {
      state: { schoolId, schoolName }
    });
  };
  /* const redirect = () => {
    navigate('/app/dashboard');
  }; */
  /* const [values,setValues]= useState({ 
    school_search: ''
  });  */

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: 'id',
      headerName: 'School ID',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
      
    },
    {
      field: 'name',
      headerName: 'School Name',
      width: 300,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'addressName',
      headerName: 'Address',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'accountManager',
      headerName: 'Account Manager',
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 160,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'action',
      headerName: 'Action',
      /* flex: 2, */ width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) =>
        location.pathname === '/app/manage/schools' ? (
          <div>
            <span style={{ paddingLeft: 10 }}>
            <Tooltip title="View">
            {/* <IconButton hidden={hidden} color="inherit" onClick={redirect}>
              <HomeOutlinedIcon fontSize="large" />
            </IconButton> */}
           

              <Button
                variant="contained"
                color="white"
                align="left"
                width="10px"
                
          
                // {<Tooltip title="View">}
                  
                //className={classes.button}
                startIcon={<VisibilityIcon />}

                // </Tooltip>
                onClick={() => {
                  const api: GridApi = params.api;
                  const fields = api
                    .getAllColumns()
                    .map(c => c.field)
                    .filter(c => c !== '__check__' && !!c);
                  const thisRow = {};
                  fields.forEach(f => {
                    thisRow[f] = params.getValue(f);
                  });
                  console.log(thisRow);
                  redirect_school(thisRow.id, thisRow.name);
                }}
              />
               </Tooltip>
            </span>
            <Tooltip title="Assign Plan">
            <span style={{ paddingLeft: 5 }}>
              <Button
                variant="contained"
                color="white"
                align="right"
                width="10px"
                //className={classes.button}
                startIcon={<LaunchOutlinedIcon />}
                onClick={() => {
                  const api: GridApi = params.api;
                  const fields = api
                    .getAllColumns()
                    .map(c => c.field)
                    .filter(c => c !== '__check__' && !!c);
                  const thisRow = {};
                  fields.forEach(f => {
                    thisRow[f] = params.getValue(f);
                  });
                  redirect_sub_school(thisRow.id);
                }}
              />
              
            </span>
            </Tooltip>
          </div>
        ) : (
          <div>
            <span style={{ marginLeft: 25 }}>
            <Tooltip title="Assign Plan to a Section ">
            <Button
              variant="contained"
              color="white"
              align="left"
              width="10px"
              
              //className={classes.button}
              //startIcon={<VisibilityIcon />}
              startIcon={<LaunchOutlinedIcon />}
              onClick={() => {
                const api: GridApi = params.api;
                const fields = api
                  .getAllColumns()
                  .map(c => c.field)
                  .filter(c => c !== '__check__' && !!c);
                const thisRow = {};
                fields.forEach(f => {
                  thisRow[f] = params.getValue(f);
                });
                redirectAssignPlanSchoolSection(thisRow.id, thisRow.name);
              }}
            />
            </Tooltip>
            </span>
          </div>
        ) /* ,
  flex: 1 */
    }
  ];

  useEffect(() => {
    state !== null
      ? api
          .getListOfSchoolsBasedOnAccountId(state.accountId)
          .then(response => {
            const responseTransformed = response.data.response.body.entity.data.map(
              // item => {
              //   console.log(item["school"].id);
              // }
              item => ({
               
                id: item["school"].id,
                name: item["school"].name,
                addressName: item["school"].address.address,
                location: item["school"].address.city,
                accountManager: item["school"].account_manager !== null ? 
                  item["school"].account_manager.user_obj.first_name +
                  ' ' +
                  item["school"].account_manager.user_obj.last_name 
                  :
                  "NA"
                
              })
            );
           
            console.log(response, 'response 123',response.data.response.body.entity.data);
            
            console.log(responseTransformed, 'responsssseeee');
            setRows(responseTransformed);
            //setRows(response.data.response.body);
            setLoading(false);
          })
          .catch(err => {
            console.log(err);
          })
      : api
          .getListOfAllSchools()
          .then(response => {
            const responseTransformed = response.data.response.body.entity.data.map(
              item => ({
                //...item,
                id: item.id,
                name: item.name,
                addressName: item.address.address,
                 location: item.address.city,
                accountManager: item.account_manager !== null ? 
                  item.account_manager.user_obj.first_name +
                  ' ' +
                  item.account_manager.user_obj.last_name 
                  :
                  "NA"
              })
            );
            console.log(response, 'response');
            console.log(responseTransformed, 'responseReceived');
            setRows(responseTransformed);
            setLoading(false);
          })
          .catch(err => {
            console.log(err);
          });
    
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div
      style={{
        height: 420,
        width: '100%'
      }}
      className={classes.root}
    >
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / Schools </span></div> */}
      {/* <br/> */}
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '10px'
        }}
      >
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            //justifyContent:'center',
            fontSize: 25
          }}
        >
          Schools
        </span>
       
      </div>
     
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            loading={loading}
            pageSize={5}
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default SchoolView;
