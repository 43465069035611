import * as React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} 
from '@material-ui/core';
import { styled } from '@material-ui/styles';
import CloseIcon from "@material-ui/icons/Close";

export function SimpleDialog(props) {
  const {open, handleClose} = props;

  const useStyles = makeStyles(theme => ({
    dialogSecondaryButton: {
      color: "black"
    },

    container: {
      paddingTop: '0px',
      paddingBottom: '0px',
      backgroundColor: 'palevioletred'
    }
  }));
  const classes = useStyles();
  
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle className={classes.container}>
        <Grid container justify='space-between' direction='row' alignContent='center' alignItems='center'>
         <Grid item>{children} </Grid> 
         <Grid item>
        <IconButton
          color="secondary"
          className={classes.dialogSecondaryButton}
          aria-label="Close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton> </Grid>
        </Grid>
      </DialogTitle>
    );
  };

  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <BootstrapDialogTitle>Error</BootstrapDialogTitle>
      <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {props.value}
          </DialogContentText>
        </DialogContent>
        <DialogActions dividers>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
    </BootstrapDialog>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};
