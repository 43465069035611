import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles, Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import '../../ViewGame/ViewGame.css';
import { useSelector, useDispatch } from 'react-redux';
import { showIndividualGameDetails } from '../../../../../actions';
import api from 'src/api/Axios';

const PageSize = 10;
const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    backgroundColor: theme.palette.background.dark
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    // paddingLeft: theme.spacing(5),
    // paddingRight: theme.spacing(5)
  }
}));

export default function GameVersionHistory(props) {
  const classes = useStyles();
  const [gameView, setGameView] = useState(false);
  const list = useSelector(state => state.gameList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(list?.length > 0 ? 1 : -1);
  useEffect(() => {
    const rows = [];
    if (props.gameId) {
      api
        .viewGameVersion(props.gameId)
        .then(gameResponse => {
          const data = gameResponse.data.response.body;
          data.map(item => {
            const {
              gameCode,
              gameName,
              gameLevel,
              gameSubject
            } = item.game.gameFormData;
            rows.push({
              id: item.game.gameId,
              code: gameCode,
              version: item.gameVersion,
              name: gameName,
              description: item.gameDescription,
              gamePath: item.gamePath,
              createdDate: new Date(item.createdDate),
              gameAction: item.isActive
            });
          });
          setRows(rows);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return list?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, list]);

  const handleAction = () => {
    setGameView(true);
    dispatch(showIndividualGameDetails());
  };

  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'code',
      headerName: 'Code',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'version',
      headerName: 'Version',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'gamePath',
      headerName: 'URL',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    }
  ];

  columns.push({
    field: 'gameAction',
    headerName: 'Status',
    width: 150,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    align: 'center',
    renderCell: cellValues => {
      return (
        <div
          style={{
            backgroundColor: cellValues.value ? '#adb2b7' : '#adb2b7',
            color: 'white',
            width: '110px',
            height: '50px'
          }}
        >
          {cellValues.value ? 'Active' : 'In Active'}
        </div>
      );
      // if (!props.showButton) {
      //     return (
      //         <div style={{ backgroundColor: cellValues.value ? "#adb2b7" : "#adb2b7", color: "white", width: "110px", height: "50px" }}>
      //             {cellValues.value ? "Active" : "In Active"}
      //         </div>
      //     );
      // } else {
      //     return (
      //         <Button variant="contained" color="success" style={{ backgroundColor: cellValues.value ? "#43A047" : "#E53935", color: "white", width: "110px", height: "50px" }}>
      //             {cellValues.value ? "Active" : "In Active"}
      //         </Button>
      //     );
      // }
    }
  });

  return (
    <div style={{ height: 200, width: '100%' }} className={classes.root}>
      <div style={{ display: 'flex', height: '100%', width: '100%' }}>
        <div style={{ flexGrow: 1, overflow: 'hidden' }}>
          <DataGrid rows={rows} columns={columns} />
        </div>
      </div>
    </div>
  );
}
