import React, { useState, useContext, useEffect } from 'react';
import { useStep } from 'react-hooks-helper';
import Creategame from './CreateGame';
import GameRewardView from './gameReward';
import { Review } from './Review';
import GameInformation from './GameInformation';
import GamePackage from './GamePackage';
import api from '../../../../../api/Axios';
import GameThumbnail from './GameThumbnail';
import { GameContext } from 'src/views/Configure/Context/GameContext';
import apis from 'src/api/apis';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

 const CreategameView = () => {
  const globalVariables = [
    {
      label: 'GlobalVariable1',
      value: 'globalVariable1'
    },
    {
      label: 'GlobalVariable2',
      value: 'globalVariable2'
    },
    {
      label: 'GlobalVariable3',
      value: 'globalVariable3'
    },
    {
      label: 'GlobalVariable4',
      value: 'globalVariable4'
    },
    {
      label: 'GlobalVariable5',
      value: 'globalVariable5'
    }
  ];

  const levelWiseVariables = [
    {
      label: 'LevelWiseVariable1',
      value: 'levelWiseVariable1'
    },
    {
      label: 'LevelWiseVariable2',
      value: 'levelWiseVariable2'
    },
    {
      label: 'LevelWiseVariable3',
      value: 'levelWiseVariable3'
    },
    {
      label: 'LevelWiseVariable4',
      value: 'levelWiseVariable4'
    },
    {
      label: 'LevelWiseVariable5',
      value: 'levelWiseVariable5'
    }
  ];

  const { gameFormData, informationData, isEditable, packageData } = useContext(
    GameContext
  );
  const [formData, setFormData] = useState(gameFormData);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [gamePackage, setGamePackage] = useState([]);
  const [gameLevelRewardPoints, setGameLevelRewardPoints] = useState([]);
  const [variables, setVariables] = useState(globalVariables);
  const [checkedGlobalVariables, setCheckedGlobalVariables] = useState([]);
  const [checkedLevelWiseVariables, setCheckedLevelWiseVariables] = useState(
    []
  );
  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [thumbnailType, setThumbNailType] = useState('URL');
  const [gameImageType, setGameImageType] = useState('URL');

  const [gameData, setGameData] = useState(null);
  const [globalVarButton, setGlobalVarButton] = useState(true);
  const [gameInformationData, setGameInformationData] = useState(
    informationData
  );
  const [gameThumbnails, setGameThumbnails] = useState(null);

  const [gamePackageData, setGamePackageData] = useState(packageData);
  const [loading, setLoading] = useState(false);
  const globalVariablesSelected = checkedGlobalVariables.map(
    item => item.label
  );
  const levelWiseVariablesSelected = checkedLevelWiseVariables.map(
    item => item.label
  );
  const steps = [
    { id: 'createGame' },
    { id: 'gamePackage' },
    { id: 'review' }
    //{ id: 'submit' }
  ];
  const { id } = useParams();
  console.log(id, 'id');
  const { step, navigation } = useStep({
    steps,
    initialStep: 0
  });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleGameLevelRewardPoints = (event, index) => {
    const { name, value } = event.target;
    if (/^(\s?|\d+)$/.test(value)) {
      const newList = [...gameLevelRewardPoints];
      newList[index][name] = value;
      setGameLevelRewardPoints(newList);
    } else {
      alert('Please enter only numbers');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickVariable = name => {
    if (name === 'global') {
      setVariables(globalVariables);
      setGlobalVarButton(true);
    } else {
      setVariables(levelWiseVariables);
      setGlobalVarButton(false);
    }
  };
  const handleGameInformationChanges = event => {
    setGameInformationData({
      ...gameInformationData,
      [event.target.name]: event.target.value
    });
  };

  const handleGamePackageChanges = event => {
    setGamePackageData({
      ...gamePackageData,
      [event.target.name]: event.target.value
    });
    // if(event.target.value==="URL"){
    //   setGamePackageData({
    //     ...gamePackageData,
    //     gamePackageImages: [],
    //     [event.target.name]: event.target.value
    //   });
    // }
  };
  useEffect(() => {
    setLoading(true);
    apis
      .get(`/get/game/${id}`)
      .then(res => {
        console.log(res.data.response.body, 'hhhs');
        const data = res.data.response.body;
        const tempPackage = JSON.parse(res.data.response.body.gamePackageData)
        const formData = {
          gameName: data.gameFormData.gameName,
          gameCode: data.gameFormData.gameCode,
          gameType: data.gameFormData.gameType,
          gameSubject: data.gameFormData.gameSubject,
          gameConcept: data.gameFormData.gameConcept,
          gameSkill: data.gameFormData.gameSkill,
          gameLevel: data.gameFormData.gameLevel,
          gameId:data.gameId
        };

        const gameLevelRewardPointstemp = {
          gameLevelRewardPoints: JSON.parse(data.gameLevelRewardPoints)
        };
        const temp = { ...gameData };
        temp.formData = formData;
        temp.gameInformationData = JSON.parse(data.gameInformationData);
        temp.gameLevelRewardPoints = gameLevelRewardPointstemp;
        setGamePackage
        setGameData(temp);
        // setGamePackageData((prev) => [
        //   ...prev,
        //   {
        //     gameURL: data.gamePackage,
        //     gamePackageUploadType: 'URL'
        //   }
        // ]);
        setGamePackageData({  
          gameURL: data.gamePackage,
          gamePackageUploadType: 'URL',
          gameVersion:tempPackage.gameVersion
        })
        setLoading(false)
      })
      .catch(err => {
        console.error(err);
      });
   
  }, [id]);
  const handlePost = (setModalOpen, data = null) => {
    if (thumbnailFile.length > 2) {
      toast.error('Max  2 thumbnails can be uploaded');
      return;
    }
    if (image.length > 2) {
      toast.error('Max 2 game images can be uploaded');
      return;
    }
    let newFormData = new FormData();
    newFormData.append('formData', JSON.stringify(gameData.formData));
    newFormData.append(
      'gamePackageData',
      JSON.stringify({
        versionHistory: gamePackageData.gameDescription,
        gaveVersion: gamePackageData.gameVersion,
        uploadType: gamePackageData.iconPackageUploadType,
        gameUrl: gamePackageData.gameURL
      })
    );
    newFormData.append(
      'gameInformationData',
      JSON.stringify(gameData?.gameInformationData)
    );
    newFormData.append(
      'gameLevelRewardPoints',
      JSON.stringify(gameData.gameLevelRewardPoints?.gameLevelRewardPoints)
    );
    newFormData.append('thumbnailMobUrl', gameThumbnails?.thumbnailMobUrl);
    newFormData.append('thumbnailMobUrl2', gameThumbnails?.thumbnailMobUrl2);
    newFormData.append('gameImageUrl', gameThumbnails?.gameImageUrl);
    newFormData.append('gameImageUrl2', gameThumbnails?.gameImageUrl2);
    newFormData.append(
      'createdById',
      localStorage.getItem('userId') == null
        ? '0'
        : localStorage.getItem('userId').toString()
    );
    newFormData.append('createdBy', '0');
    newFormData.append('createdDate', JSON.stringify(new Date()));
    if (gamePackage[0]) {
      newFormData.append('gamePackage', gamePackage[0] || null);
    }

    newFormData.append('gamePackageUrl', gamePackageData?.gameURL);
    if (image[0]) {
      newFormData.append('imageFile1', image[0] || null);
    }

    if (image[1]) {
      newFormData.append('imageFile2', image[1] || null);
    }
    if (thumbnailFile[0]) {
      newFormData.append('thumbnailMobFile', thumbnailFile[0] || null);
    }
    if (thumbnailFile[1]) {
      newFormData.append('thumbnailwebFile', thumbnailFile[1] || null);
    }

    console.log(newFormData, '========================');
    if (id) {
      apis
        .post(`/updateGame/${id}`, newFormData)
        .then(res => {
          setModalOpen(true);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      apis
        .post('/addGame', newFormData)
        .then(() => {
          setModalOpen(true);
        })
        .catch(err => {
          console.log(err);
        });
    }

    // if (isEditable) {
    //   api
    //     .updateGame(newFormData, formData.gameId)
    //     .then(response => {
    //       setModalOpen(true);
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // } else {
    //   api
    //     .addGame(bodyData)
    //     .then(response => {
    //       addGameVersion(
    //         gamePackageUpdatedDetails,
    //         formData,
    //         response.data.response.body.message
    //       );
    //       setModalOpen(true);
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }
  };
console.log(gamePackageData,"gfdg");
  const addGameVersion = (gamePackageData, formData, newlyGameId) => {
    let versionGameDetails = new FormData();
    const versionDetails = {
      gameId: newlyGameId,
      gamePath: gamePackageData.gameURL,
      gameVersion: gamePackageData.gameVersion,
      isActive: true,
      gameMobImgPath: gamePackageData.gameThumbnailUrl,
      gameWebImgPath: gamePackageData.gameImageUrl,
      createdBy: JSON.stringify(
        localStorage.getItem('createdBy') == null
          ? '0'
          : localStorage.getItem('createdBy')
      ),
      gameDescription: gamePackageData.gameDescription
    };
    versionGameDetails.append('gameVersionDTO', JSON.stringify(versionDetails));
    api.addGameVersion(versionGameDetails);
  };
  const props = {
    formData,
    setFormData,
    step,
    navigation,
    handleChange,
    open,
    setOpen,
    handleClickOpen,
    handleClose,
    image,
    setImage,
    gameLevelRewardPoints,
    setGameLevelRewardPoints,
    handleGameLevelRewardPoints,
    variables,
    setVariables,
    checkedGlobalVariables,
    setCheckedGlobalVariables,
    handleClickVariable,
    globalVariablesSelected,
    checkedLevelWiseVariables,
    setCheckedLevelWiseVariables,
    globalVarButton,
    levelWiseVariablesSelected,
    gameInformationData,
    handleGameInformationChanges,
    gamePackageData,
    setGamePackageData,
    setGameInformationData,
    handleGamePackageChanges,
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
    handlePost,
    isEditable,
    gameData,
    setGameData,
    gamePackage,
    setGamePackage,
    thumbnailFile,
    setThumbnailFile,
    gameThumbnails,
    setGameThumbnails,
    thumbnailType,
    setThumbNailType,
    gameImageType,
    setGameImageType
  };
 
    switch (step.id) {
      case 'createGame':
        return <Creategame {...props} />;
      /* case 'gameLevel':
      return <GameRewardView {...props} />;*/
      case 'gamePackage':
        return <GamePackage {...props} />;
      case 'review':
        return (
          <>
            <GameThumbnail {...props} />
          </>
        );
      default:
        <h1>not proper game id </h1>;
      /* case 'submit':
      return <Creategame {...props} />; */
    
  }
};

export default CreategameView;
