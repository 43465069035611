import * as React from 'react';
//import { useState } from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../../api/Axios';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white',
      display: 'flex'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));


const Suscription_SectionsView = ({ props,className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const role = localStorage.getItem('role');
  const { state } = useLocation();
  console.log(state,"subscriptionnnn")


  const redirect_license = () => {
    navigate('/app/manage/licenseList');
  };
  const redirect_sub_add = () => {
     navigate('/app/manage/addSubscription');
    //navigate('/app/manage/addSubscription', { state: { schoolId } });
  };
  const redirect = () => {
    navigate('/app/dashboard');
  };
  const [values, setValues] = useState({
    school_search: ''
  });

 


  const columns = [
    {
      field: 'noOfChildren',
      headerName: 'Number Of Children',
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'utilization',
      headerName: 'Utilization',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      /* width: 280, */ headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => (
        <div>
          <Button
            variant="contained"
            color="white"
            align="left"
            width="10px"
            style={{marginLeft:'45%'}}
            //className={classes.button}
            startIcon={<VisibilityIcon />}
            onClick={redirect_license}
          ></Button>
        </div>
      ) /* ,
  flex: 1 */
    }
  ];

  useEffect(() => {
    state !== null
      ? api
          .getAllColumns(1)
          .then(response => {
            const responseTransformed = response.data.response.body.entity.data.map(
             console.log(state.schoolId,"sub sectttttt")
          
              // item => ({
               
              //   id: item["school"].id,
              //   name: item["school"].name,
              //   addressName: item["school"].address.address,
              //   location: item["school"].address.city,
              //   accountManager: item["school"].account_manager !== null ? 
              //     item["school"].account_manager.user_obj.first_name +
              //     ' ' +
              //     item["school"].account_manager.user_obj.last_name 
              //     :
              //     "NA"
                
              // })
            );
           
             //console.log(response, 'response 123',response.data.response.body.entity.data);
            
            // console.log(responseTransformed, 'responsssseeee');
            // setRows(responseTransformed);
            //setRows(response.data.response.body);
            // setLoading(false);
          })
          .catch(err => {
            console.log(err);
          })
      : api
          .getListOfAllSchools()
          .then(response => {
            console.log(response,"subsectionnnnnnnn")
            const responseTransformed = response.data.response.body.entity.data.map(
              item => 
              ({
                ...item,
                
                id: item.id,
                name: item.name,
                addressName: item.address.address,
                 location: item.address.city,
                accountManager: item.account_manager !== null ? 
                  item.account_manager.user_obj.first_name +
                  ' ' +
                  item.account_manager.user_obj.last_name 
                  :
                  "NA"
             })
            );
            console.log(response, 'response');
            console.log(responseTransformed, 'responseReceived');
            setRows(responseTransformed);
           // setLoading(false);
          })
          .catch(err => {
            console.log(err);
          });
    /* api
      .getListOfAllSchools()
      .getListOfSchoolsBasedOnAccountId(state.accountId) */
    /* axios
      .get(
        'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/schools',
        {
          header: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      ) */
  }, []);
  
  // const rows = [
  //   {
  //     id: 1,
  //     noOfChildren: '10',
  //     start_date: '18-March-2020',
  //     end_date: '17-March-2021',
  //     utilization: '6/10',
  //     status: 'Inactive',
  //     action: 'My School'
  //   },
  //   {
  //     id: 2,
  //     noOfChildren: '50',
  //     start_date: '10-March-2020',
  //     end_date: '09-April-2022',
  //     utilization: '10/50',
  //     status: 'Active',
  //     action: 'My School'
  //   },
  //   {
  //     id: 3,
  //     noOfChildren: '10',
  //     start_date: '18-March-2020',
  //     end_date: '17-March-2021',
  //     utilization: '6/10',
  //     status: 'Inactive',
  //     action: 'My School'
  //   },
  //   {
  //     id: 4,
  //     noOfChildren: '50',
  //     start_date: '10-March-2020',
  //     end_date: '09-April-2022',
  //     utilization: '10/50',
  //     status: 'Active',
  //     action: 'My School'
  //   }
  //   /*{ id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  // { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  // { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  // { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  // { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  // { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  // { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  // { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },*/
  // ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 420, width: '100%' }} className={classes.root}>
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / Schools / Subscription Details - Section </span></div> */}
      {/* <br/> */}
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '10px'
        }}
      >
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */ fontSize: 25
          }}
        >
          School-1 Section-Name Subscription Details
        </span>
        <span style={{ float: 'right', paddingLeft: 2 }}>
          <Tooltip title="Add subscription">
            <Button
              color="primary"
              variant="contained"
              onClick={redirect_sub_add}
              disabled={role === 'user' ? true : false}
            >
              ADD SUBSCRIPTION
            </Button>
          </Tooltip>
        </span>
        {/* <span style={{float: 'right'}}>
        <Tooltip title="Go to Home" >
          <Button
            color="primary"
            variant="contained"
            startIcon={<HomeIcon />}
            onClick={redirect}
          >
            Home
          </Button>
        </Tooltip>
      </span> */}
      </div>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            pageSize="5"
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default Suscription_SectionsView;
