import React, { useEffect,useState } from 'react';
import { Radio, RadioGroup, Grid, FormControlLabel } from '@material-ui/core';

const GameDetailsPartThree = (props) => {
  const {gamePackageData} = props.selectedGameData;
  const [data, setData] = useState({});
  useEffect(() => {
    if (gamePackageData) {
        try {
            const parsedData = JSON.parse(gamePackageData);
            setData(parsedData)
        }catch(e){
            console.log("not a proper data"+e);
        }
    }
  }, [gamePackageData])
  return (
    <>
      <div style={{paddingBottom: '20px', fontSize: '20px'}}>
        <b>Game Images</b>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
            <Grid item md={2} ><div style={{fontWeight: '600',textDecoration: 'underline'}}>Image Url :</div></Grid>
            <Grid item md={10}><div>{data.gameImageUrl}</div></Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2}>
                <Grid item md={2} ><div style={{fontWeight: '600',textDecoration: 'underline'}}>Thumbnail Image Url :</div> </Grid>
                <Grid item md={10}><div>{data.gameThumbnailUrl}</div></Grid>
            </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default GameDetailsPartThree;
