import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  form,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft:theme.spacing(15),
    paddingRight:theme.spacing(3)
  }
}));

const DeleteAdminUserView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [values,setValues]= useState({ 
    firstName: '',
    lastName:'',
    emailId:'',
    mobileNumber:'',
    address1:'',
    address2:'',
    city:'',
    state:'',
    country:'',
    pincode:'',
});

const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    /* const token=localStorage.getItem('Auth');
    const t=token.replace(/['"]+/g, '')
    const Auth=`Bearer ${t}`;
    console.log(Auth)
    console.log(values.stockName)
    
    const data = {
      gameName: values.gameName,
      gameType: values.gameType,
      gameCode:values.gameCode,
      subject:values.subject,
      

    };
    
    
   
    axios.post('http://localhost:3000/transaction', data ,
    {
      headers:{
        'Authorization':Auth,
        'Accept':'application/json',
        'content-Type':'application/json'
      }
    })
      .then(res => {
        console.log(res);
       
        if(res.data.error!=="Please authenticate")
        {
          console.log(res.data.balance);
          localStorage.setItem("Balance",res.data.user.balance)
          navigate('/app/dashboard', { replace: true });
          
         

        }
        else
        {
          alert('You are not Allowed for Transaction ,Authentication needed')
        }
       
      });*/
  }



  return (
    <div>
    <div style={{textAlign:'center', marginLeft:'8%'}} >
    <span className={classes.title} align='center' style={{color: '#e91e63', }}>
    Delete Admin User</span>
    </div>
    <br/>

    <form
      autoComplete="off"
      align='center'
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
    <Card container spacing={4}>
        <CardHeader subheader="Please enter the Admin User details for deletion" title="Delete Admin User"     />
       
        <Divider />
        <CardContent>
          <Grid  container  spacing={3}  >
          
            <Grid   item md={12}   xs={12} >
             
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
           >
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid   item md={12}   xs={12} >
              <TextField
                fullWidth
                label="E-mail Id"
                name="emailId"
                onChange={handleChange}
                required
              
                value={values.emailId}
                variant="outlined"
              >
              </TextField>
            </Grid>
            <Grid   item md={12}   xs={12} >
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobileNumber"
                onChange={handleChange}
                required
                value={values.mobileNumber}
                variant="outlined"
              />
            </Grid>
            <Grid   item md={12}   xs={12} >
              <TextField
                fullWidth
                label="Address 1"
                name="address1"
                onChange={handleChange}
                required
                value={values.address1}
                variant="outlined"
              />
            </Grid>
            <Grid   item md={12}   xs={12} >
              <TextField
                fullWidth
                label="Address 2"
                name="address2"
                onChange={handleChange}
                required
                value={values.address2}
                variant="outlined"
              />
            </Grid>
            <Grid   item md={12}   xs={12} >
              <TextField
                fullWidth
                label="City"
                name="city"
                onChange={handleChange}
                required
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid   item md={12}   xs={12} >
              <TextField
                fullWidth
                label="State"
                name="state"
                onChange={handleChange}
                required
                value={values.state}
                variant="outlined"
              />
            </Grid>
            <Grid   item md={12}   xs={12} >
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                variant="outlined"
              />
            </Grid>
            <Grid   item md={12}   xs={12} >
              <TextField
                fullWidth
                label="Pincode"
                name="pincode"
                onChange={handleChange}
                required
                value={values.pincode}
                variant="outlined"
              />
            </Grid>            
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
        
          <Button
            color="primary"
            
            variant="contained"
            onClick={handleSubmit}
          >
            Delete
          </Button>
        </Box>
      </Card>
    </form>
 </div>
  );
};

DeleteAdminUserView.propTypes = {
  className: PropTypes.string
};

export default DeleteAdminUserView;
