import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CardActions from '@material-ui/core/CardActions';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Chip
} from '@material-ui/core';
import GameRewardLevel from './GameRewardLevel';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

const GameRewardView = ({
  formData,
  setFormData,
  step,
  navigation,
  open,
  setOpen,
  handleClickOpen,
  handleClose,
  handleChange,
  className,
  gameLevelRewardPoints,
  setGameLevelRewardPoints,
  handleGameLevelRewardPoints,
  variables,
  setVariables,
  checkedGlobalVariables,
  setCheckedGlobalVariables,
  handleClickVariable,
  handleClickLevelWiseVar,
  gameVariables,
  globalVariablesSelected,
  handleVariableClick,
  checkedLevelWiseVariables,
  setCheckedLevelWiseVariables,
  globalVarButton,
  globalLevelWiseButton,
  levelWiseVariablesSelected,
  activeStep,
  handleNext,
  handleBack,
  ...rest
}) => {
  const classes = useStyles();
  const data = {
    formData,
    gameLevelRewardPoints,
    handleGameLevelRewardPoints
  };
  const [errors, setErrors] = useState({
    gameLevel: '0'
    //imageUploaded: ''
  });
  const handleAddGameLevel = () => {
    const arr = [...Array(Number(formData.gameLevel)).keys()];
    const newArr = arr.map(item => {
      let newItem = { gameRewardPoint: '' };
      return newItem;
    });
    setGameLevelRewardPoints(newArr);
  };
  const [disabled, setDisabled] = useState(true);
  const dataProp = {
    variables,
    checkedGlobalVariables,
    setCheckedGlobalVariables,
    handleVariableClick,
    checkedLevelWiseVariables,
    setCheckedLevelWiseVariables,
    globalVarButton,
    globalLevelWiseButton
  };

  const handleGameLevelValidation = event => {
    setFormData({
      ...formData,
      gameLevel: event.target.value
    });
    validate({ [event.target.name]: event.target.value });
  };

  const validate = (fieldValues = formData) => {
    let temp = { ...errors };
    if ('gameLevel' in fieldValues) {
      temp.gameLevel = /^\d+$/.test(fieldValues.gameLevel)
        ? ''
        : 'Please Enter Numerals';
    }
    setErrors({
      ...temp
    });
    setDisabled(!Object.values(temp).every(x => x == ''));
    console.log(disabled);
    console.log(errors);
  };

  return (
    <div>
      <div>
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card style={{ margin: 'auto' }}>
            <CardContent>
             <Grid
                style={{ display: 'flex', alignItems: 'center' }}
                container
                spacing={2}
              >
                <Grid item xs={4} md={4}>
                  <Typography>Game Levels</Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <TextField
                    variant="outlined"
                    name="gameLevel"
                    placeholder="Enter the number of game levels"
                    margin="dense"
                    fullWidth
                    value={formData.gameLevel}
                    onChange={handleGameLevelValidation}
                    required
                    {...(errors.gameLevel &&
                      errors.gameLevel !== '0' && {
                        error: true,
                        helperText: errors.gameLevel
                      })}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAddGameLevel}
                    style={{
                      textTransform: 'none',
                      fontSize: '1rem'
                    }}
                    disabled={disabled}
                    {...(formData.gameLevel &&
                      errors.gameLevel === '0' && {
                        disabled: false
                      })}
                  >
                    Create
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Reward points</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <GameRewardLevel {...data} />
                </Grid>
              </Grid>
              <br />
              <Divider />
              <br />
              <Grid
                container
                spacing={2}
                style={{ display: 'flex', alignItems: 'center' }}
              >
              <Grid item xs={12}>
                  {globalVariablesSelected.map(item => (
                    <Chip
                      key={item}
                      label={item}
                      style={{
                        margin: '3px',
                        backgroundColor: '#008080',
                        color: 'white'
                      }}
                    />
                  ))}
                </Grid>
                <br />
             </Grid>
              <br />
              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigation.previous();
                    handleBack();
                  }}
                  style={{
                    textTransform: 'none',
                    fontSize: '1rem'
                  }}
                >
                  Back
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigation.next();
                    handleNext();
                    console.log(gameLevelRewardPoints);
                  }}
                  style={{
                    textTransform: 'none',
                    fontSize: '1rem'
                  }}
                >
                  Save & Continue
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </form>
      </div>
    </div>
  );
};

GameRewardView.propTypes = {
  className: PropTypes.string
};

export default GameRewardView;
