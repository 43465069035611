const initialState = {
    tasks: ['I am a task'],
    openGameNameDialog:false,
    openTableDialog: false,
    individualGame:{
        isShow:false,
        activeIndex:1
    },
    selectedGamesForPlan:[]
};

const appReducer = (state = initialState, action) => {
    switch(action.type){
        case 'ADD_TASK':
            return {
                ...state,
                tasks: state.tasks.concat(action.data),
            };
        case 'SHOW_INDIVIDUAL_GAME':
                return {
                    ...state,
                    individualGame:  action.data,
        }
        case 'CHANGE_GAME_DETAIL_INDEX':
            console.log("ind->",action.data)
                return {
                    ...state,
                    individualGame: {
                       ...state.individualGame,
                        activeIndex:action.data
                    },
        }
        case 'OPEN_GAME_DIALOG':
                return {
                    ...state,openGameNameDialog:action.data
        }
        case 'OPEN_TABLE_DIALOG':
                return {
                    ...state,openTableDialog:action.data
        }
        case 'SET_SELECTED_GAMES':
            return {
                ...state,selectedGamesForPlan:action.data
        }
        default:
            return state;
    }
};

export default appReducer;

