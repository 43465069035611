import * as React from 'react';
import { useReducer, useState } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme)=>({
    root: {
      '& .super-app-theme--header': {
        backgroundColor:"#008080",
        color: 'white'
      },
      paddingLeft:theme.spacing(5),
    paddingRight:theme.spacing(5)
    },
  }));
  
  const formReducer = (state, event) => {
    return {
      ...state,
      [event.name]: event.value
    }
   }

const Suscription_AddView = ({ className, ...rest }) => {
  
    const classes = useStyles();
    const [values,setValues]= useState({ 
        school_search: '',

}); 
    const [formData, setFormData] = useReducer(formReducer, {});
    const [submitting, setSubmitting] = useState(false);
    const handleSubmit = event => {
    event.preventDefault();
    setSubmitting(true);

    setTimeout(() => {
    setSubmitting(false);
    }, 3000)
    }

    const handleChange = event => {
        setFormData({
          name: event.target.name,
          value: event.target.value,
        });
      }

return (
  <div style={{ height: 400, width: '100%' }} className={classes.root}>
    {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / Schools / Subscription Add </span></div> */}
    {/* <br/> */}
    <div style={{textAlign:'center'}} ><span className={classes.title} align='center' style={{color: '#e91e63', justifyContent:'center', fontSize: 25}}>
    School-1 Suscription Add</span></div>
    <div> 
        {submitting &&
        <div>You are submitting the following:
            <ul>
                {Object.entries(formData).map(([name, value]) => (
                    <li key={name}><strong>{name}</strong>:{value.toString()}</li>
                ))}
            </ul>
        </div>
        }
        <form onSubmit={handleSubmit}>
            <fieldset>
            <label>
                <p>Name</p>
                <input name="name" onChange={handleChange} />
            </label>
            </fieldset>
            <Button
            color="primary"
            variant="contained"
            type="submit">Submit</Button>
        </form>

    </div>
    
  </div>
);

};

export default Suscription_AddView;
