import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardActions from '@material-ui/core/CardActions';
import {
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
  Grid,
  Chip,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import { useForm } from 'react-hook-form';
import { KSelect } from '../../../../common-components/KSelect';
import { KTextField } from '../../../../common-components/KTextField';
import api from 'src/api/Axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import GameRewardLevel from './GameRewardLevel';

const useStyles = makeStyles(theme => ({
  root: {
    //display: 'flex',
    backgroundColor: theme.palette.background.dark,
    //height: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  cardHeader: {
    textAlign: 'center',
    color: '#e91e63',
    fontSize: 25
  }
}));

function createSchema(isEditable) {
  const schema = yup.object().shape({
    gameName: yup
      .string()
      .required('Please enter Game Name in Alphanumeric format'),
    gameCode: yup
      .string()
      .required('Please enter Game Code in Alphanumeric format'),
    gameTech: yup.string().required('Please enter Game Tech'),
    gameType: yup.string().required('Please Select Game Type'),
    gameSubject: yup.string().required('Please Select Game Subject'),
    gameConcept: yup.string().required('Please Select Game Concept'),
    gameSkill: yup.string().required('Please Select Game Skill')
  });
  return schema;
}

const Creategame = ({
  formData,
  setFormData,
  setGameInformationData,
  image,
  setImage,
  step,
  navigation,
  handleChange,
  className,
  activeStep,
  handleNext,
  handleBack,
  gameData,
  setGameData,
  // setGameLevelRewardPoints,
  globalVariablesSelected,
  // gameLevelRewardPoints,
  // handleGameLevelRewardPoints,
  handleGameInformationChanges,
  gameInformationData,
  isEditable,
  ...rest
}) => {
  const classes = useStyles();
  const [gameName, setGameName] = useState('');
  const [gameCode, setGameCode] = useState('');
  const [gameType, setGameType] = useState('');
  const [gameSubject, setGameSubject] = useState('');
  const [gameConcept, setGameConcept] = useState('');
  const [gameSkill, setGameSkill] = useState('');
  const [gameLevel, setGameLevel] = useState(0);
  const [gameTech, setGameTech] = useState('');
  const [description, setDescripion] = useState('');
  const [objective, setGameObjective] = useState('');
  const [learning, setLearning] = useState('');
  const [error, setError] = useState(null);
  const [gameLevelRewardPoints, setGameLevelRewardPoints] = useState([]);
  const [gameSubjectOption, setGameSubjectOption] = useState([
    { label: 'subject1', value: 'subject1' }
  ]);
  const [loading,setLoading] = useState(false);
  const [gameConceptOption, setGameConceptOption] = useState([]);
  const [gameTypeOption, setGameTypeOption] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setLoading(true);
    const data = { ...gameData };
    setGameName(data?.formData?.gameName);
    setGameCode(data?.formData?.gameCode);
    setGameType(data?.formData?.gameType);
    setGameSubject(data?.formData?.gameSubject);
    setGameConcept(data?.formData?.gameConcept);
    setGameSkill(data?.formData?.gameSkill);
    setGameLevel(data?.gameLevelRewardPoints?.gameLevelRewardPoints?.length||0);
    setGameLevelRewardPoints(data?.gameLevelRewardPoints?.gameLevelRewardPoints || []);
    setGameTech(data?.gameInformationData?.gameTech)
    setDescripion(data?.gameInformationData?.description);
    setGameObjective(data?.gameInformationData?.objectives)
    setLearning(data?.gameInformationData?.outcome)
    console.log(data,"222")
    setLoading(false)
  }, []); 

  const handleSubmit = e => {
    e.preventDefault();
    if (gameName?.length < 1) { 
      setError({
        ...error,
        gameName: 'Game Name is required'  
      });
      return;
    }
    const formData = {
      gameName: gameName,
      gameCode: gameCode,
      gameType: gameType,
      gameSubject: gameSubject,
      gameConcept: gameConcept,
      gameSkill: gameSkill,
      gameLevel: gameLevel
    };
    const gameInformationData = {
      gameTech: gameTech,
      description: description,
      objectives: objective,
      learning: learning
    };
    const gameLevelRewardPointstemp = {
      gameLevelRewardPoints: gameLevelRewardPoints
    };
    const temp = { ...gameData };
    temp.formData = formData;
    temp.gameInformationData = gameInformationData;
    temp.gameLevelRewardPoints = gameLevelRewardPointstemp;
    setGameData(temp);
    navigation.next();
  };

  const handleGameLevelRewardPoints = (event, index) => {
    const { name, value } = event.target;
    if (/^(\s?|\d+)$/.test(value)) {
      const newList = [...gameLevelRewardPoints];
      newList[index][name] = value;
      setGameLevelRewardPoints(newList);
    } else {
      alert('Please enter only numbers');
    }
  };
  const validate = (fieldValues = formData) => {
    let temp = { ...error };
    if ('gameLevel' in fieldValues) {
      temp.gameLevel = /^\d+$/.test(fieldValues.gameLevel)
        ? ''
        : 'Please Enter Numerals';
    }
    /*setErrors({
      ...temp
    });*/
    setDisabled(!Object.values(temp).every(x => x == ''));
  };
  const handleGameLevelValidation = event => {
    setGameLevel(event.target.value);
    validate({ [event.target.name]: event.target.value });
  };
 
  const handleAddGameLevel = () => {
    const arr = [...Array(Number(gameLevel)).keys()];
    const newArr = arr.map(item => {
      let newItem = { gameRewardPoint: '' };
      return newItem;
    });
    setGameLevelRewardPoints(newArr);
  };

  useEffect(() => {
    api.getCatelog('GameType').then(options => setGameTypeOption(options));
    //TESTING PURPOSE
 
    // api
    //   .getCatelog('GameSubject')
    //   .then(options => setGameSubjectOption(options));
    api
      .getCatelog('GameConcept')
      .then(options => setGameConceptOption(options));
  }, []);

  //this should coem via api
  const gameSkillOption = [
    {
      label: 'GameSub1',
      value: 'GameSub1' 
    },
    {
      label: 'GameSub2',
      value: 'GameSub2'
    },
    {
      label: 'GameSub3',
      value: 'GameSub3'
    },
    {
      label: 'GameSub4',
      value: 'GameSub4'
    }
  ];
  const generateSingleOptions = selectOptions => {
    return selectOptions.map(option => {
      return <MenuItem value={option.value}>{option.label}</MenuItem>;
    });
  };
  return (
    <div>
      {loading?<div>loading</div>:<>
      <div className={classes.cardHeader}>Create Game V2</div>
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
        className={classes.root}
        {...rest}
      >
        <Card style={{ margin: 'auto' }}>
          <CardContent style={{ paddingTop: '8px' }}>
            <MobileStepper
              variant="dots"
              steps={3}
              position="static"
              activeStep={activeStep}
              style={{
                float: 'right',
                maxWidth: 400,
                flexGrow: 1
              }}
            />
            <Grid
              container
              spacing={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item xs={3} md={3}>
                <Typography>Game Name</Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                {/* <KTextField name="gameName" control={control} /> */}
                <TextField
                  // rows={5}
                  // maxRow={10}
                  helperText={error?.gameName ? error?.gameName : null}
                  error={error?.gameName}
                  onChange={e => setGameName(e.target.value)}
                  value={gameName}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography>Game Code</Typography> 
              </Grid>
              <Grid item xs={9} md={9}>
                <TextField
                  // rows={5}
                  // maxRow={10}
                  helperText={error?.gameCode ? error?.gameCode : null}
                  error={error?.gameCode}
                  onChange={e => setGameCode(e.target.value)}
                  value={gameCode}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography>Game Type</Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                {/* <KSelect
                  name="gameType"
                  control={control}
                  options={gameTypeOption}
                /> */}
                <TextField
                  helperText={error?.gameType ? error?.gameType : null}
                  error={error?.gameType}
                  onChange={e => setGameType(e.target.value)}
                  value={gameType}
                  fullWidth
                  // label={label}
                  margin="dense"
                  variant="outlined"
                  select
                >
                  {generateSingleOptions(gameTypeOption)}
                </TextField>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography>Game Subject</Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                {/* <KSelect
                  name="gameSubject"
                  control={control}
                  options={gameSubjectOption}
                /> */}
                <TextField
                  helperText={error?.gameSubject ? error?.gameSubject : null}
                  error={error?.gameSubject}
                  onChange={e => setGameSubject(e.target.value)}
                  value={gameSubject}
                  fullWidth
                  // label={label}
                  margin="dense"
                  variant="outlined"
                  select
                >
                  {generateSingleOptions(gameSubjectOption)}
                </TextField>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography>Game Concept</Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                {/* <KSelect
                  name="gameConcept"
                  control={control}
                  options={gameConceptOption}
                /> */}
                <TextField
                  helperText={error?.gameConcept ? error?.gameConcept : null}
                  error={error?.gameConcept}
                  onChange={e => setGameConcept(e.target.value)}
                  value={gameConcept}
                  fullWidth
                  // label={label}
                  margin="dense"
                  variant="outlined"
                  select
                >
                  {generateSingleOptions(gameSubjectOption)}
                </TextField>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography>Game Skill</Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                {/* <KSelect
                  name="gameSkill"
                  control={control}
                  options={gameSkillOption}
                /> */}
                <TextField
                  helperText={error?.gameSkill ? error?.gameSkill : null}
                  error={error?.gameSkill}
                  onChange={e => setGameSkill(e.target.value)}
                  value={gameSkill}
                  fullWidth
                  // label={label}
                  margin="dense"
                  variant="outlined"
                  select
                >
                  {generateSingleOptions(gameSkillOption)}
                </TextField>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item xs={3} md={3}>
                <Typography>
                  <strong>Game Levels</strong>
                </Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <TextField
                  variant="outlined"
                  name="gameLevel"
                  placeholder="Enter the number of game levels"
                  margin="dense"
                  fullWidth
                  value={gameLevel}
                  onChange={handleGameLevelValidation}
                  required
                  {...(error?.gameLevel &&
                    error?.gameLevel !== '0' && {
                      error: true,
                      helperText: error?.gameLevel
                    })}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAddGameLevel}
                  style={{
                    textTransform: 'none',
                    fontSize: '1rem'
                  }}
                  {...(gameLevel &&
                    error?.gameLevel === '0' && {
                      disabled: false
                    })}
                >
                  Create
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>Reward points</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <GameRewardLevel
                  gameLevelRewardPoints={gameLevelRewardPoints}
                  handleGameLevelRewardPoints={handleGameLevelRewardPoints}
                />
              </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <Grid
              container
              spacing={2}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item xs={12}>
                {globalVariablesSelected.map(item => (
                  <Chip
                    key={item}
                    label={item}
                    style={{
                      margin: '3px',
                      backgroundColor: '#008080',
                      color: 'white'
                    }}
                  />
                ))}
              </Grid>

              <Grid
                container
                spacing={1}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Grid item xs={12} md={12}>
                  <Typography>
                    <strong>Game Information</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Typography>Game Tech</Typography>
                </Grid>
                <Grid item xs={9} md={9}>
                  {/* <KTextField name="gameTech" control={control} /> */}
                  <TextField
                    // rows={5}
                    // maxRow={10}
                    helperText={error?.gameTech ? error?.gameTech : null}
                    error={error?.gameTech}
                    onChange={e => setGameTech(e.target.value)}
                    value={gameTech}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography>Description</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    margin="dense"
                    fullWidth
                    name="description"
                    onChange={e => setDescripion(e.target.value)}
                    multiline
                    rows={5}
                    value={description}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography>Objectives</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    margin="dense"
                    fullWidth
                    name="objectives"
                    onChange={e => setGameObjective(e.target.value)}
                    multiline
                    rows={5}
                    required
                    value={objective}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography>Learning Outcome</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    required
                    variant="outlined"
                    margin="dense"
                    name="outcome"
                    value={learning}
                    onChange={e => setLearning(e.target.value)}
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ float: 'right' }}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              //disabled={true}
              style={{
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              Save & Continue
            </Button>
          </CardActions>
        </Card>
      </form></>}
    </div>
  );
};

// Creategame.propTypes = {
//   className: PropTypes.string
// };

export default Creategame;
