import * as React from 'react';
import { useState } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';
import AssessmentIcon from '@material-ui/icons/Assessment';
//import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
//import ReportCard from './ReportCard';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

const ReportView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const redirectReports = () => {
    navigate('/app/manage/reportCard');
  };
  const redirect = () => {
    navigate('/app/dashboard');
  };
  /* const [values,setValues]= useState({ 
    school_search: ''
  });  */

  const columns = [
    {
      field: 'childName',
      headerName: 'Child Name',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'grade',
      headerName: 'Grade',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'section',
      headerName: 'Section',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'schoolName',
      headerName: 'SchoolName',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'planAssigned',
      headerName: 'Plan Assigned',
      width: 160,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'viewReport',
      headerName: 'View Report',
      /* flex: 1, */ width: 130,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: false,
      /* width: 100, */
      disableClickEventBubbling: true,
      renderCell: (params: cellParams) => (
        <Button
          variant="contained"
          color="white"
          align="center"
          //className={classes.button}
          startIcon={<AssessmentIcon />}
          /* onClick={() => {
         const api: gridApi = params.api;
         const fields = api
           .getAllColumns()
           .map((c) => c.field)
           .filter((c) => c !== "__check__" && !!c);
         const thisRow = {};

         fields.forEach((f) => {
           thisRow[f] = params.getValue(f);
         });

         return alert(JSON.stringify(thisRow, null, 4));
         //return alert('got it');
       }} */
          onClick={redirectReports}
        ></Button>
      )
    }
  ];

  const rows = [
    {
      id: 1,
      childName: 'Sam',
      grade: 'noname@nothing.new',
      section: '+19999999999',
      schoolName: 30,
      planAssigned: '33333'
    },
    {
      id: 2,
      childName: 'Tom',
      grade: '1noname@nothing.new',
      section: '+19999999999',
      schoolName: 5,
      planAssigned: '33333'
    },
    {
      id: 3,
      childName: 'Tom',
      grade: '2noname@nothing.new',
      section: '+29999999999',
      schoolName: 14,
      planAssigned: '343333'
    },
    {
      id: 4,
      childName: 'Juhi',
      grade: 'moname@nothing.new',
      section: '+19999999999',
      schoolName: 15,
      planAssigned: '121111'
    }
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 420, width: '100%' }} className={classes.root}>
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / Reports </span></div> */}
      {/* <br/> */}
      <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
        <span
          className={classes.title}
          align="center"
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */ fontSize: 25
          }}
        >
          {/* Report */}
        </span>
        {/* <span style={{float: 'right'}}>
        <Tooltip title="Go to Home" >
        <Button
          color="primary"
          variant="contained"
          startIcon={<HomeIcon />}
          onClick={redirect}
        >
          Home
        </Button>
        </Tooltip>
      </span> */}
      </div>
      {/* <div>      
      <div style={{textAlign:'right'}}><Button
        color="primary"
        variant="contained"
        startIcon={<HomeIcon />}
        onClick={redirect}
      >
      Home
      </Button></div>
      <br />
    </div> */}
      <div style={{ display: 'flex', height: '100%',color: '#e91e63',textAlign: 'center',paddingTop: '100px' }}>
        <div style={{ flexGrow: 1 }}>
          {/* <DataGrid
            pageSize="5"
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          /> */}
          <h1>Coming Soon!!</h1>
        </div>
      </div>
    </div>
  );
};

export default ReportView;
