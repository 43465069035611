import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';
import VisibilityIcon from '@material-ui/icons/Visibility';
//import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';
//import { axios } from '../../../../api/Axios';
import api from '../../../../api/Axios';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      //backgroundColor: '#e91e63',
      backgroundColor: '#008080',
      color: 'white'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
    //padding:theme.spacing(10)
  }
}));

const AccountView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('============Inside Account Manager================');
    api
      .getAccountDetails()
      .then(response => {
        console.log(response.data.response.body.entity.data, 'response');
        const rowsData = response.data.response.body.entity.data.map(item => {
          let newRowsData = {
            id: item.user_obj.id,
            accountName:
              item.user_obj.first_name + ' ' + item.user_obj.last_name,
            emailId: item.user_obj.email,
            phoneNumber: item.phone,
            numberOfLicenses: item.no_of_license
          };
          return newRowsData;
        });
        console.log(rowsData, 'rowsData');
        setRows(rowsData);
        setLoading(false);
      })
      .catch(err => console.log(err, 'err'));
  }, []);

  const redirect_accounts = accountId => {
    navigate('/app/manage/schools', { state: { accountId } });
  };
  /* const redirect = () => {
    navigate('/app/dashboard');
  }; */
  /* const [values,setValues]= useState({ 
    school_search: ''
  }); */

  const role = localStorage.getItem('role');

  const columns = [
    {
      field: 'accountName',
      headerName: 'Account Name',
      /* flex: 1, */ width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'emailId',
      headerName: 'Email ID',
      /* flex: 1, */ width: 350,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      /* flex: 1, */ width: 270,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'numberOfLicenses',
      headerName: 'Licenses',
      /* flex: 0.5, */ width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      /* width: 70, */ headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      /* width: 100, */
      disableClickEventBubbling: true,
      renderCell: (params: cellParams) => (
        <Tooltip title="View Schools for this Account">
          <Button
            variant="contained"
            style={{ marginLeft:"20%"}}
          
            startIcon={<VisibilityIcon />}
            disabled={role === 'user' ? true : false}
            
            onClick={() => {
              const api: gridApi = params.api;
              /* const fields = api
                .getAllColumns()
                .map(c => c.field)
                .filter(c => c !== '__check__' && !!c);
              const thisRow = {};
              fields.forEach(f => {
                thisRow[f] = params.getValue(f);
              }); */
              redirect_accounts(params.id);
              //console.log(thisRow, 'thisRow');
              //console.log(params.id, 'params');
              //return alert(JSON.stringify(thisRow, null, 4));
              //return alert('got it');
            }}
          />
        </Tooltip>
      )
    }
  ];

  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div
      style={{
        height: 420,
        width: '100%'
      }}
      className={classes.root}
    >
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / Accounts </span></div> */}
      {/* <br/> */}
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '10px'
        }}
      >
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */ fontSize: 25
          }}
        >
          Accounts
        </span>
       
      </div>
     
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            //autoPageSize='true'
            loading={loading}
            pageSize={5}
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountView;
