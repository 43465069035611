import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  form,
  Typography,
  makeStyles,
  CardActions
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    //height: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  title: {
    textAlign: 'center',
    color: '#e91e63',
    fontSize: 25
  }
}));

const DeletegameView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    gameName: '',
    gameCode: '',
    gameType: '',
    subject: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    /* const token=localStorage.getItem('Auth');
    const t=token.replace(/['"]+/g, '')
    const Auth=`Bearer ${t}`;
    console.log(Auth)
    console.log(values.stockName)
    
    const data = {
      gameName: values.gameName,
      gameType: values.gameType,
      gameCode:values.gameCode,
      subject:values.subject,
      

    };
    
    
   
    axios.post('http://localhost:3000/transaction', data ,
    {
      headers:{
        'Authorization':Auth,
        'Accept':'application/json',
        'content-Type':'application/json'
      }
    })
      .then(res => {
        console.log(res);
       
        if(res.data.error!=="Please authenticate")
        {
          console.log(res.data.balance);
          localStorage.setItem("Balance",res.data.user.balance)
          navigate('/app/dashboard', { replace: true });
          
         

        }
        else
        {
          alert('You are not Allowed for Transaction ,Authentication needed')
        }
       
      });*/
  };

  return (
    <div>
      <div className={classes.title}>Delete Game</div>
      <form
        autoComplete="off"
        //align="center"
        noValidate
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Card style={{ margin: 'auto' }}>
          {/* <CardHeader
            subheader="Please enter the new Game details"
            title="Game Info"
          />
          <Divider /> */}
          <CardContent style={{ paddingTop: '8px' }}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextField
                  margin="dense"
                  fullWidth
                  helperText="Please specify the Game name"
                  label="Game Name"
                  name="gameName"
                  onChange={handleChange}
                  required
                  value={values.gameName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  margin="dense"
                  fullWidth
                  label="Game Code"
                  name="gameId"
                  onChange={handleChange}
                  required
                  value={values.gameCode}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  margin="dense"
                  fullWidth
                  label="Game Type"
                  name="gameType"
                  onChange={handleChange}
                  required
                  value={values.gameType}
                  variant="outlined"
                ></TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  margin="dense"
                  fullWidth
                  label="Subject"
                  name="subject"
                  onChange={handleChange}
                  required
                  value={values.subject}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          {/* <Divider /> */}
          <CardActions style={{ float: 'right' }}>
            {/* <Box display="flex" justifyContent="flex-end" p={2}> */}
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              style={{
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              Submit
            </Button>
            {/* </Box> */}
          </CardActions>
        </Card>
      </form>
    </div>
  );
};

DeletegameView.propTypes = {
  className: PropTypes.string
};

export default DeletegameView;
