import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
//import VisibilityIcon from '@material-ui/icons/Visibility';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlChildren from 'src/views/Manage/AssignPlans/AssignplanView/FormControlChildren';
//import { ContactsOutlined, LaunchOutlined } from '@material-ui/icons';
//import Spinner from '../../Spinner/Spinner';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../../api/Axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

const AssignPlanSchoolSectionView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //   const redirect_children = () => {
  //     navigate('/app/manage/children')
  //   }
  /* const redirect_children = () => {
    navigate('/app/manage/assignPlanDialogBox')
  } */
  const redirect = () => {
    navigate('/app/dashboard');
  };
  const role = localStorage.getItem('role');
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [loading, setLoading] = useState(true);
  const [batchId, setBatchId] = useState(null);
  const initialFormData = {
    schoolId: state.schoolId,
    licenseId: '',
    childIdList: [],
    planId: null,
    valid_from: '',
    valid_to: '',
    batchId: batchId,
    isActive: true,
    //valid_from: '',
    //valid_to: '',
    createdBy: role,
    modifiedBy: role,
    createdDate: today,
    modifiedDate: today
  };
  const [values, setValues] = useState(
    /* { 
    //school_search: '',
    licenseId:'',
    childrenDetails: '',
    planDetails: '',
    startDate: '',
    endDate: ''

} */
    initialFormData
  );
  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});

  const [open, setOpen] = React.useState(false);
  const [section, setSection] = React.useState();
  const [gamePlanDetails, setGamePlanDetails] = useState([]);
  const [childrenDetails, setChildrenDetails] = useState([]);
  const [licenseDetails, setLicenseDetails] = useState([]);

  //const [assignedPlans, setAssignedPlans] = useState([]);
  let assignedPlans = [];
  const handleClickOpen = section => {
    setOpen(true);
    setSection(section);
    // const api: GridApi = params.api;
    // const fields = api
    //   .getAllColumns()
    //   .map((c) => c.field)
    //   .filter((c) => c !== "__check__" && !!c);
    // const thisRow = {};

    // fields.forEach((f) => {
    //   thisRow[f] = params.getValue(f);
    // });

    // return alert(JSON.stringify(thisRow, null, 4));
  };

  const handleClose = () => {
    setOpen(false);
    setValues(initialFormData);
    setErrors({});
  };

  const handleChangeLicense = event => {
    setValues({
      ...values,
      licenseId: event.target.value
    });
    if (event.target.value == '') {
      alert('License ID cannot be blank');
    }
  };

  const onChangeValue = childDetails => {
    setValues({
      ...values,
      childIdList: childDetails
    });
  };
  const handleChildSelect = newValue => {
    setValues({
      ...values,
      childIdList: newValue
    });
  };

  const handleChangePlan = event => {
    setValues({
      ...values,
      planId: event.target.value
    });
  };

  const handleStartDate = event => {
    setValues({
      ...values,
      //startDate : event.target.value
      valid_from: event.target.value
    });
  };

  const handleEndDate = event => {
    setValues({
      ...values,
      //endDate : event.target.value
      valid_to: event.target.value
    });
  };

  const handleBatchId = batchId => {
    setBatchId(batchId);
    console.log(batchId, 'batchId');
  };

  //let childrenDetails = [];

  const handleGetChildrenDetails = (schoolId, sectionId) => {
    api
      .getChildListAssociatedToSectionId(schoolId, sectionId)
      .then(response => {
        console.log(response, 'response');
        const responseTransformed = response.data.response.body.entity.map(
          item => ({
            label: item.child.first_name + ' ' + item.child.last_name,
            value: item.child.id
          })
        );
        //childrenDetails = responseTransformed;
        setChildrenDetails(responseTransformed);
        console.log(childrenDetails, 'childrenDetails');
      })
      .catch(error => console.log(error, 'error'));
  };

  const propsData = { childrenDetails };

  useEffect(() => {
    api
      .getListOfSchoolDetailsBasedOnSchoolId(state.schoolId)
      .then(response => {
        const responseTransformed = response.data.response.body.entity.map(
          (item, index) => ({
            ...item,
            id: index,
            planDetails:item.planDetails==="NOT ASSIGNED" ? "Not Assigned" : Object.values(item.planDetails)
          })
        );
        console.log(responseTransformed, 'responseTransformed');
        setRows(responseTransformed);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });

    api
      .getGamePlanList()
      .then(response => {
        //console.log(response.data.response.body.entity);
        const responseTransformed = response.data.response.body.entity;
        setGamePlanDetails(responseTransformed);
        console.log(
          responseTransformed,
          'responseTransformed game plan details'
        );
      })
      .catch(err => {
        console.log(err);
        //setLoading(false);
        //if(err.request.status === 404){
        //alert('404 Page not found.')}
      });

      api
      .getLicenseDetails(state.schoolId)
      .then(response => {
        //console.log(response.data.response.body.entity);
        const responseTransformed = response.data.response.body.entity;
        setLicenseDetails(responseTransformed);
        console.log(
          responseTransformed,
          'responseTransformed License details'
        );
      })
      .catch(err => {
        console.log(err);
        //setLoading(false);
        //if(err.request.status === 404){
        //alert('404 Page not found.')}
      });
  }, []);

  /* useEffect(() => {
    let field = 'schoolId';
    let fieldValue = 1;
    axios
      .get(
        `http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/Plan/get?field=${field}&value=${fieldValue}`,
        {
          headers: headers,
          timeout: 15000
        }
      )
      .then(response => {
        //setAssignedPlans(response.data.response.body.entity);
        assignedPlans = response.data.response.body;
        console.log(assignedPlans);
        //console.log(response);
        //setLoading(false);
      })
      .catch(err => {
        console.log(err);
        //setLoading(false);
        //if(err.request.status === 404){
        //alert('404 Page not found.')}
      });
  }, []); */

  /* const getPlanDetails = () => {
    let field = 'schoolId';
    let fieldValue = 10;
    axios
      .get(
        //`http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/Plan/get?field=${field}&value=${fieldValue}`
        `http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/subscriptionPlan/get/${field}/${fieldValue}`,
        {
          headers: headers,
          timeout: 15000
          //params: {}
        }
      )
      .then(response => {
        const planDetails = response.data.response.body;
        //setLoading(false);
        console.log(planDetails);
      })
      .catch(err => {
        console.log(err);
        //setLoading(false);
        alert(err + '.' + ' Could not get Plan details');
        //if(err.request.status === 404){
        //alert('404 Page not found.')}
      });
  }; */

  const headers = {
    'Access-Control-Allow-Origin': '*',
    // "Content-Type":"application/x-www-form-urlencoded"
    'Content-Type': 'application/json'
    //"Access-Control-Allow-Credentials": true
  };

  /* useEffect(() => {
    let id = 3;
    axios
      .get(
        `http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/childLearningPlan/get/schoolId/${id}`,
        {
          headers: headers,
          timeout: 15000,
          params: {}
        }
      )
      .then(response => {
        planDetails = response.data.response.body;
        //setLoading(false);
        console.log(planDetails);
      })
      .catch(err => {
        console.log(err);
        //setLoading(false);
        alert(err + '.' + ' Could not get Plan details');
        //if(err.request.status === 404){
        //alert('404 Page not found.')}
      });
  }, []); */

  const validate = () => {
    let temp = {};
    let today_date = new Date();
    let valid_from_date = new Date(values.valid_from);
    let valid_to_date = new Date(values.valid_to);
    temp.licenseId = values.licenseId ? '' : 'This field is required';
    temp.childIdList =
      values.childIdList.length != 0 ? '' : 'This field is required';
    temp.planId = values.planId ? '' : 'This field is required';
    temp.valid_from =
      today_date.getTime() <= valid_from_date.getTime() && values.valid_from
        ? ''
        : 'Invalid Date';
    temp.valid_to =
      today_date.getTime() <= valid_to_date.getTime() &&
      valid_from_date.getTime() < valid_to_date.getTime() &&
      values.valid_to
        ? ''
        : 'Invalid Date';
    setErrors({
      ...temp
    });
    //console.log(values.childId, temp)
    return Object.values(temp).every(x => x == '');
  };

  const handleAssign = event => {
    event.preventDefault();
    console.log('values to post', values);
    values.batchId = batchId;
    if (validate()) {
      setLoading(true);
      //console.log('assigned', values);
      //values.childId = 28 ; //values.licenseId = 5
      //{loading ? <Spinner /> : ''};
      api
        .assignPlan(values)
        .then(response => {
          console.log('response', response);
          setLoading(false);
          alert('plan assigned successfully!');
          handleClose();
        })
        .catch(handleError);
      setLoading(false);
      /* axios
        .post(
          //'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/childLearningPlan/addPlan'
          'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/subscriptionPlan/addPlan',
          //(loading ? <Spinner /> : values) 
          values,
          {
            headers: headers
          }
        )
        .then(response => {
          console.log('response', response);
          setLoading(false);
          alert('plan assigned successfully!');
          handleClose();
        })
        .catch(handleError);
      setLoading(false); */
    }
  };

  const handleError = error => {
    if (error.response) {
      console.log('problem with response', error.response.status);
    } else if (error.request) {
      console.log('Problem with request', error.request);
    } else {
      console.log('error');
    }
    setLoading(false);
    alert('Error. Plan was not assigned');
    handleClose();
  };

  // const licenseDetails = [
  //   {
  //     label: 'License1',
  //     value: 1
  //   },
  //   {
  //     label: 'License2',
  //     value: 2
  //   },
  //   {
  //     label: 'License3',
  //     value: 3
  //   },
  //   {
  //     label: 'License4',
  //     value: 4
  //   },
  //   {
  //     label: 'License5',
  //     value: 5
  //   }
  // ];

  //let planDetails = [];
  /* useEffect(() => {
    axios
      .get(
        'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/Plan/get',
        {
          headers: headers,
          timeout: 15000
        }
      )
      .then(response => {
        //console.log(response.data.response.body.entity);
        const responseTransformed = response.data.response.body.entity;
        setGamePlanDetails(responseTransformed);
        console.log(
          responseTransformed,
          'responseTransformed game plan details'
        );
        //console.log(response);
        //setLoading(false);
      })
      .catch(err => {
        console.log(err);
        //setLoading(false);
        //if(err.request.status === 404){
        //alert('404 Page not found.')}
      });
  }, []); */
  /*  const planDetails = [
    {
      label: 'Plan1',
      value: 1
    },
    {
      label: 'Plan2',
      value: 2
    },
    {
      label: 'Plan3',
      value: 3
    },
    {
      label: 'Plan4',
      value: 4
    },
    {
      label: 'Plan5',
      value: 5
    }
  ]; */

  const columns = [
    {
      field: 'schoolId',
      headerName: 'School Id',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'sectionId',
      headerName: 'Section Id',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'sectionName',
      headerName: 'Section',
      width: 160,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'teacherName',
      headerName: 'Teacher Incharge',
      width: 290,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'grade',
      headerName: 'Grade',
      width: 160,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'planDetails',
      headerName: 'Plan Assigned',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      /* width: 120, */ headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => (
        <div>
          <div>
            <Tooltip title="Assign Plan for this Section">
              <Button
                variant="contained"
                color="white"
                align="right"
                width="10px"
                style={{marginLeft:'30%'}}
                //className={classes.button}
                //startIcon={<VisibilityIcon />}
                startIcon={<LaunchOutlinedIcon />}
                //variant="outlined"
                //color="primary"
                //onClick={handleClickOpen}
                onClick={() => {
                  //getPlanDetails();
                  const api: GridApi = params.api;
                  const fields = api
                    .getAllColumns()
                    .map(c => c.field)
                    .filter(c => c !== '__check__' && !!c);
                  const thisRow = {};
                  fields.forEach(f => {
                    thisRow[f] = params.getValue(f);
                  });
                  console.log(thisRow, 'thisRow');
                  handleClickOpen(thisRow['sectionName']);
                  handleGetChildrenDetails(
                    parseInt(thisRow.schoolId),
                    thisRow.sectionId
                  );
                  handleBatchId(thisRow.sectionId);
                }}
                //onClick={redirect_children}
                disabled={role === 'user' ? true : false}
              />
            </Tooltip>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              PaperProps={{
                style: {
                  borderRadius: 10,
                  borderWidth: 5,
                  borderStyle: 'solid',
                  borderColor: '#e91e63'
                }
              }}
              BackdropProps={{
                style: {
                  backgroundColor: 'rgba(0,0,0,0.1)'
                }
              }}
              /* style={{
          borderStyle: "solid",
          color: "#fff"}} */
            >
              <div style={{ backgroundColor: '#008080' }}>
                <DialogTitle
                  style={{
                    color: 'white'
                  }}
                  align="center"
                  id="form-dialog-title"
                >
                  {section}
                </DialogTitle>
              </div>
              <DialogContent>
                {/* <DialogContentText>
            conten content content
          </DialogContentText> */}
                <TextField
                  autoFocus
                  margin="dense"
                  id="license-details"
                  label="License Details"
                  //type="email"
                  value={values.license}
                  onChange={handleChangeLicense}
                  fullWidth
                  //error
                  select
                  {...(errors.licenseId && {
                    error: true,
                    helperText: errors.licenseId
                  })}
                >
                  {licenseDetails.map(option => (
                    <MenuItem key={option.subscriptionName} value={option.subscriptionName}>
                      {option.subscriptionName}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  value={values.childIdList}
                  onChange={(event, newValue) => {
                    setValues({ ...values, childIdList: newValue });
                  }}
                  options={childrenDetails}
                  disableCloseOnSelect
                  getOptionLabel={option => option.label}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      //variant="outlined"
                      margin="dense"
                      placeholder={'Search by Child name'}
                    />
                  )}
                /> */}
                <FormControlChildren onChange={onChangeValue} {...propsData} />

                {/* <TextField
            //autoFocus
            margin="dense"
            id="child-details"
            label="Children Details"
            //type="email"
            fullWidth
            select
            aria-multiselectable=''
            helperText="Select "
          >
              {childDetails.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </TextField> */}

                <TextField
                  //autoFocus
                  margin="dense"
                  id="plan-details"
                  label="Plan Details"
                  //type="email"
                  fullWidth
                  select
                  onChange={handleChangePlan}
                  {...(errors.planId && {
                    error: true,
                    helperText: errors.planId
                  })}
                >
                  {gamePlanDetails.map(option => (
                    <MenuItem key={option.planId} value={option.planId}>
                      {option.planName}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  //autoFocus
                  margin="dense"
                  id="name"
                  label="Start Date"
                  type="date"
                  fullWidth
                  format="yyyy-MM-dd"
                  {...(errors.valid_from && {
                    error: true,
                    helperText: errors.valid_from
                  })}
                  onChange={handleStartDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  //autoFocus
                  margin="dense"
                  id="name"
                  label="End Date"
                  type="date"
                  fullWidth
                  format="yyyy-MM-dd"
                  onChange={handleEndDate}
                  {...(errors.valid_to && {
                    error: true,
                    helperText: errors.valid_to
                  })}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={handleAssign}
                  color="primary"
                >
                  Assign
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          {/* <Button variant="contained"
    color="white"
    align='right'
    width='10px'
    //className={classes.button}
    startIcon={<VisibilityIcon />}
      onClick={() => {
        const api: GridApi = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);
        const thisRow = {};

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });

        return alert(JSON.stringify(thisRow, null, 4));
        //return alert('got it');
      }}></Button> */}
        </div>
      )
    }
  ];
  //console.log(assignedPlans);
  /* const rows = assignedPlans.map(item => {
    item['id'] = item.schoolId;
    return item;
  }); */
  /*  const rows = [
    {
      id: 1,
      section: 'Nursery A',
      teacher_incharge: 'Mahendra',
      grade: 'Nursery',
      plan_assigned: '-',
      action: 'My School'
    },
    {
      id: 2,
      section: 'Nursery B',
      teacher_incharge: 'Mahendra',
      grade: 'Nursery',
      plan_assigned: '-',
      action: 'My School'
    },
    {
      id: 3,
      section: 'LKG A',
      teacher_incharge: 'Amit',
      grade: 'LKG',
      plan_assigned: '-',
      action: 'My School'
    },
    {
      id: 4,
      section: 'LKG B',
      teacher_incharge: 'Amit',
      grade: 'LKG',
      plan_assigned: '-',
      action: 'My School'
    }
  ]; */
  /*{ id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },*/
  //];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 420, width: '100%' }} className={classes.root}>
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / AssignPlan </span></div> */}
      {/* <br/> */}
      <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */ fontSize: 25
          }}
        >
          {state.schoolName} Sections
        </span>
        {/* <span style={{ float: 'right' }}>
          <Tooltip title="Go to Home">
            <Button
              color="primary"
              variant="contained"
              startIcon={<HomeIcon />}
              onClick={redirect}
            >
              Home
            </Button>
          </Tooltip>
        </span> */}
      </div>
      {/* <div>      
      <div style={{textAlign:'right'}}>
      <Tooltip title="Go to Home" >
        <Button
        color="primary"
        variant="contained"
        startIcon={<HomeIcon />}
        onClick={redirect}
      >
      Home
      </Button>
      </Tooltip>
      </div>
      <br />
    </div> */}
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            loading={loading}
            pageSize="5"
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignPlanSchoolSectionView;
