import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  //Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import Tooltip from '@material-ui/core/Tooltip';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

const useStyles = makeStyles({
  root: {},
  toolbar: {
    height: 64
  }
});

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  //const roleOne = localStorage.getItem('role')
  //const [role, setRole] = useState(roleOne)
  const [hidden, setHidden] = useState(true);
  const role = localStorage.getItem('role');
  //console.log('******', role);
  const logOutUser = () => {
    localStorage.clear();
    navigate('/login');
  };
  const redirect = () => {
    navigate('/app/dashboard');
  };
  useEffect(() => {
    if (role !== null) setHidden(false);
  }, []);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Tooltip title="Home">
            <IconButton hidden={hidden} color="inherit" onClick={redirect}>
              <HomeOutlinedIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton hidden={hidden} color="inherit" onClick={logOutUser}>
              <InputIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
