import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
//import NavBar from 'C:/Users/sinha/kredooAdminUI/src/layouts/DashboardLayout/NavBar';
//import NavBar from 'E:/UBS_Kreedo/kredooAdminUI/src/layouts/DashboardLayout/NavBar';
//import NavBar from 'src/layouts/DashboardLayout/NavBar/index';
import NavBar from '../DashboardLayout/NavBar/index';
import TopBar from './TopBar';
import BreadCrumbs from '../../layouts/DashboardLayout/NavBar/BreadCrumbs';
import { ConfigureContext } from '../../views/Configure/Context/ConfigureContext';

const useStyles = makeStyles(theme => ({
  root: {
    //backgroundColor: theme.palette.background.default,
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
    //alignContent:'left',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
    /* paddingTop: 40,
    paddingLeft:10 */
    //paddingTop: 35,
    /* [theme.breakpoints.up('lg')]: {
      paddingLeft: 135
    } */
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
    //paddingLeft: 5
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
    //paddingLeft: 5
  }
}));

const MainLayout = () => {
  const classes = useStyles();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [planName, setPlanName] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  const [planGameDependency, newRows] = useState([]);
  const [planId, setPlanId] = useState();
  let initialValues = {
    firstName: '',
    lastName: '',
    userEmail: '',
    pwd: '',
    uname: '',
    userMobNumber: '',
    address: '',
    //address1: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    createdBy: 'postman',
    modifiedBy: 'postman',
    emailVerified: true,
    phoneVerified: false,
    //isSuperUser: false,
    //isSiteAdmin: false,
    isWebUser: false,
    isMobileUser: false,
    isActive: false,
    pin: '0098754312'
    //addRole: '',
    //reportingTo: '',
    //actions: ''
  };
  const [values, setValues] = useState(initialValues);
  const [roleAssign, setRoleAssign] = useState('');
  const [reportingToAssign, setReportingToAssign] = useState('');

  return (
    <div className={classes.root}>
      <TopBar /* onMobileNavOpen={() => setMobileNavOpen(true)} */ />
      {/* <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      /> */}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {/* <BreadCrumbs /> */}
            <ConfigureContext.Provider
              value={{
                planId,
                setPlanId,
                planName,
                setPlanName,
                ageGroup,
                setAgeGroup,
                planGameDependency,
                newRows,
                values,
                setValues,
                initialValues,
                roleAssign,
                setRoleAssign,
                reportingToAssign,
                setReportingToAssign
              }}
            >
              <Outlet />
            </ConfigureContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
