import React from 'react';
import { useSelector } from 'react-redux';
import GameDetailsPartOne from './GameDetailsPartOne';
import GameDetailsPartTwo from './GameDetailsPartTwo';
import GameDetailsPartThree from './GameDetailsPartThree';

const GameDetails = (props) => {
    const gameDetailIndex = useSelector(state => state.individualGame.activeIndex)
    const showDetails = () => {
        let element = '';
        switch (gameDetailIndex) {
            case 1:
                element = <GameDetailsPartOne selectedGameData={props.selectedGameData} />
                break;
            case 2:
                element = <GameDetailsPartTwo gameId={props.selectedGameData.gameId} gamePackageData={props.selectedGameData.gamePackageData} />
                break;
            case 3:
                element = <GameDetailsPartThree selectedGameData={props.selectedGameData}/>
                break;
            default:
                break;

        }
        return element;
    }

    return <>{showDetails()}</>
}
export default GameDetails;