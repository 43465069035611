import axios from 'axios';
let instance = null;
const baseAppURL = process.env.REACT_APP_BASE_APP_URL;

const loginInstance = axios.create({
  baseURL: baseAppURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});
if (JSON.parse(localStorage.getItem('token')) !== null && instance === null) {
  instance = axios.create({
    baseURL: baseAppURL,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type':
        'application/json' /* ,
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` */
    },
    timeout: 15000
  });
} else {
  //redirect to Login Page
}
///http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com//curriculum/subscriptionSchool/get/schoolId/{schoolId}
// export const apiCall = () => {
//   instance = axios.create({
//     baseURL: 'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com',
//     headers: {
//       'Access-Control-Allow-Origin': '*',
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
//     },
//     timeout: 15000
//   });
// };

/* const instance = axios.create({
  baseURL: 'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  },
  timeout: 15000
}) */

const api = {
  apiCall: () => {
    instance = axios.create({
      baseURL: baseAppURL,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type':
          'application/json' /* ,
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` */
      },
      timeout: 15000
    });
  },


  authenticateUser: loginDetails =>
    loginInstance({
      method: 'POST',
      url: '/users/authenticate',
      data: loginDetails
    }),

  getAccountDetails: () =>
    instance({
      method: 'POST',
      url: '/curriculum/get/accounts',
      data: { type: 'all' }
    }),

  getListOfSchoolsBasedOnAccountId: accountId =>
    instance({
      method: 'GET',
      url: `/curriculum/get/school_details/account_id/${accountId}`
    }),

  getListOfAllSchools: () =>
    instance({
      method: 'GET',
      url: '/curriculum/get/school_details/all/0'
    }),

  isGameAvailable : gameCode => 
  instance({
    method: 'GET',
    url: `/isGameAvailable/${gameCode}`
  }),
  
  getListOfSchoolDetailsBasedOnSchoolId: schoolId =>
    instance({
      method: 'GET',
      //url: `/curriculum/get/section_details/schoolId/${schoolId}`
      url: `/curriculum/get/school_details/schoolId/${schoolId}`
    }),

  getChildListAssociatedToSectionId: (schoolId, sectionId) =>
    instance({
      method: 'POST',
      url: '/curriculum/childListBySectionId',
      data: { school: schoolId, section: sectionId }
    }),

  getGamePlanList: () =>
    instance({
      method: 'GET',
      url: '/Plan/get'
    }),

  assignPlan: values =>
    instance({
      method: 'POST',
      url: '/subscriptionPlan/addPlan',
      data: values
    }),

  publishUnpublishPlan: editedValues =>
    instance({
      method: 'POST',
      url: '/Plan/edit',
      data: editedValues
    }),

  viewPlanDetails: planName =>
    instance({
      method: 'GET',
      url: `/Plan/get?field=planName&value=${planName}`
    }),

  getPublishedAndUnpublishedPlans: value =>
    instance({
      method: 'GET',
      url: `/Plan/get?field=isPublished&value=${value}`
    }),

  createPlan: values =>
    instance({
      method: 'POST',
      url: '/Plan/add',
      data: values
    }),

  editPlan: values =>
    instance({
      method: 'POST',
      url: '/Plan/edit',
      data: values
    }),

  getListOfAllAdmins: () =>
    instance({
      method: 'GET',
      url: '/users/getListOfAllAdmins'
    }),

  editAdminUser: values =>
    instance({
      method: 'POST',
      url: '/users/editAdmin',
      data: values
    }),

  createNewAdminUser: values =>
    instance({
      method: 'POST',
      url: '/users/register',
      data: values
    }),

  getAllSubscriptions: () =>
    instance({
      method: 'GET',
      url: '/subscriptionSchool/get/all/0'
    }),

  getSubscriptionDetailsBasedOnSchoolId: schoolId =>
    instance({
      method: 'GET',
      url: `/subscriptionSchool/get/schoolId/${schoolId}`
    }),

  getSubscriptionDetailsBasedOnSubscriptionId: subscriptionId =>
    instance({
      method: 'GET',
      url: `/subscriptionPlan/get/subscriptionId/${subscriptionId}`
    }),

  addNewSubscriptionToSchool: values =>
    instance({
      method: 'POST',
      url: '/subscriptionSchool/add',
      data: values
    }),

  getLicenseDetails: schoolId =>
    instance({
      method: 'GET',
      url: `/subscriptionSchool/get/schoolId/${schoolId}`
    }),
  getGamesList: (page, rows) =>
    instance({
      method: 'GET',
      url: `get-all-games?pageSize=${rows}&pageNumber=${page}`
    }),

  viewGameVersion: id =>
    instance({
      method: 'GET',
      url: `/viewGameVersionById/${id}`
    }),

  addGameVersion: formdata => {
    return axios
      .post(`${baseAppURL}/addGameVersion`, formdata, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': ' multipart/form-data; boundary=something'
        }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },

  updateGame: (formdata, gameId) => {
    return axios
      .post(`${baseAppURL}/updateGame/${gameId}`, formdata, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': ' multipart/form-data; boundary=something'
        }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },

  addGame: formdata => {
    return axios
      .post(`${baseAppURL}/addGame`, formdata, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': ' multipart/form-data; boundary=something'
        }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getCatelog: catcode => {
    switch (catcode) {
      case 'GameType':
        return getDropDownOptions('viewAllGameType', catcode);
      case 'GameSubject':
        return getDropDownOptions('curriculum/get/subject/all/0', catcode);
      case 'GameConcept':
        return getDropDownOptions('curriculum/get/concept/all/0', catcode);

      default:
        console.error('catagory code is not suporsted');
    }
  },

};
export default api;
function getDropDownOptions(url, catcode) {
  return axios
    .get(`${baseAppURL}/${url}`, {
      header: {
        'Access-Control-Allow-Origin': '*'
      },
      timeout: 15000
    })
    .then(response => {
      switch (catcode) {
        case 'GameType':
          return commonOptionHandler(response.data.response.body);
        case 'GameSubject':
          return subjectOptionHandler(response.data.response.body);
        case 'GameConcept':
          return subjectOptionHandler(response.data.response.body);
        default:
          return null;
      }
    })
    .catch(error => {
      console.log(error);
    });
}
const commonOptionHandler = data => {
  return data.map((item, index) => ({
    label: data[index],
    value: index
  }));
};
const subjectOptionHandler = data => {
  return data.entity.data.map((item, index) => ({
    label: item.name,
    value: item.id
  }));
};
