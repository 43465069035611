import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer,
  useGridSlotComponentProps
} from '@material-ui/data-grid';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Switch from '@material-ui/core/Switch';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  form,
  Typography,
  Paper,
  TextField,
  CardActions,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  Input,
  Table as MuiTable,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  IconButton
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import Table from './Table';
import { ConfigureContext } from '../../../Context/ConfigureContext';
import { Navigate } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import api from '../../../../../api/Axios';
import './CreatePlan.css';
import GameNameDialog from './GameNameDialog';
import { OPEN_GAME_DIALOG, SET_GAME_NAMES } from '../../../../../actions';
import apis from 'src/api/apis';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { GridCloseIcon } from '@material-ui/x-grid';
const useStyles = makeStyles(theme => ({
  formRoot: {
    /* '& > *': {
      margin: theme.spacing(3)
    },
    display: 'flex',
    '& .MuiTextField-root': {
      margin: theme.spacing(3)
    }, */
    backgroundColor: theme.palette.background.dark,
    //height: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    //width: '100%',
    paddingRight: theme.spacing(5)
  },
  /* new: {
    paddingLeft: theme.spacing(15)
  },
  grid: {
    width: '100%',
    margin: '2px'
  }, */
  paper: {
    padding: theme.spacing(2)
    //textAlign: 'center',
    //border: 'False'
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '25ch'
  },
  button: {
    //alignContent: 'left',
    textTransform: 'none',
    fontSize: '1rem'
    //float: 'right'
  },
  cardHeader: {
    textAlign: 'center',
    color: '#e91e63',
    fontSize: 25
  },
  root: {
    '& .MuiDataGrid-root .MuiDataGrid-colCellWrapper ': {
      //backgroundColor: '#e91e63',
      backgroundColor: '#008080',
      color: 'white'
    }
  },
  columnContainer: {
    border: '1px solid #ddd',
    minHeight: '56px',
    maxHeight: '56px',
    lineHeight: '56px',
    fontSize: '0.8rem',
    fontWeight: 500
  },
  rowContainer: {
    border: '1px solid #ddd'
  },
  rowPadd: {
    paddingLeft: '3rem'
  }
}));

const addedGame = {
  id: 0,
  gameName: '',
  sortOrder: 0,
  dependency: [],
  initialRelease: false,
  month: ''
};
export default function PublishedPlanView() {
  const { id } = useParams();
  console.log(id, '----------------');
  const [planName, setPlanName] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  const [selectedGames, setSelectedGames] = useState([]);
  const [addedGames, setAddedGames] = useState([]);
  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState();
  const [searchedGames,setSearchedGames] = useState([]);
  const [activeIndex,setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const classes = useStyles();

  const location = useLocation();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  useEffect(() => {
    apis
      .get('filter/gameData')
      .then(res => {
        setGames(res?.data?.response?.body);
      })
      .catch(() => {
        setGames([]);
      });
    if (id) {
      apis
        .get(`Plan/get?field=planId&value=${id}&pageSize=5&pageNumber=0`)
        .then(res => {
          console.log(res.data.response.body.entity[0]);
          setPlan(res.data.response.body.entity[0]);
          setAgeGroup(res.data.response.body.entity[0].ageGroup);
          setPlanName(res.data.response.body.entity[0].planName);
          const temp = res.data.response.body.entity[0].planGameDependency.map(
            g => {
              const retTemp = {
                gameName: g.gameName,
                id: g.gameId,
                dependency: JSON.parse(g.dependency),
                sortOrder: g.sortOrder,
                month: g.month,
                initialRelease: g.initialRelease
              };
              return retTemp;
            }
          );
          setAddedGames(temp);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  
  const [games, setGames] = useState([]);
  useEffect(()=>{
    setSearchedGames(games);
      },[games])
  useEffect(() => {
    if (location.pathname === '/app/config/plans/createplans') {
      setPlanName('');
      setAgeGroup('');
      setAddedGames([]);
    }
  }, [location.pathname]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const handlePost = () => {
    console.log(planName, ageGroup, addedGames);
    const temp = addedGames.map(g => {
      const retTemp = {
        gameName: g.gameName,
        gameId: g.id,
        dependency: JSON.stringify(g.dependency),
        sortOrder: g.sortOrder,
        month: g.month,
        initialRelease: g.initialRelease
      };
      return retTemp;
    });
    console.log(temp, 'ddfvd');
    const bodyData = {
      planName: planName,
      ageGroup: ageGroup,
      planGameDependency: temp
    };
    const bodyData2 = {
      planName: planName,
      ageGroup: ageGroup,
      planGameDependency: temp,
      planId: id ? id : 0
    };
    const bodyTemp = { ...plan };
    bodyTemp.planName = planName;
    bodyTemp.ageGroup = ageGroup;
    bodyTemp.planGameDependency = temp;

    location.pathname === '/app/config/plans/createplans'
      ? apis
          .post('/Plan/add', bodyData)
          .then(() => {
            navigate('/app/config/plans/viewplans');
          })
          .catch(() => {})
      : apis
          .post('/Plan/edit', bodyTemp)
          .then(() => {
            navigate('app/config/plans/viewplans');
          })
          .catch(() => {});
  };

  const handlePlanName = e => {
    setPlanName(e.target.value);
  };

  const handlePlanAgeGroup = e => {
    setAgeGroup(e.target.value);
  };

  const sortTable = (a, b) => {
    return a.sortOrder - b.sortOrder;
  };

  const handleSortOrder = (index, event) => {
    const temp = [...addedGames];
    temp[index].sortOrder = event.target.value;
    setAddedGames(temp);
  };

  const tableSort = (a, b) => {
    return a.sortOrder - b.sortOrder;
  };
  const handleSelectedGames = value => {
    const temp = [...selectedGames];
    temp.push(value);
    setSelectedGames(value);
  };
  const addNewRows = () => {
    const temp = selectedGames.map(g => {
      const addedGame = {
        id: g.gameId,
        gameName: g.gameName,
        sortOrder: 0,
        dependency: [],
        initialRelease: false,
        month: ''
      };

      return addedGame;
    });
    console.log(temp);
    setAddedGames(temp);
  };
  console.log(addedGames);
  const handleReleaseChange = (e, index) => {
    const temp = [...addedGames];
    temp[index].initialRelease = e.target.checked;
    setAddedGames(temp);
  };
  const handleMonthChange = (e, index) => {
    const temp = [...addedGames];
    temp[index].month = e.target.value;
    setAddedGames(temp);
  };
  const handleDependencyChange = (value, index,key) => {
    console.log(value);

    // Clone the entire addedGames array
    const temp = [...addedGames];

    // Clone the specific object at the given index
    const updatedGame = { ...temp[index] };

    // Ensure that the dependency array is initialized before pushing the new value
    updatedGame.dependency = updatedGame.dependency || [];

    // Update the dependency array
    if(key==true){
      updatedGame.dependency.push(value);
    }else{
      updatedGame.dependency= updatedGame.dependency.filter((d)=>d.gameId!=value.gameId)
    }
    

    // Update the object at the given index in the cloned array
    temp[index] = updatedGame;

    console.log(temp);
    setAddedGames(temp);
  };
  const handleClose = () => {
    setOpen(false);
    // setSelectedCheckbox([])
    // setIsIndeterminateCheckbox(false)
  };
  const handleSearch = (query) => {
    // setSearchQuery(query);

    // Create a copy of the original games array to avoid mutating the state directly
    const gamesCopy = [...games];

    // Filter the games based on the search query
    const searchResults = gamesCopy.filter(
      (game) =>
        game.gameName.toLowerCase().includes(query.toLowerCase()) ||
        game.gameId.toString().includes(query)
    );

    // Remove the search results from the original array
    gamesCopy.splice(0, games.length, ...searchResults);

    // Set the state with the updated games array
    // Note: This assumes you are using React state to manage the games array
    // If you are using Redux or other state management, adjust accordingly
    setSearchedGames(gamesCopy);
  };
  return (
    <div>
      {/* <GameNameDialog
        addedGames={addedGames}
        setAddedGames={setAddedGames}
        tableGames={planGameDependency}
      /> */}
      <div className={classes.cardHeader}>
        {' '}
        {location.pathname === '/app/config/plans/createplans'
          ? 'Create Plan'
          : planName}
      </div>
      <form autoComplete="off" noValidate className={classes.formRoot}>
        <Card style={{ margin: 'auto' }}>
          <CardContent style={{ paddingTop: '8px', paddingBottom: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="planName"
                  id="planName"
                  label="Plan Name"
                  placeholder="Enter the Name"
                  fullWidth
                  required
                  margin="dense"
                  value={planName}
                  variant="outlined"
                  onChange={handlePlanName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="planAgeGroup"
                  id="planAgeGroup"
                  label="Plan Age Group"
                  placeholder="Enter the Age Group"
                  fullWidth
                  required
                  margin="dense"
                  value={ageGroup}
                  variant="outlined"
                  onChange={handlePlanAgeGroup}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <div>
                  <Autocomplete
                    className="gamePlanDropdown"
                    multiple
                    id="checkboxes-tags-demo"
                    value={selectedGames}
                    onChange={(event, newValue) => {
                      handleSelectedGames(newValue);
                    }}
                    disableCloseOnSelect
                    options={games}
                    getOptionSelected={(option, value) =>
                      value && value.gameId === option.gameId
                    }
                    getOptionLabel={option => (option && option.gameName) || ''}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option && option.gameName}
                      </React.Fragment>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Game Name"
                        required
                        margin="dense"
                        placeholder={'Search by Game name'}
                      />
                    )}
                  />
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <CardActions style={{ float: 'right', paddingRight: 0 }}>
                  <Button
                    onClick={addNewRows}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Add Games
                  </Button>
                </CardActions>
              </Grid>
              <div style={{ width: '100%' }}>
                {/* <TableDialog
                  selectedRowId={selectedRowId}
                  dependentGames={dependentGames}
                  setDependentGames={setDependentGames}
                /> */}
                <table style={{ width: '100%' }}>
                  <thead
                    style={{
                      margin: 0,
                      textAlign: 'center',
                      backgroundColor: '#008080',
                      color: 'white',
                      width: '100%'
                    }}
                  >
                    <tr className={classes.columnContainer}>
                      <th>Game Code</th>
                      <th>Game Name</th>
                      <th>Sort Order</th>
                      <th>Dependency</th>

                      <th>Initial Release</th>
                      <th>Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addedGames.map((row, i) => (
                      <tr key={row.id} className={classes.rowContainer}>
                        <td className={classes.rowPadd}>{row.id}</td>
                        <td>{row.gameName}</td>
                        <td style={{ width: '10%', paddingRight: '1rem' }}>
                          <TextField
                            name="sortOrder"
                            label="Sort Order"
                            placeholder="Enter the Sort Order"
                            required
                            fullWidth
                            margin="dense"
                            value={row.sortOrder}
                            variant="outlined"
                            onChange={e => {
                              handleSortOrder(i, e);
                            }}
                            // onChange={handleSortOrder}
                          />
                          {/* <input onChange={handleSortOrder}></input> */}
                        </td>
                        <td style={{ width: '20%' }}>
                         <div style={{display:"flex",justifyContent:"space-between"}}>
                          <Autocomplete
                          style={{width:"200px"}}
                            className="gamePlanDropdown"
                            multiple
                            disabled
                            id="checkboxes-tags-demo"
                            value={row.dependency}
                            onChange={(event, newValue) => {
                              handleDependencyChange(newValue, i);
                            }}
                            onClick={() => {setActiveIndex(i);setOpen(!open);}}
                            disableCloseOnSelect
                            options={games}
                            getOptionSelected={(option, value) =>
                              value && value.gameId === option.gameId
                            }
                            getOptionLabel={option =>
                              (option && option.gameName) || ''
                            }
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option && option.gameName}
                              </React.Fragment>
                            )}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Game Name"
                                  required
                                  margin="dense"
                                  // placeholder={'Search by Game name'}
                                />
                              )}
                          />
                          <Button variant="contained"
                    color="primary"
                    style={{height: "40px",
                    alignSelf: "center",
                    marginLeft: "9px"}}
                    className={classes.button} type="button" onClick={() => setOpen(!open)}>
                            <AddIcon style={{color:"white"}}/>
                          </Button>
                          </div>
                        </td>

                        <td
                          style={{ paddingLeft: '20px', textAlign: 'center' }}
                        >
                          <Checkbox
                            color="primary"
                            checked={row.initialRelease}
                            onChange={e => handleReleaseChange(e, i)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </td>
                        <td
                          style={{
                            padding: '10px 5px 10px 0px',
                            textAlign: 'center'
                          }}
                        >
                          <TextField
                            style={{ width: '130px' }}
                            id={'month' + row.id}
                            label="Enter Month"
                            onChange={e => handleMonthChange(e, i)}
                            value={row.month}
                            variant="outlined"
                          />
                          <p
                            id={'monthError' + row.id}
                            style={{ display: 'none', color: 'red' }}
                          >
                            <h6>
                              <em>Enter value between 1 to 12!</em>
                            </h6>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Grid item xs={12}>
                <CardActions style={{ float: 'right', paddingRight: 0 }}>
                  <Button
                    onClick={handlePost}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    {location.pathname === '/app/config/plans/createplans'
                      ? 'Create Game Plan'
                      : 'Edit Game Plan'}
                  </Button>
                </CardActions>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        style={{maxHeight:"800px"}}
        open={open}
        onClose={handleClose}
        id="modalWrapper"
      >
        <DialogTitle
          component="div"
          sx={{
            textAlign: 'center'

            // px: (theme) => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
            // pt: (theme) => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
          }}
          style={{ alignSelf: 'center' }}
        >
          <div className='d-flex' style={{justifyContent:'space-between',alignItems:'center'}}>
          <Typography
            style={{ textAlign: 'center' }}
            variant="h3"
          >{`Add Dependency`}</Typography>
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        </div>
          <hr />
        </DialogTitle>
        
        <DialogContent>
          <TextField
            label="Search Game"
            placeholder="Enter game name"
            fullWidth
            required
            margin="dense"
            // value={ageGroup}
            variant="outlined"
            onChange={(e)=>handleSearch(e.target.value)}
          />
        </DialogContent>
        <MuiTable>
          <TableHead>
            <TableRow>
              <TableCell>
              </TableCell>
              <TableCell>Game Code</TableCell>
              <TableCell>Game Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {searchedGames.slice(0, 10).map((g, i) => (
  <TableRow key={g.gameId}>
    <TableCell>
      <Checkbox
        color="primary"
        checked={addedGames[activeIndex]?.dependency?.find((r)=>g.gameId===r.gameId)}
      
        onChange={e => handleDependencyChange(g, activeIndex,e.target.checked)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </TableCell>
    <TableCell>{g.gameId}</TableCell>
    <TableCell>{g.gameName}</TableCell>
  </TableRow>
))}

          </TableBody>
        </MuiTable>
        {/* <Grid className="modalFooter">
          <Button className="bg-success" onClick={handleActivateButton}>
            Yes
          </Button>
          <Button className="bg-danger" onClick={handleClose}>
            Cancel
          </Button>
        </Grid> */}
      </Dialog>
    </div>
  );
}
