import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './GamePackage.css';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  makeStyles,
  Typography,
  CardActions,
  MobileStepper,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import DragAndDrop from './DragAndDrop';
import { KTextField } from '../../../../common-components/KTextField';
import { SimpleDialog } from './CustomDialog';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import GameVersionHistory from './GameVersionHistory';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  cardHeader: {
    textAlign: 'center',
    color: '#e91e63',
    fontSize: 25
  },
  dimStyle: {
    opacity: 0.7,
    pointerEvents: 'none',
    paddingLeft: '5px'
  },
  gameContainer: {
    paddingLeft: '5px'
  },

  gameVersionContainer: {
    paddingTop: '20px',
    paddingLeft: '5px'
  }
}));

function createSchema(isEditable) {
  const schema = yup.object().shape({
    gameVersion: yup
      .string()
      .test('', 'Please enter Game Version', gameVersionData => {
        if (isEditable) {
          return true;
        }
        return !(gameVersionData === '' || gameVersionData === null);
      }),
    
  });
  return schema;
}

const GameInformation = ({
  
  step,
  navigation,
  formData,
  gamePackageData,
  setGamePackageData,
  handleGamePackageChanges,
  activeStep,
  handleNext,
  handleBack,
  isEditable,
  gamePackage,
  setGamePackage,
  ...rest
}) => {
  const classes = useStyles();
  const props = { step, gamePackageData, setGamePackageData };
  const [open, setOpen] = React.useState(false);
  const [isVariableValid, setVariableValid] = React.useState(true);
  const [gameUrl,setGameUrl] = useState(null);
  const [gameFile,setGameFile] = useState(null);
  const [gameVersion,setGameVersion] = useState('');
  const [versionHistory,setVersionHistory] = useState('');

  const validateGamePackage = event => {
    validateJson();
    handleGamePackageChanges(event);
  };
  const methods = useForm({
    mode: 'all',
    defaultValues: gamePackageData,
    resolver: yupResolver(createSchema(isEditable))
  });
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = methods;
  const validateJson = () => {
    try {
      JSON.parse(gamePackageData.levelDetails);
      setVariableValid(true);
    } catch (e) {
      //setOpen(true); // TODO : decided
      setVariableValid(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = data => {
    console.log(data);
    if (isVariableValid) {
      setGamePackageData({
        ...data,
        ...{ levelDetails: gamePackageData.levelDetails }
      });
      if(gamePackageData.gamePackageUploadType==="FileUpload" && gamePackage.length<1 ){
        toast.error("Game Package is required");
        return
      }
      if(gamePackageData.gamePackageUploadType==="FileUpload" && gamePackage.length>1 ){
        toast.error("Only one game package can be uploaded at one time");
        return
      }
      if(gamePackageData.gamePackageUploadType==="URL" && gamePackageData.gameURL.length<1 ){
        toast.error("Game package url can't be empty");
        return
      }
      navigation.next();
      handleNext();
    }
  };

  return (
    <div>
      {/* <div className={classes.cardHeader}>Create Game</div> */}
      <form
        autoComplete="off"
        noValidate
        className={classes.root}
        {...rest}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card style={{ margin: 'auto' }}>
          <CardContent style={{ paddingTop: '8px' }}>
            <MobileStepper
              variant="dots"
              steps={3}
              position="static"
              activeStep={activeStep}
              style={{
                float: 'right',
                maxWidth: 400,
                flexGrow: 1
              }}
            />
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2} md={2}>
                <div> Upload Game Package</div>
              </Grid>
              <Grid item xs={10} md={10}>
                <RadioGroup row name="gamePackageUploadType">
                  <FormControlLabel
                    value="URL"
                    control={<Radio />}
                    label="URL"
                    checked={gamePackageData.gamePackageUploadType === 'URL'}
                    onChange={handleGamePackageChanges}
                  />
                  <FormControlLabel
                    value="FileUpload"
                    control={<Radio />}
                    label="File Upload"
                    checked={
                      gamePackageData.gamePackageUploadType === 'FileUpload'
                    }
                    onChange={handleGamePackageChanges}
                  />
                </RadioGroup>
              </Grid>

              <br />
            
                <Grid
                  className={
                    gamePackageData.gamePackageUploadType !== 'URL'
                      ? classes.dimStyle
                      : classes.gameContainer
                  }
                  container
                  xs={12}
                  md={12}
                >
                  <Grid item xs={2} md={2}>
                    <Typography>Game URL</Typography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <KTextField name="gameURL" control={control} />
                  </Grid>
                </Grid>
              
              
                <Grid
                  className={
                    gamePackageData.gamePackageUploadType !== 'FileUpload'
                      ? classes.dimStyle
                      : ''
                  }
                  item
                  xs={12}
                >
                  <Card>
                    <CardHeader
                      title="File upload"
                      style={{
                        padding: 8,
                        color: 'gray',
                        textAlign: 'center'
                      }}
                    />
                    <CardContent style={{ paddingTop: '3px' }}>
                      <DragAndDrop image = {gamePackage} setImage={setGamePackage} {...props} />
                    </CardContent>
                  </Card>
                </Grid>
              
              {isEditable ? (
                <></>
              ) : (
                <>
                  <Grid
                    className={classes.gameVersionContainer}
                    justifyContent="center"
                    alignItems="center"
                    container
                    spacing={1}
                  >
                    <Grid item xs={2} md={2}>
                      <Typography>Game Version</Typography>
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <KTextField name="gameVersion" control={control} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>Version History Description</Typography>
                  </Grid>
                  <Grid item xs={9} md={9}>
                    <KTextField
                      multiline={true}
                      name="gameDescription"
                      control={control}
                    />
                    {/* <TextField
                  // rows={5}
                  // maxRow={10}
                  // helperText={error?.gameName ? error?.gameName : null}
                  // error={error?.gameName}
                  onChange={e => setGameName(e.target.value)}
                  value={gameName}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                /> */}
                  </Grid>
                </>
              )}
              {/* <Grid item xs={12} md={12}>
                <GameVersionHistory
                  gameId={formData.gameId}
                  showButton={true}
                />
              </Grid> */}
              <Grid item xs={12} md={12}>
                <Typography>Add Variable in JSON format</Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextField
                  required
                  multiline
                  rows={5}
                  maxRow={10}
                  variant="outlined"
                  margin="dense"
                  name="levelDetails"
                  value={gamePackageData.levelDetails}
                  onChange={validateGamePackage}
                  onBlur={validateJson}
                  fullWidth
                  helperText={!isVariableValid ? 'Please enter valid JSON' : ''}
                  id="levelDetails"
                />
              </Grid>
              {/* <Grid item xs={2} md={2}>
                <Button
                  variant="contained"
                  onClick={validateJson}
                  style={{
                    textTransform: 'none',
                    fontSize: '1rem',
                    backgroundColor: "forestgreen",
                  }}
                >
                  Validate
                </Button>
              </Grid> */}
            </Grid>
          </CardContent>
          <CardActions style={{ float: 'right' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigation.previous();
                handleBack();
              }}
              style={{
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              Back
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              Save & Continue
            </Button>
          </CardActions>
        </Card>
      </form>
      <SimpleDialog
        open={open}
        handleClose={handleClose}
        value={'Invalid Json, Please enter correct json'}
      />
    </div>
  );
};

GameInformation.propTypes = {
  className: PropTypes.string
};

export default GameInformation;
