import { createContext, useState } from 'react';


export const GameContext = createContext({});

export const GameContextProvider = (props) => {
    const [gameFormData, setGameFormData] = useState({
        gameId: '',
        gameName: '',
        gameCode: '',
        gameType: '',
        gameSubject: '',
        gameConcept: '',
        gameSkill: '',
        gameLevel: 0
    });

    const [informationData, setInformationData] = useState({
        description: '',
        objectives: '',
        outcome: '',
        gameTech: ''
      });

    const [isEditable, setIsEditable] = useState(false);

    const [packageData, setPackageData] = useState({
        gamePackageImages: [],
        gamePackages: [],
        gameVersion: '',
        levelDetails: defaultVariableSript,
        variableScriptError: false,
        gameURL: '',
        gamePackageUploadType: 'URL',
        iconPackageUploadType: 'URL',
        gameImageUrl: '',
        gameThumbnailUrl: '',
        gameDescription: ''
      });

    const gameDetails = {
        gameFormData,
        setGameFormData,
        informationData,
        setInformationData,
        isEditable,
        setIsEditable,
        packageData,
        setPackageData
    };

    return (
        <GameContext.Provider value={gameDetails}>
          {props.children}
        </GameContext.Provider>
    );    
}

const defaultVariableSript = '{\"level0\": {\"presentation\": {\"completed\": 0, \"playCount\": 0, \"timeSpent\": 0, \"completedCount\": 0}}, \"level1\": {\"completed\": 0, \"playCount\": 0, \"timeSpent\": 0, \"presentation\": {\"completed\": 0, \"playCount\": 0, \"timeSpent\": 0, \"completedCount\": 0}, \"completedCount\": 0, \"correctAttempts\": 0, \"incorrectAttempts\": 0}, \"level2\": {\"completed\": 0, \"playCount\": 0, \"timeSpent\": 0, \"presentation\": {\"completed\": 0, \"playCount\": 0, \"timeSpent\": 0, \"completedCount\": 0}, \"completedCount\": 0, \"correctAttempts\": 0, \"incorrectAttempts\": 0}, \"level3\": {\"completed\": 0, \"playCount\": 0, \"timeSpent\": 0, \"presentation\": {\"completed\": 0, \"playCount\": 0, \"timeSpent\": 0, \"completedCount\": 0}, \"completedCount\": 0, \"correctAttempts\": 0, \"incorrectAttempts\": 0}, \"level4\": {\"completed\": 0, \"playCount\": 0, \"timeSpent\": 0, \"presentation\": {\"completed\": 0, \"playCount\": 0, \"timeSpent\": 0, \"completedCount\": 0}, \"completedCount\": 0, \"correctAttempts\": 0, \"incorrectAttempts\": 0}, \"currentLevel\": {\"level\": 0, \"presentationCompleted\": 0}}';

