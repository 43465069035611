import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';
//import VisibilityIcon from '@material-ui/icons/Visibility';
//import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../../api/Axios';
import { PageItem } from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

const LicenseDetailView = ({ className, ...rest }) => {
  const classes = useStyles();
  const { state } = useLocation();
  // const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  // const redirect_plan = () => {
  //   navigate('/app/manage/children');
  // };
  // const redirect = () => {
  //   navigate('/app/dashboard');
  // };
  // const [values, setValues] = useState({
  //   school_search: ''
  // });
  useEffect(() => {
    api
      .getSubscriptionDetailsBasedOnSubscriptionId(state.subscriptionId)
      .then(response => {
        console.log(response, 'response');
        const responseTransformed = response.data.response.body.entity.map(
          item => ({
            ...item,
            id: item.childId,
            childFullName: item.firstName + ' ' + item.lastName
          })
        );
        setRows(responseTransformed);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
        setLoading(false);
      });
  }, []);
  const columns = [
    {
      field: 'childFullName',
      headerName: 'Child Name',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'gradeName',
      headerName: 'Grade',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'sectionName',
      headerName: 'Section',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'subscriptionLife',
      headerName: 'License Details',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'planName',
      headerName: 'Plan Assigned',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    }
    //   { field: 'actions', headerName: 'Actions',  width: 225, headerClassName: 'super-app-theme--header',
    // sortable: false,
    // disableClickEventBubbling: true,
    // renderCell: (params: CellParams) => (
    //   <div>
    //   <Button variant="contained"
    //   color="white"
    //   align='center'
    //   width='10px'
    //   //className={classes.button}
    //   startIcon={<LaunchOutlinedIcon />}
    //     onClick={redirect_plan}
    //   >
    //     </Button>
    //   </div>
    //   )
    //  },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     child: 'San',
  //     grade: 'Nursery',
  //     section: 'Nursery A',
  //     license_details: '18-03-2020 to 17-03-2021',
  //     plan_assigned: 'Level 1',
  //     actions: 'My School'
  //   },
  //   {
  //     id: 2,
  //     child: 'Dugga',
  //     grade: 'UKG',
  //     section: 'UKG B',
  //     license_details: '18-03-2020 to 17-03-2021',
  //     plan_assigned: 'Level 1',
  //     actions: 'My School'
  //   },
  //   {
  //     id: 3,
  //     child: 'Nas',
  //     grade: '1st',
  //     section: '1st C',
  //     license_details: '18-03-2020 to 17-03-2021',
  //     plan_assigned: 'Level 2',
  //     actions: 'My School'
  //   },
  //   {
  //     id: 4,
  //     child: 'Aggud',
  //     grade: '2nd',
  //     section: '2nd A',
  //     license_details: '18-03-2020 to 17-03-2021',
  //     plan_assigned: 'Level 3',
  //     actions: 'My School'
  //   }
  // ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 420, width: '100%' }} className={classes.root}>
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / Subscriptions / Subscription Details / License Details</span></div> */}
      {/* <br/> */}
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '10px'
        }}
      >
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            /*  justifyContent:'center', */ fontSize: 25
          }}
        >
          School-1 Subscription Details - License Details
        </span>
        {/* <span style={{float: 'right'}}>
        <Tooltip title="Go to Home" >
          <Button
            color="primary"
            variant="contained"
            startIcon={<HomeIcon />}
            onClick={redirect}
          >
            Home
          </Button>
        </Tooltip>
      </span> */}
      </div>
      {/* <div>      
      <div style={{textAlign:'right'}}><Button
        color="primary"
        variant="contained"
        startIcon={<HomeIcon />}
        onClick={redirect}
      >
      Home
      </Button></div>
      <br/>
    </div> */}
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            loading={loading}
            pageSize="5"
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default LicenseDetailView;
