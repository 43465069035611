import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import BreadCrumbs from '../../layouts/DashboardLayout/NavBar/BreadCrumbs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden'
    //width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 35 + 35,
    //paddingTop: 5,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 135 + 135
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%'
    //overflow: 'auto'
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  const location = useLocation();

  /* useEffect(() => {
    if (role === null) {
      navigate('/login');
    } else if (location.pathname === '/app' || location.pathname === '/App') {
      navigate('/app/dashboard');
    }
  }, [location.pathname]); */
  //console.log(localStorage.getItem('token'), 'token');
  useEffect(() => {
    if (token === null && role === null) {
      navigate('/login');
    } else if (location.pathname === '/app' || location.pathname === '/App') {
      navigate('/app/dashboard');
    }
  }, [location.pathname]);

  /*  useEffect(()=>{
    if (location.pathname === '/app' || location.pathname === '/App'){
      navigate ('/app/dashboard');
    }
  },[location.pathname]); */

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <BreadCrumbs />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
