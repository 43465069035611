import * as React from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
//import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../../api/Axios';
import { useState, useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

export default function UserView() {
  const navigate = useNavigate();
  const redirect = () => {
    navigate('/app/dashboard');
  };
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'id',
      headerName: 'User ID',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
      
    },
    {
      field: 'userEmail',
      headerName: 'User Email',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'userMobNumber',
      headerName: 'Mobile number',
      width: 250,
      description: 'This column his not sortable.',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'isActive',
      headerName: 'is Active',
      width: 270,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center',
      renderCell: (cellValues) => {
        return (
          

          <Button variant="contained" color="success" style={{ backgroundColor: cellValues.value ? "#43A047" : "#E53935", color: "white",width:"110px",marginLeft:"20%"}}>

            {cellValues.value ? "Active" : "Not Active"}
          </Button>
         
        );
      }
    }
  ]

  /* const rows = [
     {
       id: 1,
       emailId: 'noname@nothing.new',
       phoneNumber: '+91 1234567890',
       role: 'Boss',
       address: 'Pune, MH',
       associatedSchool: 'My School',
       status: 'active'
     },
     {
       id: 2,
       emailId: '1noname@nothing.new',
       phoneNumber: '+19999999999',
       role: 'Boss',
       address: 'Pune, MH',
       associatedSchool: 'My School',
       status: 'active'
     },
     {
       id: 3,
       emailId: 'noname@nothing.new',
       phoneNumber: '+29999999999',
       role: 'Boss',
       address: 'Mumbai, MH',
       associatedSchool: 'My School',
       status: 'active'
     },
     {
       id: 4,
       emailId: 'moname@nothing.new',
       phoneNumber: '+19999999999',
       role: 'Boss',
       address: 'Pune, MH',
       associatedSchool: 'My School',
       status: 'active'
     }
   ];
 */

  useEffect(() => {
    api
      .getListOfAllAdmins()
      .then(response => {
        const responseTransformed = response.data.response.body.map(
          item => ({
            //...item,
            /*
            "userId": 2,
      "firstName": "admin@super.com",
      "lastName": "admin@super.com",
      "userEmail": "admin@super.com",
      "userMobNumber": "12345677421",
      "uname": "admin@super.com",
      "role": "",
      "phoneVerified": false,
      "country": "postman",
      "state": "Maharashtra",
      "city": "Pune",
            */

            id: item.userId,
            userEmail: item.userEmail,
            roles: item.roles.length > 0 ? item.roles[0].name : null,
            userMobNumber: item.userMobNumber,
            isActive: item.isActive

            //   <Button

            //   variant="contained"
            //   color="primary"
            //   //style={{ float: 'flex-end' }}
            //   //edge="end"
            //   style={{
            //     textTransform: 'none',
            //     fontSize: '1rem'
            //     //float: 'right'
            //   }}
            // >

            // </Button>
          })
        );


        setRows(responseTransformed);

      })
      .catch(err => {
        console.log(err);
      });

  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 420, width: '100%' }} className={classes.root}>
      {/* <br/> */}
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
      Manage / Users</span></div> */}
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '10px'
        }}
      >
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */

            fontSize: 25
          }}
        >
          All Users
        </span>
        {/* <span style={{float: 'right'}}>
          <Tooltip title="Go to Home" >
            <Button
              color="primary"
              variant="contained"
              startIcon={<HomeIcon />}
              onClick={redirect}
            >
              Home
            </Button>
          </Tooltip>
        </span> */}
      </div>
      {/* <div style={{textAlign:'right'}}><Button
          color="primary"
          variant="contained"
          startIcon={<HomeIcon />}
          onClick={redirect}
        >
          Home
        </Button>
        </div>
        <br /> */}
      <div style={{ display: 'flex', height: '100%' ,width:'100%' , overflow:"hidden" }}>
        <div style={{ flexGrow: 1, overflow:"hidden"}}>
          <DataGrid
            style={{overflow:"hidden"}}
            pageSize="5"
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
}

