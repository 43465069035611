import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  makeStyles,
  Typography,
  CardActions,
  MobileStepper,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@material-ui/core';
import axios from 'axios';
import DragAndDrop from './DragAndDrop';
import { KTextField } from '../../../../common-components/KTextField';
import { useForm } from 'react-hook-form';
import {CongratulationDialog} from './CongratulationDialog'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  cardHeader: {
    textAlign: 'center',
    color: '#e91e63',
    fontSize: 25
  },
  dimStyle: {
    opacity: 0.7,
    pointerEvents: 'none',
    paddingLeft: '5px'
  },
  gameContainer: {
    paddingLeft: '5px'
  }
}));

const GameThumbnail = ({
  image,
  setImage,
  step,
  navigation,
  gamePackageData,
  setGamePackageData,
  handleGamePackageChanges,
  activeStep,
  handleNext,
  handleBack,
  handlePost,
  setActiveStep,
  thumbnailFile,
  setThumbnailFile,
  gameThumbnails,
  setGameThumbnails,
  thumbnailType,setThumbNailType,gameImageType,setGameImageType,
  ...rest
}) => {
  const classes = useStyles();

  const props = {  step, gamePackageData, setGamePackageData };
  const methods = useForm({
    mode: 'all',
    defaultValues: gamePackageData
  });
  const {
    handleSubmit,
    control
  } = methods;
const [mobThumbNail,setMobThumbnail] = useState();
const [webThumbnail,setWebThumbnail] = useState();
const [gameIamge1,setGameImage2] = useState();


  
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalClose = () => {
    modalOpen(false);
  };

  const onSubmit = data => {
    console.log(data)
    setGameThumbnails({
      thumbnailMobUrl:data.gameThumbnailMobUrl,
      thumbnailMobUrl2:data.gameThumbnailMobUrl2,
gameImageUrl:data.gameImageUrl,
gameImageUrl2:data.gameImageUrl2
    });
    handlePost(setModalOpen, data);
  };
  return (
    <div>
      {/* <div className={classes.cardHeader}>Create Game</div> */}
      <form autoComplete="off" noValidate className={classes.root} {...rest} onSubmit={handleSubmit(onSubmit)}>
        <Card style={{ margin: 'auto' }}>
          <CardContent style={{ paddingTop: '8px' }}>
            <MobileStepper
              variant="dots"
              steps={3}
              position="static"
              activeStep={activeStep}
              style={{
                float: 'right',
                maxWidth: 400,
                flexGrow: 1
              }}
            />
            {/*  <div>Upload Game Package</div> */}
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2} md={2}>
                <div>Game thumbnail icons</div>
              </Grid>
              <Grid item xs={10} md={10}>
                <RadioGroup row name="iconPackageUploadType">
                  <FormControlLabel
                    value="URL"
                    control={<Radio />}
                    label="URL"
                    checked={thumbnailType === 'URL'}
                    onChange={()=>{setThumbNailType("URL");setThumbnailFile([])}}
                  />
                  <FormControlLabel
                    value="FileUpload"
                    control={<Radio />}
                    label="Image Upload"
                    checked={
                      thumbnailType === 'FileUpload'
                    }
                    onChange={()=>setThumbNailType("FileUpload")}
                  />
                </RadioGroup>
              </Grid>
              <br />
              <Grid
                className={
                  thumbnailType !== 'URL'
                    ? classes.dimStyle
                    : classes.gameContainer
                }
                container
                xs={12}
                md={12}
              >
                <Grid item xs={2} md={2}>
                  <Typography>Image URL</Typography>
                  
                </Grid>
                <Grid item xs={8} md={8}>
                  <KTextField label={"Mobile Thumbnail URL"} name="gameThumbnailMobUrl" control={control} />
                  <KTextField label={"Web Thumbnail URL"} name="gameThumbnailMobUrl2" control={control} />
                </Grid>
                
              </Grid>
              <Grid
                className={
                  thumbnailType !== 'FileUpload'
                    ? classes.dimStyle
                    : ''
                }
                item
                xs={12}
              >
                <Card>
                  <CardHeader
                    title="Image upload"
                    style={{
                      padding: 8,
                      color: 'gray',
                      textAlign: 'center'
                    }}
                  />
                  <CardContent style={{ paddingTop: '3px' }}>
                    <DragAndDrop image={thumbnailFile} setImage={setThumbnailFile} {...props} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2} md={2}>
                <div>Game Images</div>
              </Grid>
              <Grid item xs={10} md={10}>
                <RadioGroup row name="iconPackageUploadType">
                  <FormControlLabel
                    value="URL"
                    control={<Radio />}
                    label="URL"
                    checked={gameImageType === 'URL'}
                    onChange={()=>{setGameImageType('URL');setImage([])}}
                  />
                  <FormControlLabel
                    value="FileUpload"
                    control={<Radio />}
                    label="Image Upload"
                    checked={
                      gameImageType === 'FileUpload'
                    }
                    onChange={()=>setGameImageType('FileUpload')}
                  />
                </RadioGroup>
              </Grid>
              <br />
              <Grid
                className={
                  gameImageType !== 'URL'
                    ? classes.dimStyle
                    : classes.gameContainer
                }
                container
                xs={12}
                md={12}
              >
                <Grid item xs={2} md={2}>
                  <Typography>Image URL</Typography>
                </Grid>
                <Grid item xs={8} md={8}>
                  <KTextField label={"First Image URL"} name="gameImageUrl" control={control} />
                  <KTextField label={"Second Image URL"} name="gameImageUrl2" control={control} />
                </Grid>
              </Grid>
              <Grid
                className={
                  gameImageType !== 'FileUpload'
                    ? classes.dimStyle
                    : ''
                }
                item
                xs={12}
              >
                <Card>
                  <CardHeader
                    title="Image upload"
                    style={{
                      padding: 8,
                      color: 'gray',
                      textAlign: 'center'
                    }}
                  />
                  <CardContent style={{ paddingTop: '3px' }}>
                    <DragAndDrop image={image} setImage={setImage} {...props} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ float: 'right' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigation.previous();
                handleBack();
              }}
              style={{
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              Back
            </Button>
            <Button
              color="primary"
              variant="contained"
              type={"submit"}
              style={{
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              Save & Continue
            </Button>
          </CardActions>
        </Card>
      </form>

      <CongratulationDialog
        open={modalOpen}
        setActiveStep={setActiveStep}
        navigation={navigation}
        handleClose={handleModalClose}
        value={'You have created game successfully. Please click "View Game" to view the games created till date or click "Create Games" to add a new game'}
      ></CongratulationDialog>
    </div>
  );
};

GameThumbnail.propTypes = {
  className: PropTypes.string
};

export default GameThumbnail;
