import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ConfigureContext } from '../../../Context/ConfigureContext';
import {OPEN_TABLE_DIALOG} from '../../../../../actions';
import { useDispatch } from 'react-redux';
import TableDialog from './TableDialog';

const useStyles = makeStyles({
  columnContainer: {
    border: '1px solid #ddd',
    minHeight: '56px',
    maxHeight: '56px',
    lineHeight: '56px',
    fontSize: '0.8rem',
    fontWeight: 500,
  },
  rowContainer: {
    border: '1px solid #ddd',
  },
  rowPadd: {
    paddingLeft: '3rem',
  }
});

const Table = ({
  gameDependency,
  handleChangeDependency,
  handleSortOrder,
  sortOrder
}) => {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [dependentGames, setDependentGames] = useState([]);
  const [checked, setChecked] = useState(false);
  const { planGameDependency } = useContext(ConfigureContext);
  console.log('plan game dependency in table.js', planGameDependency);
  const dispatch = useDispatch();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const games = [
    /* {
      value: 'null',
      label: 'Select a game'
    }, */
    {
      label: 'Game1',
      value: 'Game1'
    },
    {
      label: 'Game2',
      value: 'Game2'
    },
    {
      label: 'Game3',
      value: 'Game3'
    },
    {
      label: 'Game4',
      value: 'Game4'
    },
    {
      label: 'Game5',
      value: 'Game5'
    }
  ];

  const addDependency = (id, e) => {
    e.preventDefault();
    setSelectedRowId(id);
    dispatch(OPEN_TABLE_DIALOG(true));
  }

  // const onIntialReleaseStart = () => {
  //     planGameDependency.map( item => {
  //       if(item.id == selectedRowId.toString())
  //           return item.initialRelease;
  //     });
  // }

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   planGameDependency.map( item => {
  //     if(item.id == selectedRowId.toString()){
  //       if(item.initialRelease) item.initialRelease = false;
  //       else item.initialRelease = true;
  //     }
  //     onIntialReleaseStart();
  //     console.log(item.id, "--", item.initialRelease);
  //   });
  // };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleMonthChangeText = (id, e) => {
    let monthEntered = e.target.value;
    let errorText = document.getElementById('monthError'+id);
    if(monthEntered < 1 || monthEntered > 12) errorText.style.display = "inline";
    else {
      planGameDependency.map(row => {
        if(row.id == id){
          row.month = monthEntered;
        }
      });
      errorText.style.display = "none";
    }
  };

  // const handleMonthChange = (id, event) => {
  //   console.log(event,'*****',id);
  //   // setMonth(event.target.value);
  //   let monthChange = {
  //     id: id,
  //     month: event.target.value
  //   }
  //   month.unshift(monthChange);
  //   setMonth(month);
  //   console.log(month);
  //   planGameDependency.map(row => {
  //     if(row.id == id){
  //       row.month = event.target.value;
  //     }
  //   });
  //   console.log(planGameDependency);
  // };

  const createObj = (val) => { return { label: val, value: val }};

  return (
      <div style={{ width: '100%'}}>
      <TableDialog selectedRowId={selectedRowId} dependentGames={dependentGames} setDependentGames={setDependentGames}/>
      <table style={{ width: '100%' }}>
        <thead
          style={{
            margin: 0,
            textAlign: 'center',
            backgroundColor: '#008080',
            color: 'white',
            width: '100%'
          }}>
          <tr className={classes.columnContainer}>
            <th>Game Code</th>
            <th>Game Name</th>
            <th>Sort Order</th>
            <th>Dependency</th>
            <th></th>
            <th>Initial Release</th>
            <th>Month</th>
          </tr>
        </thead>
        <tbody>
          {planGameDependency.map(row => (
            <tr key={row.id} className={classes.rowContainer}>
              <td className={classes.rowPadd}>{row.id}</td>
              <td>{row.gameName}</td>
              <td style={{width: "10%", paddingRight: '1rem'}}>
                <TextField
                  name="sortOrder"
                  label="Sort Order"
                  placeholder="Enter the Sort Order"
                  required
                  fullWidth
                  margin="dense"
                  value={row.sortOrder}
                  // value=""
                  //value={sortOrder}
                  /* InputLabelProps={{
                    shrink: true
                  }} */
                  variant="outlined"
                  onChange={e => {
                    handleSortOrder(row.id, e);
                  }}
                  // onChange={handleSortOrder}
                />
                {/* <input onChange={handleSortOrder}></input> */}
              </td>
              <td style={{width: "20%"}}>
                {/* <TextField
                  id="gameDependency"
                  label="Add dependency"
                  value={row.dependency}
                  disabled
                  select
                  InputLabelProps={{
                    shrink: true
                  }}

                  variant="outlined"
                  margin="dense"
                >
                  { {planGameDependency.map(option => {
                    if (option.gameName != row.gameName) {
                      return (
                        <MenuItem key={option.id} value={option.gameName}>
                          {option.gameName}
                        </MenuItem>
                      );
                    }
                  })}}
                </TextField> */}
                <Autocomplete disabled className="gamePlanDropdown" style={{width: "100%"}}
                  multiple
                  id="checkboxes-tags-demo"
                  value={row.dependency.map(createObj)}
                  disableCloseOnSelect
                  options={games}
                  getOptionLabel={option => option.label}
                  renderOption={(option, { selected }) => {
                    console.log(option, selected, 'abc');
                    return (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </React.Fragment>
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Add Dependency"
                      required
                      margin="dense"
                      placeholder={''}
                    />
                  )}
                /> 
              </td>
              <td style={{paddingLeft: "20px"}} >
                <Button variant="contained" onClick={e => addDependency(row.id, e)} size="small" color="primary"><span style={{fontSize: "20px"}}>+</span></Button>            
              </td>
              <td style={{paddingLeft: "20px"}}>
              <Checkbox color="primary" checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'primary checkbox' }}/>
              </td>
               <td style={{padding: "10px 5px 10px 0px"}}>
              {/* <TextField
              style={{width: "150px"}}
                id={row.id}
                select
                label="Month"
                value={month} 
                variant="outlined"
                onChange={e => handleMonthChange(row.id, e)}>
                {months.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                </TextField> */}
              <TextField style={{width: "130px"}} id={'month'+row.id} label="Enter Month" onChange={e => handleMonthChangeText(row.id,e)} variant="outlined" />
              <p id={'monthError'+row.id} style={{display:"none", color:"red"}}><h6><em>Enter value between 1 to 12!</em></h6></p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
