export const addTask = (task) => ({
    type: 'ADD_TASK',
    data: task,
});

export const showIndividualGameDetails=()=>({
    type: 'SHOW_INDIVIDUAL_GAME',
    data:  {isShow:true,activeIndex:1},
});
export const CHANGE_GAME_DETAIL_INDEX=(index)=>({
    type: 'SHOW_INDIVIDUAL_GAME',
    data:  {isShow:true,activeIndex:index},
});
export const OPEN_GAME_DIALOG=(flag)=>({
    type: 'OPEN_GAME_DIALOG',
    data:  flag,
});
export const OPEN_TABLE_DIALOG=(flag)=>({
    type: 'OPEN_TABLE_DIALOG',
    data:  flag,
});

export const SET_GAME_NAMES=(items)=>({
    type: 'SET_SELECTED_GAMES',
    data:  items,
});