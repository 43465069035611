export const roleName = [
  {
    label: 'Super Admin',
    value: 'Super Admin'
  },
  {
    label: 'Site Admin',
    value: 'Site Admin'
  }
];
export const reportingToName = [
  {
    label: 'Byshadh',
    value: 'Byshadh'
  }
];
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
