import * as React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { FormHelperText } from '@material-ui/core';
//import SearchIcon from '@material-ui/icons/Search';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import { useEffect } from 'react';
import { TextField, InputAdornment, SvgIcon } from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  formControl: {
    //margin: theme.spacing(1),
    //minWidth: 120,
    //maxWidth: 300
  } /* ,
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: 2
    },
    noLabel: {
      marginTop: theme.spacing(3)
    } */,
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    //color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

/* const childrenDetails = [
  {
    label: 'Child1',
    value: 1
  },
  {
    label: 'Child2',
    value: 2
  },
  {
    label: 'Child3',
    value: 3
  },
  {
    label: 'Child4',
    value: 4
  },
  {
    label: 'Child5',
    value: 5
  },
  {
    label: 'Child6',
    value: 6
  },
  {
    label: 'Child7',
    value: 7
  },
  {
    label: 'Child8',
    value: 8
  },
  {
    label: 'Child9',
    value: 9
  },
  {
    label: 'Child10',
    value: 10
  },
  {
    label: 'Chil11',
    value: 11
  }
]; */

export default function FormControlChildren(props) {
  const classes = useStyles();
  console.log(props, 'props');
  const [childDetails, setChildDetails] = React.useState([]);

  const [searchValue, setSearchValue] = React.useState([]);

  const handleChange = event => {
    setChildDetails(event.target.value);
  };

  const handleSearch = event => {
    setSearchValue(event.target.value);
  };

  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(childDetails);
    }
  }, [childDetails]);
  console.log(childDetails, 'childDetails');

  return (
    <div>
      <FormControl className={classes.formControl} fullWidth="true">
        <InputLabel id="mutiple-checkbox-label">Children Details</InputLabel>
        <Select
          labelId="mutiple-checkbox-label"
          id="mutiple-checkbox"
          multiple
          value={childDetails}
          onChange={handleChange}
          input={<Input />}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
        >
          
          <div>
            <TextField
              onChange={handleSearch}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search by child name"
              variant="outlined"
            />
          </div>
          {/* <div className={classes.root}>
            <AppBar position="static">
              <Toolbar>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search by child name"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={handleSearch}
                    //onBlur={handleSearch}
                  />
                </div>
              </Toolbar>
            </AppBar>
          </div> */}
          {props.childrenDetails.map(
            option =>
              option.label.toLowerCase().includes(searchValue) && (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={childDetails.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              )
          )}
        </Select>
        <FormHelperText>Select Children</FormHelperText>
      </FormControl>
    </div>
  );
}
