import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

export const KTextField = ({ name, control, label, multiline=false, disabled=false  }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState
      }) => (
        <TextField
          multiline={multiline}
          rows={5}
          maxRow={10}
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          margin="dense"
          variant="outlined"
          disabled={disabled}
        />
      )}
    />
  );
};
