import React , { useState,useEffect ,useContext} from 'react';
import { NavLink as RouterLink,useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import {
  List,
  Button,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: /* '10px 8px' */ 0,
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      //textDecoration: 'none',
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  icon: {
    marginRight: theme.spacing(3)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    //color: theme.palette.primary.main,
    '& $title': {
      color: 'white',
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      //color: theme.palette.primary.main
      color: 'white'
    },
    '&:hover': {
      //textDecoration: 'none',
      backgroundColor: theme.palette.primary.main
    }
  }
}));


const NavItem = ({
  toggleOtherDropdowns,
  setToggleOtherDropdowns,
  className,
  items,
  href,
  icon: Icon,
  title,
  flag,
  level,
  ...rest
}) => {
const classes = useStyles();
const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const location = useLocation();
const navigate = useNavigate();

const toggleDropdown = () => {
  setIsDropdownOpen(!isDropdownOpen);
  if (setToggleOtherDropdowns)
  setToggleOtherDropdowns(!toggleOtherDropdowns);
}

useEffect(()=>{
  if (!location.pathname.includes(href)){
    setIsDropdownOpen(false);
  }
  if (location.pathname === '/app/manage'){
    navigate('/app/manage/accounts');
  }
  //!location.pathname.includes(href) || isDropdownOpen !== true && setIsDropdownOpen(false);
  //console.log("toggleOtherDropdowns changed")
},[toggleOtherDropdowns]);


  return (
    <div> 
      <div  style={{marginLeft:"10px"}}>
          <List style={{padding: 5}}>
            {/* <ListItem
              className={clsx(classes.item, className)}
              disableGutters
              {...rest}
            > */}
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={RouterLink}
                onClick={toggleDropdown}
                to={href}
              >
                {Icon && (
                  <Icon
                    className={classes.icon}
                    size="20"
                  />
                )}
                <span className={classes.title}>
                  {title}
                </span>
                  {flag === 'null' ? '' : isDropdownOpen === true ? <ExpandLess />  : <ExpandMore />}
              </Button>
            {/* </ListItem> */}
              {items !== undefined && isDropdownOpen === true &&  flag !=='null'
                && <List  alignItems="center">
                  {items.map((item) => (
                  <NavItem
                    toggleOtherDropdowns = {toggleOtherDropdowns}
                    setToggleOtherDropdowns = {setToggleOtherDropdowns}
                    level={level+1}
                    items={item.items}
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    flag={item.flag}
                    icon={item.icon}
                  />
                  ))}
                  </List>
                }
          </List>
      </div>
    </div>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  flag: PropTypes.string,
  level:PropTypes.number
};

export default NavItem;