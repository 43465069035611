import React, { useState, useMemo, useEffect, useContext } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';
import '../ViewGame.css';
import { useDispatch } from 'react-redux';
import GameDetails from './GameDetails';
import { showIndividualGameDetails } from '../../../../../actions';
import api from '../../../../../api/Axios';
import { useNavigate } from 'react-router-dom';
import { GameContext } from 'src/views/Configure/Context/GameContext';
import { Box } from '@mui/material';

const PageSize = 10;
const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3)
  }
}));

const GameView = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [gameView, setGameView] = useState(false);
  const [gameList, setGameList] = useState([]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedGameData, setSelectedGameData] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return gameList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, gameList]);

  const handleAction = data => {
    setGameView(true);
    dispatch(showIndividualGameDetails());
    setSelectedGameData(data);
  };

  const {
    setGameFormData,
    setInformationData,
    setIsEditable,
    setPackageData
  } = useContext(GameContext);

  // const columns = [
  //   {
  //     field: 'gameName',
  //     headerName: 'Game Name',
  //     width: 400,
  //     headerClassName: 'super-app-theme--header',
  //     headerAlign: 'left'
  //   },
  //   {
  //     field: 'gameCode'||'gameId',
  //     headerName: 'Game Code',
  //     width: 150,
  //     headerClassName: 'super-app-theme--header',
  //     headerAlign: 'left'
  //   },
  //   {
  //     field: 'gameLevel',
  //     headerName: 'Level',
  //     width: 100,
  //     headerClassName: 'super-app-theme--header',
  //     headerAlign: 'left'
  //   },
  //   {
  //     field: 'gameSubject',
  //     headerName: 'Subject',
  //     width: 400,
  //     headerClassName: 'super-app-theme--header',
  //     headerAlign: 'left'
  //   },
  //   {
  //     headerName: 'Action',
  //     width: 150,
  //     field: 'gameAction',
  //     headerClassName: 'super-app-theme--header',
  //     headerAlign: 'left',
  //     renderCell: cellValues => (
  //       <div style={{ display: 'flex', gap: '10px' }}>
  //         <VisibilityIcon
  //           onClick={() => {
  //             handleAction(cellValues.row);
  //           }}
  //           style={{ fontSizeLarge: 'small' }}
  //         />
  //         <EditIcon
  //           onClick={() => {
  //             console.log(cellValues.row);
  //             const {
  //               gameCode,
  //               gameConcept,
  //               gameLevel,
  //               gameName,
  //               gameSkill,
  //               gameSubject,
  //               gameType,
  //               gameDescription,
  //               gameObjectives,
  //               gameOutcome,
  //               gameTechnology,
  //               gamePackageData,
  //               levelDetails,
  //               gameId
  //             } = cellValues.row;
  //             setGameFormData({
  //               gameId,
  //               gameCode,
  //               gameConcept,
  //               gameLevel,
  //               gameName,
  //               gameSkill,
  //               gameSubject,
  //               gameType
  //             });

  //             setInformationData({
  //               description: gameDescription,
  //               objectives: gameObjectives,
  //               outcome: gameOutcome,
  //               gameTech: gameTechnology
  //             });

  //             let parsedGamePackageData = {};
  //             try {
  //               parsedGamePackageData = JSON.parse(gamePackageData);
  //             } catch (e) {
  //               console.log(
  //                 'error while parsing parsedGameData from GameView screen ' + e
  //               );
  //             }

  //             setPackageData({
  //               gamePackageImages: [],
  //               gamePackages: [],
  //               gameVersion: '',
  //               levelDetails: levelDetails,
  //               variableScriptError: false,
  //               gameURL: parsedGamePackageData.gameURL,
  //               gamePackageUploadType: 'URL',
  //               iconPackageUploadType: 'URL',
  //               gameImageUrl: parsedGamePackageData.gameImageUrl,
  //               gameThumbnailUrl: parsedGamePackageData.gameThumbnailUrl,
  //               gameDescription: ''
  //             });

  //             setIsEditable(true);
  //             navigate('/app/config/game/creategame');
  //           }}
  //           style={{ fontSizeLarge: 'small' }}
  //         />
  //       </div>
  //     )
  //   }
  // ];
  useEffect(() => {
    setLoading(true);
    api.getGamesList(page, rowsPerPage).then(gameResponse => {
      const rows = [];
      const data = gameResponse && gameResponse.data && gameResponse.data.response && gameResponse.data.response.body&&gameResponse.data.response.body.content;
      setTotalCount(gameResponse.data.response.body.totalElements);
      data.map(item => {
        const {
          gameCode,
          gameName,
          gameLevel,
          gameSubject,
          gameType,
          gameConcept,
          gameSkill
        } = item.gameFormData;

        let gameDescription,
          gameObjectives,
          gameOutcome,
          gameTechnology = 'NA';

        if (item.gameInformationData) {
          const { description, objectives, outcome, gameTech } = JSON.parse(
            item.gameInformationData
          );

          gameDescription = description ? description : 'NA';
          gameObjectives = objectives ? objectives : 'NA';
          gameOutcome = outcome ? outcome : 'NA';
          gameTechnology = gameTech ? gameTech : 'NA';
        }
        const gameLevelRewardPoints = item.gameLevelRewardPoints
          ? JSON.parse(item.gameLevelRewardPoints)
          : 'NA';
        rows.push({
          id: gameCode,
          gameId: item.gameId,
          gameCode: gameCode,
          gameName: gameName,
          gameLevel: gameLevel,
          gameSubject: gameSubject ? gameSubject : 'NA',
          gameType: gameType ? gameType : 'NA',
          gameConcept: gameConcept ? gameConcept : 'NA',
          gameSkill: gameSkill ? gameSkill : 'NA',
          gameDescription: gameDescription,
          gameObjectives: gameObjectives,
          gameOutcome: gameOutcome,
          gameTechnology: gameTechnology,
          gameLevelRewardPoints: gameLevelRewardPoints,
          gamePackageData: item.gamePackageData,
          levelDetails: item.levelDetails
        });
      });
      setGameList(rows);
      setLoading(false);
    });
  }, [page, rowsPerPage]);
  useEffect(() => {
    setLoading(true);
    api.getGamesList(page, rowsPerPage).then(gameResponse => {
      const rows = [];
      const data = gameResponse && gameResponse.data && gameResponse.data.response && gameResponse.data.response.body&&gameResponse.data.response.body.content;
      setTotalCount(gameResponse.data.response.body.totalElements);
      data.map(item => {
        const {
          gameCode,
          gameName,
          gameLevel,
          gameSubject,
          gameType,
          gameConcept,
          gameSkill
        } = item.gameFormData;

        let gameDescription,
          gameObjectives,
          gameOutcome,
          gameTechnology = 'NA';

        if (item.gameInformationData) {
          const { description, objectives, outcome, gameTech } = JSON.parse(
            item.gameInformationData
          );

          gameDescription = description ? description : 'NA';
          gameObjectives = objectives ? objectives : 'NA';
          gameOutcome = outcome ? outcome : 'NA';
          gameTechnology = gameTech ? gameTech : 'NA';
        }
        const gameLevelRewardPoints = item.gameLevelRewardPoints
          ? JSON.parse(item.gameLevelRewardPoints)
          : 'NA';
        rows.push({
          id: gameCode,
          gameId: item.gameId,
          gameCode: gameCode,
          gameName: gameName,
          gameLevel: gameLevel,
          gameSubject: gameSubject ? gameSubject : 'NA',
          gameType: gameType ? gameType : 'NA',
          gameConcept: gameConcept ? gameConcept : 'NA',
          gameSkill: gameSkill ? gameSkill : 'NA',
          gameDescription: gameDescription,
          gameObjectives: gameObjectives,
          gameOutcome: gameOutcome,
          gameTechnology: gameTechnology,
          gameLevelRewardPoints: gameLevelRewardPoints,
          gamePackageData: item.gamePackageData,
          levelDetails: item.levelDetails
        });
      });
      setGameList(rows);
      setLoading(false);
    });
  }, []);
  const columns = [
    {
      field: 'gameName',
      headerName: 'Game Name',
      width: 400,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
    },
    {
      field: 'gameCode' || 'gameId',
      headerName: 'Game Code',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
    },
    {
      field: 'gameLevel',
      headerName: 'Level',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
    },
    {
      field: 'gameSubject',
      headerName: 'Subject',
      width: 400,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
    },
    {
      headerName: 'Action',
      width: 150,
      field: 'gameAction',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      renderCell: (cellValues) => (cellValues &&
        <div style={{ display: 'flex', gap: '10px' }}>
          <VisibilityIcon
            onClick={() => {
              handleAction(cellValues.row);
            }}
            style={{ fontSizeLarge: 'small' }}
          />
          <EditIcon
            onClick={() => {
              // console.log(cellValues.row);
  
              // // Destructure the row data with default values if the fields are missing
              // const {
              //   gameId,
              //   gameCode = '',
              //   gameConcept = '',
              //   gameLevel = '',
              //   gameName = '',
              //   gameSkill = '',
              //   gameSubject = '',
              //   gameType = '',
              //   gameDescription = '',
              //   gameObjectives = '',
              //   gameOutcome = '',
              //   gameTechnology = '',
              //   gamePackageData = '',
              //   levelDetails = '',
              // } = cellValues.row || {};
  
              // setGameFormData({
              //   gameId,
              //   gameCode,
              //   gameConcept,
              //   gameLevel,
              //   gameName,
              //   gameSkill,
              //   gameSubject,
              //   gameType,
              // });
  
              // setInformationData({
              //   description: gameDescription,
              //   objectives: gameObjectives,
              //   outcome: gameOutcome,
              //   gameTech: gameTechnology,
              // });
  
              // let parsedGamePackageData = {};
              // try {
              //   parsedGamePackageData = JSON.parse(gamePackageData);
              // } catch (e) {
              //   console.log(
              //     'error while parsing parsedGameData from GameView screen ' + e
              //   );
              // }
  
              // setPackageData({
              //   gamePackageImages: [],
              //   gamePackages: [],
              //   gameVersion: '',
              //   levelDetails,
              //   variableScriptError: false,
              //   gameURL: parsedGamePackageData.gameURL || '',
              //   gamePackageUploadType: 'URL',
              //   iconPackageUploadType: 'URL',
              //   gameImageUrl: parsedGamePackageData.gameImageUrl || '',
              //   gameThumbnailUrl: parsedGamePackageData.gameThumbnailUrl || '',
              //   gameDescription: '',
              // });
  
              // setIsEditable(true);
              navigate(`/app/config/game/creategame/${cellValues?.row?.id}`);
            }}
            style={{ fontSizeLarge: 'small' }}
          />
        </div>
      ),
    },
  ];
  

  const handlePageSizeChange = (e, newPageSize) => {
    setRowsPerPage(e.pageSize);
    setPage(0); // Reset to the first page when changing page size
  };

  const handlePageChange = (e, newPage) => {
    setPage(e.page);
  };
  return (
    <>
      {gameView ? (
        <div className={classes.root} id="gameDetails">
          <GameDetails selectedGameData={selectedGameData} />
        </div>
      ) : (
        <div className={classes.root}>
          <Box sx={{ height: '100%', width: '100%' }}>
            {gameList &&
            <DataGrid
              rows={ gameList}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50]}
              rowCount={totalCount}
              page={currentPage}
              pageSize={rowsPerPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              paginationMode="server"
            />}
          </Box>
        </div>
      )}
    </>
  );
};

export default GameView;
