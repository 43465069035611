import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';

//import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
//import FormControlChildrenPlan from 'src/views/Manage/Schools/SchoolView/FormControlChildrenPlan';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../../api/Axios';


const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
      //display: 'flex'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)'
      }
    }
  }
});

const ChildrenView = ({ props, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [gamePlanDetails, setGamePlanDetails] = useState([]);
  const [errors, setErrors] = useState({});
  /* const redirect_plan = () => {
    navigate('/app/manage/plans')
  } */
  const redirect = () => {
    navigate('/app/dashboard');
  };
  const [rows, setRows] = useState([]);
  useEffect(() => {
    api
      .getChildListAssociatedToSectionId(state.schoolId, state.sectionId)
      .then(response => {
        
        const responseTransformed = response.data.response.body.entity.map(
         item => ({
            
            id: item.child.id,
            child: item.child.first_name + ' ' + item.child.last_name,
            // license_details: item.child.subscriptions.map(
            //   item => item.subscriptionName
            // )
            //plan_assigned: item.child.plans.map(item => item.planName),
             grade: state.grade,
            section: state.sectionName,
            plan_assigned:item.child.plans==="NOT ASSIGNED" ? "Not Assigned" : Object.values(item.child.plans.map(plan => plan.plan.planName))
            //plan_assigned: Object.values(item.child.plans.map(plan => console.log(plan.plan.planName,"Planssss")))
            
            //Object.values(item.child.plans[0].plan.planName)
          })
          // item =>{
          //   console.log(item.child.plans[0].plan.planName,"children data items")
          //   //console.log(item.child.parent,"children data new")
          // }
        );
        setRows(responseTransformed);
        console.log(responseTransformed, 'responseTransformed');
      })
      .catch(error => console.log(error, 'error'));

      api
      .getGamePlanList()
      .then(response => {
        //console.log(response.data.response.body.entity);
        const responseTransformed = response.data.response.body.entity;
        setGamePlanDetails(responseTransformed);
        console.log(
          responseTransformed,
          'responseTransformed game plan details'
        );
      })
      .catch(err => {
        console.log(err);
        //setLoading(false);
        //if(err.request.status === 404){
        //alert('404 Page not found.')}
      });


      api
      .getLicenseDetails(state.schoolId)
      .then(response => {
        //console.log(response.data.response.body.entity);
        const responseTransformed = response.data.response.body.entity;
        setLicenseDetails(responseTransformed);
        console.log(
          responseTransformed,
          'responseTransformed License details'
        );
      })
      .catch(err => {
        console.log(err);
        //setLoading(false);
        //if(err.request.status === 404){
        //alert('404 Page not found.')}
      });

  }, []);

  const role = localStorage.getItem('role');
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  

  const [batchId, setBatchId] = useState(null);
  const initialFormData = {
    schoolId: state.schoolId,
    licenseId: '',
    childIdList: [],
    planId: null,
    valid_from: '',
    valid_to: '',
    batchId: batchId,
    isActive: true,
    //valid_from: '',
    //valid_to: '',
    createdBy: role,
    modifiedBy: role,
    createdDate: today,
    modifiedDate: today
  };
  const [values, setValues] = useState(
    initialFormData
  );

  // const redirect_children = (
  //   schoolId,
  //   sectionId,
  //   grade,
  //   sectionName,
  //   schoolName
  // ) => {
  //   navigate('/app/manage/children', {
  //     state: { schoolId, sectionId, grade, sectionName, schoolName }
  //   });
  // };

  const [open, setOpen] = React.useState(false);
  const [valueofChild, setValueOfChild] = React.useState();
  const [valueOfChildId, setValueOfChildId] = React.useState();
  const [valueofGrade, setValueOfGrade] = React.useState();
  const [valueofSection, setValueOfSection] = React.useState();
  const [valueofLicense, setValueOfLicense] = React.useState();
  const [valueofPlan, setValueOfPlan] = React.useState();
  const [licenseDetails, setLicenseDetails] = useState([]);
  const [loading, setLoading] = useState(true);
 

  const validate = () => {
    let temp = {};
    let today_date = new Date();
    let valid_from_date = new Date(values.valid_from);
    let valid_to_date = new Date(values.valid_to);
    temp.licenseId = values.licenseId ? '' : 'This field is required';
    // temp.childIdList =
    //   values.childIdList.length != 0 ? '' : 'This field is required';
    temp.planId = values.planId ? '' : 'This field is required';
    temp.valid_from =
      today_date.getTime() <= valid_from_date.getTime() && values.valid_from
        ? ''
        : 'Invalid Date';
    temp.valid_to =
      today_date.getTime() <= valid_to_date.getTime() &&
      valid_from_date.getTime() < valid_to_date.getTime() &&
      values.valid_to
        ? ''
        : 'Invalid Date';
    setErrors({
      ...temp
    });
    //console.log(values.childId, temp)
    return Object.values(temp).every(x => x == '');
  };

  const handleAssign = event => {
    event.preventDefault();
    // setValues({
    //   ...values,
    //   childIdList: [valueofChild]
    // });
    //handleChild();
    console.log('values to post', values);
   // values.batchId = batchId;
    if (validate()) {
      setLoading(true);
      //console.log('assigned', values);
      //values.childId = 28 ; //values.licenseId = 5
      //{loading ? <Spinner /> : ''};
      api
        .assignPlan(values)
        .then(response => {
          setLoading(false);
          //alert('plan assigned successfully!');
          handleClose();
          console.log(values,"valuesss");
          window.location.reload();
          // //redirect_children(
          //   parseInt(values.schoolId),
          //   values.sectionId,
          //   values.grade,
          //   values.sectionName,
          //   state.schoolName
          // );
          // api
          // .getListOfSchoolDetailsBasedOnSchoolId(state.schoolId)
          // .then(response => {
          //   const responseTransformed = response.data.response.body.entity.map(
          //     (item, index) => ({
          //       ...item,
          //       id: index,
          //       planDetails:item.planDetails==="NOT ASSIGNED" ? "Not Assigned" : Object.values(item.planDetails)
          //     })
          //   );
          //   console.log(responseTransformed, 'responseTransformed');
          //   setRows(responseTransformed);
          //   setLoading(false);
          // })
          // .catch(err => {
          //   console.log(err);
          // });
        })
        .catch(handleError);
      setLoading(false);
      /* axios
        .post(
          //'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/childLearningPlan/addPlan'
          'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/subscriptionPlan/addPlan',
          //(loading ? <Spinner /> : values) 
          values,
          {
            headers: headers
          }
        )
        .then(response => {
          console.log('response', response);
          setLoading(false);
          alert('plan assigned successfully!');
          handleClose();
        })
        .catch(handleError);
      setLoading(false); */
    }
  };


  const handleClickOpen = (id,child, grade, section, license, plan) => {
    setOpen(true);
    setValueOfChildId(id);
    setValueOfChild(child);
    setValueOfGrade(grade);
    setValueOfSection(section);
    setValueOfLicense(license);
    setValueOfPlan(plan);
    // const api: GridApi = params.api;
    // const fields = api
    //   .getAllColumns()
    //   .map((c) => c.field)
    //   .filter((c) => c !== "__check__" && !!c);
    // const thisRow = {};

    // fields.forEach((f) => {
    //   thisRow[f] = params.getValue(f);
    // });

    // return alert(JSON.stringify(thisRow, null, 4));
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // const handleChild = event => {
  //   console.log("Inside handlChild")
  //   setValues({
  //     ...values,
  //     childIdList: valueofChild
  //   });
  // };

  
  const handleChangePlan = event => {
    setValues({
      ...values,
      planId: event.target.value
    });
  };

  const handleStartDate = event => {
    setValues({
      ...values,
      //startDate : event.target.value
      valid_from: event.target.value
    });
  };

  const handleEndDate = event => {
    setValues({
      ...values,
      //endDate : event.target.value
      valid_to: event.target.value
    });
  };

  const handleChangeLicense = event => {
    setValues({
      ...values,
      licenseId: event.target.value,
      childIdList: [valueOfChildId]
    });
   
    if (event.target.value == '') {
      alert('License ID cannot be blank');
    }
  };
  
const handleError = error => {
    if (error.response) {
      console.log('problem with response', error.response.status);
    } else if (error.request) {
      console.log('Problem with request', error.request);
    } else {
      console.log('error');
    }
    setLoading(false);
    alert('Error. Plan was not assigned');
    handleClose();
  };
  
  const columns = [
    {
      field: 'id',
      headerName: 'Child Id',
      width: 140,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'child',
      headerName: 'Child Name',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'grade',
      headerName: 'Grade',
      width: 140,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'section',
      headerName: 'Section',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    // // {
    // //   field: 'license_details',
    // //   headerName: 'License Details',
    // //   width: 200,
    // //   headerClassName: 'super-app-theme--header',
    // //   headerAlign: 'center'
    // // },
    {
      field: 'plan_assigned',
      headerName: 'Plan Assigned',
      width: 260,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align : 'center'
    },
    {
      field: 'actions',
      headerName: 'Actions',
      /* flex: 1, */ width: 110,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => (
        <div>
          <div>
          <Tooltip title="Assign plan">
            <Button
              variant="contained"
              color="white"
              align="right"
              width="10px"
              //className={classes.button}
              startIcon={<LaunchOutlinedIcon />}
              //variant="outlined"
              //color="primary"
              
              onClick={() => {
                const api: GridApi = params.api;
                const fields = api
                  .getAllColumns()
                  .map(c => c.field)
                  .filter(c => c !== '__check__' && !!c);
                const thisRow = {};
                fields.forEach(f => {
                  thisRow[f] = params.getValue(f);
                });
                console.log("Click opennn",thisRow);
                handleClickOpen(
                  thisRow['id'],
                  thisRow['child'],
                  thisRow['section'],
                  thisRow['grade'],
                  thisRow['license_details'],
                  thisRow['plan_assigned']
                );
              }}
              //onClick={redirect_children}
            />
            </Tooltip>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <ThemeProvider theme={theme}>
                <DialogTitle id="form-dialog-title">
                  <span className={classes.title} style={{ color: '#e91e63' }}>
                    <div>{valueofChild}</div>
                   
                    <div style={{ textAlign: 'right' }}>
                      Grade:{valueofGrade}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      Section:{valueofSection}
                    </div>
                  </span>
                </DialogTitle>
              </ThemeProvider>
              <DialogContent>
                {/* <DialogContentText>
            conten content content
          </DialogContentText> */}
                <div>
                  {/* {valueofPlan && (
                    <TextField
                      InputProps={{
                        readOnly: true
                      }}
                      autoFocus
                      margin="dense"
                      id="license-details"
                      label="License Details"
                      onChange={handleChange}
                      fullWidth
                      defaultValue={valueofLicense}
                    />
                  )} */}
                </div>
                <div>
                  {/* {valueofPlan === '' && ( */}
                    <TextField
                    autoFocus
                    margin="dense"
                    id="license-details"
                    label="License Details"
                    //type="email"
                    value={values.license}
                    onChange={handleChangeLicense}
                    fullWidth
                    //error
                    select
                    {...(errors.licenseId && {
                      error: true,
                      helperText: errors.licenseId
                    })}
                  >
                    {licenseDetails.map(option => (
                      <MenuItem key={option.subscriptionName} value={option.subscriptionName}>
                        {option.subscriptionName}
                      </MenuItem>
                    ))}
                  </TextField>
                  {/* )} */}
                </div>

                {/*<FormControlChildrenPlan />*/}

                {/* <TextField
            //autoFocus
            margin="dense"
            id="child-details"
            label="Children Details"
            //type="email"
            fullWidth
            select
            aria-multiselectable=''
            helperText="Select Children"
          >
              {childDetails.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </TextField> */}
 {/* <FormControlChildren onChange={onChangeValue} {...propsData} /> */}
               
      <TextField
                  //autoFocus
                  margin="dense"
                  id="plan-details"
                  label="Plan Details"
                  //type="email"
                  fullWidth
                  select
                  onChange={handleChangePlan}
                  {...(errors.planId && {
                    error: true,
                    helperText: errors.planId
                  })}
                >
                  {gamePlanDetails.map(option => (
                    <MenuItem key={option.planId} value={option.planId}>
                      {option.planName}
                    </MenuItem>
                  ))}
                </TextField>

                {/* <TextField
                  //autoFocus
                  margin="dense"
                  id="name"
                  label="Start Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  //autoFocus
                  margin="dense"
                  id="name"
                  label="End Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                /> */}

<TextField
                  //autoFocus
                  margin="dense"
                  id="name"
                  label="Start Date"
                  type="date"
                  fullWidth
                  format="yyyy-MM-dd"
                  {...(errors.valid_from && {
                    error: true,
                    helperText: errors.valid_from
                  })}
                  onChange={handleStartDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  //autoFocus
                  margin="dense"
                  id="name"
                  label="End Date"
                  type="date"
                  fullWidth
                  format="yyyy-MM-dd"
                  onChange={handleEndDate}
                  {...(errors.valid_to && {
                    error: true,
                    helperText: errors.valid_to
                  })}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
                <Button onClick={handleAssign} color="primary">
                  Assign
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          {/* <Button variant="contained"
    color="white"
    align='right'
    width='10px'
    //className={classes.button}
    startIcon={<VisibilityIcon />}
      onClick={() => {
        const api: GridApi = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);
        const thisRow = {};

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });

        return alert(JSON.stringify(thisRow, null, 4));
        //return alert('got it');
      }}></Button> */}
        </div>
      ) /* ,
  flex: 1 */
    }
  ];

  /* const rows = [
    {
      id: 1,
      child: 'San',
      grade: 'Nursery',
      section: 'Nursery A',
      license_details: '18-03-2020 to 17-03-2021',
      plan_assigned: '',
      actions: 'My School'
    },
    {
      id: 2,
      child: 'Dugga',
      grade: 'Nursery',
      section: 'Nursery B',
      license_details: '18-03-2020 to 17-03-2021',
      plan_assigned: 'Level 1',
      actions: 'My School'
    },
    {
      id: 3,
      child: 'Abba',
      grade: 'LKG',
      section: 'LKG A',
      license_details: '18-03-2020 to 17-03-2021',
      plan_assigned: '',
      actions: 'My School'
    },
    {
      id: 4,
      child: 'Dabba',
      grade: 'LKG',
      section: 'LKG B',
      license_details: '18-03-2020 to 17-03-2021',
      plan_assigned: 'Level 1',
      actions: 'My School'
    }
  ]; */

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 420, width: '100%' }} className={classes.root}>
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / Schools / Sections / Children / Plans</span></div> */}
      {/* <br/> */}
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '10px'
        }}
      >
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */ fontSize: 25
          }}
        >
          {state.schoolName} {state.sectionName} - Children
        </span>
        {/* <span style={{float: 'right'}}>
        <Tooltip title="Go to Home" >
        <Button
          color="primary"
          variant="contained"
          startIcon={<HomeIcon />}
          onClick={redirect}
        >
          Home
        </Button>
        </Tooltip>
      </span> */}
      </div>
      {/* <div>      
      <div style={{textAlign:'right'}}><Button
        color="primary"
        variant="contained"
        startIcon={<HomeIcon />}
        onClick={redirect}
      >
      Home
      </Button></div>
      <br />
    </div> */}
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            pageSize="5"
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default ChildrenView;
