import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';
import VisibilityIcon from '@material-ui/icons/Visibility';
//import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
//import { array, bool } from 'prop-types';
//import { Formik } from 'formik';
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../../api/Axios';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

//const role = localStorage.getItem('role')
//console.log(role);

const SubscriptionView = ({ className, ...rest }) => {
  //const role = localStorage.getItem('role')
  const classes = useStyles();
  const navigate = useNavigate();
  const subscriptionDetails = schoolId => {
    //navigate(`/app/manage/subscriptionDetail/${id}`);
    navigate('/app/manage/subscriptionDetail', { state: { schoolId } });
    //console.log('schoolId', {id: id});
  };
  const redirect = () => {
    navigate('/app/dashboard');
  };
  /* const [values, setValues]= useState({ 
    school_search: '',
    school_name: ''
  }); */
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .getAllSubscriptions()
      .then(response => {
        console.log(response, 'response');

        const responseTransformed = response.data.response.body.entity.map((item,i) => {
          let newresponseTransformed = {
            id:i,
            schoolId: item.schoolId,
            subscriptionName: item.subscriptionName,
            noOfChildren: item.noOfChildren,
            utilisation:item.utilisation,
            isActive:item.isActive
          };
          return newresponseTransformed;
        });
        console.log(responseTransformed, 'responseTransformed');
        setRows(responseTransformed);

      
      })
      .catch(error => console.log(error, 'error'));
  }, []); 

  /* const buttonToggle = () => {
    if (role === 'superadmin' && role === 'siteadmin' )
    return true;
    
  };

  const btn = false; */

  /*useEffect(() => {
    axios.get('http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/schools', {
      header: {
        //'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
    })
    .then(response => {
      setValues({school_name: [response.data]})
      console.log(values.school_name)
      //console.log(values.school_name)
    })
    .catch(err => {
      console.log(err)
    })
    }, []); */

  const columns = [
    {
      field: 'schoolId',
      //ield: 'id',
      headerName: 'school Id',
      width: 115,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'subscriptionName',
      headerName: 'Subscription Name',
      width: 260,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'noOfChildren',
      headerName: 'Number of Children',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'utilisation',
      headerName: 'Utilization',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'isActive',
      headerName: 'Status',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'action',
      headerName: 'Action',
      /* width: 160 */ flex: 0.5,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => (
        <div>
          <Tooltip title="View Schools for this Account">
            <Button
              variant="contained"
              //color="white"
              align="left"
              width="10px"
              //className={classes.button}
              startIcon={<VisibilityIcon />}
              //disabled= {Formik.isValid}
              //disabled = {(role === 'user') ? true : false}
              //onClick={subscriptionDetails}
              /* onClick={() => {
            const api: GridApi = params.api;
            const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
            const thisRow = {};

            fields.forEach((f) => {
              thisRow[f] = params.getValue(f);
            });

            return alert(JSON.stringify(thisRow, null, 4));}} */
              onClick={() => {
                const api: GridApi = params.api;
                const fields = api
                  .getAllColumns()
                  .map(c => c.field)
                  .filter(c => c !== '__check__' && !!c);
                const thisRow = {};
                fields.forEach(f => {
                  thisRow[f] = params.getValue(f);
                });
                subscriptionDetails(thisRow.schoolId);
                //return alert(JSON.stringify(thisRow, null, 4));
              }}
            />
          </Tooltip>
        </div>
      )
    }
  ];

  /* const rows = [
    {
      //schoolId: 1,
      id: 1,
      name: 'school1',
      address: 'Bangalore',
      number_of_children: '10',
      start_date: '17-March-2021',
      end_date: '16-March-2022',
      utilization: '6/10',
      status: '',
      action: 'My School'
    },
    {
      id: 2,
      school_name: 'School2',
      address: 'Pune',
      number_of_children: '50',
      start_date: '19-March-2021',
      end_date: '18-March-2022',
      utilization: '10/50',
      status: '',
      action: 'My School'
    },
    {
      id: 3,
      school_name: 'School3',
      address: 'Mumbai',
      number_of_children: '30',
      start_date: '20-March-2021',
      end_date: '19-March-2022',
      utilization: '6/30',
      status: '',
      action: 'My School'
    },
    {
      id: 4,
      school_name: 'School4',
      address: 'Chennai',
      number_of_children: '70',
      start_date: '27-March-2021',
      end_date: '26-March-2022',
      utilization: '50/70',
      status: '',
      action: 'My School'
    } 
  ]; */

  /* const rows = rowsData.map(item => {
    item['id'] = item.schoolId;
    return item;
  }); */

  /* useEffect(() => {
    axios
      .get(
        'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/schools' ,
        {
          header: {
            'Access-Control-Allow-Origin': '*'
          },
          timeout: 15000
        }
      )
      .then(response => {
        setRowsData(response.data.response.body);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        if (err.request.status === 404) {
          alert('404 Page not found.');
        }
      });
  }, []); */
  //console.log(rowsData);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div
      style={{
        height: 420,
        width: '100%'
      }}
      className={classes.root}
    >
      {/* <div>
      <span className={classes.title}  style={{color: '#e91e63', }}>
        Manage / Subscriptions </span>
    </div> */}
      {/* <br/> */}
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '10px'
        }}
      >
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */ fontSize: 25
          }}
        >
          Subscriptions
        </span>
        {/* <span style={{float: 'right'}}>
        <Tooltip title="Go to Home" >
        <Button
          color="primary"
          variant="contained"
          startIcon={<HomeIcon />}
          onClick={redirect}
        >
          Home
        </Button>
        </Tooltip>
      </span> */}
      </div>
      {/* <div>      
      <div style={{textAlign:'right'}}>
      <Tooltip title="Go to Home" >
        <Button
          color="primary"
          variant="contained"
          startIcon={<HomeIcon />}
          onClick={redirect}
        >
        Home
        </Button>
        </Tooltip>
      </div>
      <br/>
    </div> */}
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            pageSize="5"
            //loading={loading}
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
            /* columns={[{ field: 'status', hide: false }]} */
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionView;
