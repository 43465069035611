import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Notifications from './Notifications';
import Password from './Password';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    height: '95%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    overflow: 'auto'
    //paddingLeft: theme.spacing(40)
  },
  container: {
    paddingLeft: 40,
    paddingRight: 40,
    overflow: 'auto'
    //paddingTop: 75
  }
}));

const SettingsView = () => {
  const classes = useStyles();

  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <Page className={classes.root} title="Settings">
        <Container maxWidth="lg" className={classes.container}>
          <Notifications />
          <Box mt={3}>
            <Password />
          </Box>
        </Container>
      </Page>
    </div>
  );
};

export default SettingsView;
