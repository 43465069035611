import React, { useEffect, useState } from 'react';
import {
  Grid,
} from '@material-ui/core';
import apis from 'src/api/apis';

const GameDetailsPartOne = (props) => {
  const { gameLevel, gameName, gameCode, gameType, gameSubject, gameConcept, gameSkill, gameDescription, gameObjectives, gameOutcome, gameTechnology, gameLevelRewardPoints
  } = props.selectedGameData;
  let rewardPartOne = [], rewardPartTwo = [];
  const [game,setGame] = useState(null);

  const rows = [{
    header: "Game Name",
    content: gameName
  },
  {
    header: "Game Code",
    content: gameCode
  },
  {
    header: "Game Type",
    content: gameType
  },
  {
    header: "Subject",
    content: gameSubject
  },
  {
    header: "Concept",
    content: gameConcept
  }, ,
  {
    header: "Skill",
    content: gameSkill
  }]
  useEffect(()=>{
apis.get("/get/game/2").then((res)=>{
setGame(res)
}).catch((err)=>{
console.log(err)
})
  },[])
  const gameInformation = [{
    header: "Game Tech",
    content: gameTechnology
  },
  {
    header: "Description",
    content: gameDescription
  }, {
    header: "Objectives",
    content: gameObjectives
  }
    , {
    header: "Learning Outcome",
    content: gameOutcome
  }]
console.log(gameLevelRewardPoints)
  if (Array.isArray(gameLevelRewardPoints) && gameLevelRewardPoints.length > 0) {
    gameLevelRewardPoints?.map((value, index) => {
      if (index % 2 == 0) {
        rewardPartTwo.push({ header: `Level ${index + 1}`, content: value.gameRewardPoint })
      }
      else {
        rewardPartOne.push({ header: `Level ${index + 1}`, content: value.gameRewardPoint })
      }
    })
  }

  return <>
    {rows.map((row) => (<Grid container spacing={3}>
      <Grid item xs={2} sm={2} md={2} lg={2}>{row.header}</Grid>
      <Grid item xs={10} sm={10} md={10} lg={10}>{row.content}</Grid>
    </Grid>))}
    <hr />
    <Grid container spacing={3}>
      <Grid item xs={2} sm={2} md={2} lg={2}><b>Game Levels</b></Grid>
      <Grid item xs={10} sm={10} md={10} lg={10}><b>{gameLevel}</b></Grid>
    </Grid>

    <b>Reward Points</b>
    {rewardPartOne.length > 0 && <Grid container spacing={3}>
      <Grid item xs={6} sm={6} md={6} lg={6}>{rewardPartTwo.length > 0 && rewardPartTwo.map((row) => (<Grid container spacing={3}>
        <Grid item xs={2} sm={2} md={2} lg={2}>{row.header}</Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>{row.content}</Grid>
      </Grid>
      ))}</Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>{rewardPartOne.length > 0 && rewardPartOne.map((row) => (<Grid container spacing={3}>
        <Grid item xs={2} sm={2} md={2} lg={2}>{row.header}</Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>{row.content}</Grid>
      </Grid>
      ))}</Grid></Grid>}
    <hr />
    <b>Game Information</b>
    {gameInformation.map((row) => (<Grid container spacing={3}>
      <Grid item xs={2} sm={2} md={2} lg={2}>{row.header}</Grid>
      <Grid item xs={10} sm={10} md={10} lg={10}>{row.content}</Grid>
    </Grid>))}
  </>
}
export default GameDetailsPartOne;