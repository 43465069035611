import * as React from 'react';
import { useState } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';
//import VisibilityIcon from '@material-ui/icons/Visibility';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

const AssignPlanView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const redirect_school = () => {
    navigate('/app/manage/assignPlanSchoolSectionView');
  };
  const redirect = () => {
    navigate('/app/dashboard');
  };
  /* const [values, setValues]= useState({ 
    school_search: ''
  });  */

  const columns = [
    {
      field: 'school_name',
      headerName: 'School Name',
      /* flex: 1, */ width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'address',
      headerName: 'Address',
      /* flex: 1, */ width: 220,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'account_manager',
      headerName: 'Account Manager',
      /* flex: 1, */ width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'location',
      headerName: 'Location',
      /* flex: 1, */ width: 220,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'action',
      headerName: 'Action',
      /* flex: 0.5, */ width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (/* params: CellParams */) => (
        <div>
          <Tooltip title="Assign Plan">
            <Button
              variant="contained"
              color="white"
              align="left"
              width="10px"
              style={{marginLeft:"45%"}}
              //className={classes.button}
              //startIcon={<VisibilityIcon />}
              startIcon={<LaunchOutlinedIcon />}
              onClick={redirect_school}
            ></Button>
          </Tooltip>
          {/* <Button variant="contained"
    color="white"
    align='right'
    width='10px'
    //className={classes.button}
    startIcon={<LaunchOutlinedIcon />}
      onClick={() => {
        const api: GridApi = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);
        const thisRow = {};

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });

        return alert(JSON.stringify(thisRow, null, 4));
        //return alert('got it');
      }}></Button> */}
        </div>
      )
    }
  ];

  const rows = [
    {
      id: 1,
      school_name: 'noname@nothing.new',
      address: '+19999999999',
      account_manager: 'Boss',
      location: 'Pune, MH',
      action: 'My School'
    },
    {
      id: 2,
      school_name: '1noname@nothing.new',
      address: '+19999999999',
      account_manager: 'Boss',
      location: 'Pune, MH',
      action: 'My School'
    },
    {
      id: 3,
      school_name: 'noname@nothing.new',
      address: '+29999999999',
      account_manager: 'Boss',
      location: 'Mumbai, MH',
      action: 'My School'
    },
    {
      id: 4,
      school_name: 'moname@nothing.new',
      address: '+19999999999',
      account_manager: 'Boss',
      location: 'Pune, MH',
      action: 'My School'
    }
    /*{ id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },*/
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 420, width: '100%' }} className={classes.root}>
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / Schools </span></div> */}
      {/* <br/> */}
      <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */ fontSize: 25
          }}
        >
          Assign Plans
        </span>
        {/* <span style={{ float: 'right' }}>
          <Tooltip title="Go to Home">
            <Button
              color="primary"
              variant="contained"
              startIcon={<HomeIcon />}
              onClick={redirect}
            >
              Home
            </Button>
          </Tooltip>
        </span> */}
      </div>
      {/* <div>      
      <div style={{textAlign:'right'}}>
        <Tooltip title="Go To Home " >
        <Button
        color="primary"
        variant="contained"
        startIcon={<HomeIcon />}
        onClick={redirect}
      >
      Home
      </Button>
      </Tooltip>
      </div>
      <br />
    </div> */}
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            pageSize="5"
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignPlanView;
