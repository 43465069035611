import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

export const KSelect = ({ name, control, label, options = [] }) => {
  const generateSingleOptions = selectOptions => {
    return selectOptions.map(option => {
      return <MenuItem value={option.value}>{option.label}</MenuItem>;
    });
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState
      }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          margin="dense"
          variant="outlined"
          select
        >
          {generateSingleOptions(options)}
        </TextField>
      )}
    />
  );
};
