import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';
import VisibilityIcon from '@material-ui/icons/Visibility';
//import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../../api/Axios';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

const SubscriptionDetailView = ({ props, className, ...rest }) => {
  //const { id } = useParams();
  //console.log("id", id);
  const { state } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    api
      .getSubscriptionDetailsBasedOnSchoolId(state.schoolId)
      .then(response => {
        console.log(response, 'responsen of subscription details');
        const responseTransformed = response.data.response.body.entity.map(
          (item, index) => ({ ...item, id: index })
        );
       
        responseTransformed.map((v,k) => {
        v.startDate=new Date(v.startDate).toDateString();
        v.endDate=new Date(v.endDate).toDateString();
        });
        setRows(responseTransformed);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
        setLoading(false);
      });
  }, []);
  const redirect_children = subscriptionId => {
    navigate('/app/manage/licenseDetail', { state: { subscriptionId } });
  };
  const redirect = schoolId => {
    navigate('/app/manage/addSubscription', { state: { schoolId } });
  };
  const redirect_home = () => {
    navigate('/app/dashboard');
  };
  /* const [values, setValues] = useState({ 
    school_search: ''
  }); */

  const role = localStorage.getItem('role');

  const columns = [
    {
      field: 'subscriptionName',
      headerName: 'Subscription Name',
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'noOfChildren',
      headerName: 'Number of Children',
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'utilisation',
      headerName: 'Utilization',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
    },
    {
      field: 'isActive',
      headerName: 'Status',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center'
      // renderCell: (params: CellParams) => (
      //   <div>
      //     <Button
      //       variant="contained"
      //       //backgroundColor="green"
      //       color="inherit"
      //       align="left"
      //       width="10px"
      //       //className={classes.button}
      //       //startIcon={<VisibilityIcon />}
      //       onClick={null}
      //     >
      //       Active
      //     </Button>
      //   </div>
      // )
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: CellParams) => (
        <div>
          <Button
            variant="contained"
            color="white"
            align="left"
            width="10px"
            style={{marginLeft:"45%"}}
            //className={classes.button}
            startIcon={<VisibilityIcon />}
            onClick={() => {
              redirect_children(params.row.subscriptionChildSchoolId);
            }}
          />
          {/* <Button variant="contained"
                color="white"
                align='right'
                width='10px'
                //className={classes.button}
                startIcon={<VisibilityIcon />}
                onClick={() => {
                    const api: GridApi = params.api;
                    const fields = api
                    .getAllColumns()
                    .map((c) => c.field)
                    .filter((c) => c !== "__check__" && !!c);
                    const thisRow = {};

                    fields.forEach((f) => {
                    thisRow[f] = params.getValue(f);
                    });

                    return alert(JSON.stringify(thisRow, null, 4));
                    //return alert('got it');
                }}
            /> */}
        </div>
      )
    }
  ];

  

  /*  useEffect(() => {
    axios
      .get(
        `http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/schools/${id}`,
        {
          header: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      .then(response => {
        setRowsData(response.data.response.body);
        setLoading(false);
        console.log(response.data.response.body);
      })
      .catch(err => {
        console.log(err);
      });
  }, []); */

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 420, width: '100%' }} className={classes.root}>
      {/* <div>
            <span className={classes.title}  style={{color: '#e91e63', }}>
                Manage / Subscriptions / Subscription Details 
            </span>
        </div> */}
      {/* <br/> */}
      <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
        <span
          className={classes.title}
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */ fontSize: 25
          }}
        >
          {} Subscription Details
        </span>
        <span style={{ float: 'right', paddingLeft: 2 }}>
          <Tooltip title="Add subscription">
            <Button
              color="primary"
              variant="contained"
              //startIcon={<HomeIcon />}
              onClick={() => redirect(state.schoolId)}
              disabled={role === 'user' ? true : false}
            >
              ADD SUBSCRIPTION
            </Button>
          </Tooltip>
        </span>
        {/* <span style={{float: 'right'}}>
          <Tooltip title="Go to Home" >
            <Button
              color="primary"
              variant="contained"
              startIcon={<HomeIcon />}
              onClick={redirect_home}
            >
              Home
            </Button>
          </Tooltip>
        </span> */}
      </div>
      {/* <div>      
      <div style={{textAlign:'right'}}>
          <Button
            color="primary"
            variant="contained"
            //startIcon={<HomeIcon />}
            onClick={redirect}
            disabled = {(role === 'user') ? true : false}>
                Add Subscription
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<HomeIcon />}
            style={{marginLeft: '10px'}}
            onClick={redirect_home}>
                Home
          </Button>
        </div>
        <br />
    </div> */}
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            pageSize="5"
            loading={loading}
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetailView;
