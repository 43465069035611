import React, { useState, useEffect, useContext } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Tooltip,
  withStyles,
  Switch,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import axios from 'axios';
import api from '../../../../../api/Axios';
import { ConfigureContext } from '../../../Context/ConfigureContext';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
    },
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    //paddingBottom: theme.spacing(3),
    //paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  title: {
    //textAlign: 'center'
  }
}));

export default function ViewUsers() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const { setValues, setRoleAssign, setReportingToAssign } = useContext(
    ConfigureContext
  );

  useEffect(() => {
    api
      .getListOfAllAdmins()
      .then(response => {
        console.log(response.data.response.body);
        const rows = response.data.response.body.map(item => {
          item['id'] = item.userId;
          item.fullName = item.firstName + ' ' + item.lastName;

          return item;
        });

        console.log(rows);
        setRows(rows);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const redirect = () => {
    navigate('/app/config/adminUser/createadminuserview');
  };
  const classes = useStyles();

  const handleEdit = (/* planName, ageGroup, id, */ params) => {
    console.log('edit row', params.row);
    navigate(`/app/config/adminUser/createadminuserview/${params.row.id}`);
    setValues(params.row);
    setRoleAssign(params.row.role);
    setReportingToAssign();
  };

  const handleToggle = (e, id, params) => {
    let newUser = rows.map(item => {
      if (id === item.id) {
        item.isActive = e.target.checked;
      }
      return item;
      //console.log(e, id, params)
    });
    setRows(newUser);
    console.log(params.row, 'params.row');
    console.log(newUser, 'newUser');
    api
      .editAdminUser(params.row)
      .then(response => {
        console.log('response', response);
        alert(
          params.row.isActive
            ? 'User marked active successfully!'
            : 'User marked inactive successfully!'
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#e91e63',
      '&$checked': {
        color: '#008080'
      },
      '&$checked + $track': {
        backgroundColor: '#000',
        opacity: 0.38
      }
    },
    checked: {},
    track: {}
  })(Switch);

  const columns = [
    {
      field: 'fullName',
      //field: 'name',
      headerName: 'Name',
      //flex: 1,
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'userEmail',
      //field: 'emailId',
      headerName: 'Email ID',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'userMobNumber',
      //field: 'mobileNumber',
      headerName: 'Mobile Number',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },

    {
      field: 'associatedSchool',
      headerName: 'Associated School',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'isActive',
      //field: 'status',
      headerName: 'Status',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'action',
      headerName: 'Action',
      //width: 168,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: params => (
        <div style={{ display: 'contents' }}>
          <span>
            {/* <Tooltip title={'Publish this plan'}> */}
            <FormControl style={{ display: 'flex' }}>
              <FormGroup style={{ marginBottom: 0 }}>
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <CustomSwitch
                      //disabled={toggle ? true : false}
                      checked={params.row.isActive}
                      onChange={e => {
                        handleToggle(e, params.row.id, params);
                        console.log(params);
                      }}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
            {/*  </Tooltip> */}
          </span>
          <span>
            <IconButton
              //disabled={hide ? '' : true}
              style={{ color: '#008080' }}
              onClick={() => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map(c => c.field)
                  .filter(c => c !== '__check__' && !!c);
                const thisRow = {};
                fields.forEach(f => {
                  thisRow[f] = params.getValue(f);
                  console.log(thisRow);
                });
                handleEdit(params);
              }}
            >
              <EditIcon />
            </IconButton>
          </span>
        </div>
      )
    }
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageSizeChange = newPageSize => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to the first page when changing page size
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  return (
    <div style={{ width: '100%' }} className={classes.root}>
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '10px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div style={{ width: '25%' }}></div>
        <div
          style={{
            width: '50%',
            textAlign: 'center',
            color: '#e91e63',
            fontSize: 25
          }}
        >
          All Users
        </div>
        <div
          style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={redirect}
            variant="contained"
            color="primary"
            //style={{ float: 'flex-end' }}
            //edge="end"
            style={{
              textTransform: 'none',
              fontSize: '1rem'
              //float: 'right'
            }}
          >
            Create Admin
          </Button>
        </div>
      </div>
      <Box sx={{ height: '500px', width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5
              }
            }
          }}
          rowsPerPageOptions={[5, 10, 50]}
          disableRowSelectionOnClick
        />
      </Box>
      {/* <DataGrid
        rows={rows}
        loading={loading}
        columns={columns}
        // pageSize={pageSize}
        // page={currentPage}
        // onPageChange={params => handlePageChange(params.page)}
        // pageSizeOptions={[5, 10, 20]} // Options for page size selection
        // onPageSizeChange={params => handlePageSizeChange(params.pageSize)}
        // disableSelectionOnClick
      /> */}
      {/* <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            rows={rows}
            loading={loading}
            columns={columns}
            pageSize={pageSize}
            page={currentPage}
            onPageChange={params => handlePageChange(params.page)}
            pageSizeOptions={[5, 10, 20]} // Options for page size selection
            onPageSizeChange={params => handlePageSizeChange(params.pageSize)}
            disableSelectionOnClick
          />
        </div>
      </div> */}
    </div>
  );
}
