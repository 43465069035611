import * as React from 'react';
import {
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
//import ReportCard from './ReportCard';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Grid, Avatar, Box } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
import {useDispatch} from 'react-redux';
const useStyles = makeStyles({
  root: {
    //minWidth: 275,
    padding: 20,
    marginRight: 15,
    marginTop: 15,
    heigth: '95%',
    overflow: 'auto'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14,
    align: 'center'
  },
  pos: {
    marginBottom: 12
  },
  customizedButton: {
    left: '95%',
    right: '-9%',
    backgroundColor: '#e91e63',
    color: 'black'
  }
});

const DashboardView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const redirect = () => {
    navigate('/app/dashboard');
  };

  const redirectAddGames = () => {
    navigate('/app/config/game/creategame');
  };
  const redirectViewGames = () => {
    navigate('/app/config/game/viewgame');
  };
  const redirectAddPlans = () => {
    navigate('/app/config/plans/createplans');
  };
  const redirectViewPlans = () => {
    navigate('/app/config/plans/viewplans');
  };

  /* const [values,setValues]= useState({ 
    school_search: ''
  });  */

  const today = new Date();
  const date =
    today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
  const number_of_children = '2000';
  const number_of_parents = '1000';

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Box
            display="flex"
            m={3}
            //mb={1}
            mt={1}
            //padding="20"
          >
            <Box
              flexDirection="column"
              display="flex"
              m={3}
              mt={1}
              ml={5}
              border={1}
              width={300}
              height={200}
              borderRadius="50%"
              borderRadius="borderRadius"
              borderRadius={16}
              style={{ border: '2px solid #e91e63' }}
            >
              <Box flexDirection="row" display="flex">
                <Box m={2} ml={12} mt={3} height={20} order={1}>
                  <Avatar
                    src="/static/images/avatars/avatar_12.png"
                    align="left"
                  />
                </Box>
                <Box m={2} mt={5} ml={0} height={20} order={2}>
                  <Typography align="right" variant="h4" component="h1">
                    Children
                  </Typography>
                </Box>
              </Box>
              <Box flexDirection="row" display="flex">
                <Box
                  m={2}
                  ml={2}
                  mt={2}
                  height={87}
                  width={135}
                  order={1}
                  color="#ffffff"
                  style={{ backgroundColor: '#e91e63' }}
                >
                  <Typography align="center" variant="h5" component="h1">
                    Total Children
                  </Typography>
                  <br />
                  <Typography align="center" variant="h4" component="h1">
                    {number_of_children}
                  </Typography>
                </Box>
                <Box
                  m={2}
                  ml={0}
                  mt={2}
                  height={87}
                  width={135}
                  order={2}
                  color="#ffffff"
                  style={{ backgroundColor: '#e91e63' }}
                >
                  <Typography align="center" variant="h5" component="h1">
                    Active Children
                  </Typography>
                  <br />
                  <Typography align="center" variant="h4" component="h1">
                    {number_of_children}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              flexDirection="column"
              display="flex"
              m={3}
              mt={1}
              ml={8}
              border={1}
              width={300}
              height={200}
              borderRadius="50%"
              borderRadius="borderRadius"
              borderRadius={16}
              style={{ border: '2px solid #e91e63' }}
              //mt={3}
            >
              <Box flexDirection="row" display="flex">
                <Box m={2} ml={12} mt={3} height={20} order={1}>
                  <Avatar
                    src="/static/images/avatars/avatar_13.png"
                    align="left"
                  />
                </Box>
                <Box m={2} mt={5} ml={0} height={20} order={2}>
                  <Typography align="right" variant="h4" component="h1">
                    Parents
                  </Typography>
                </Box>
              </Box>
              <Box flexDirection="row" display="flex">
                <Box
                  m={2}
                  ml={2}
                  mt={2}
                  height={87}
                  width={135}
                  order={1}
                  color="#ffffff"
                  style={{ backgroundColor: '#e91e63' }}
                >
                  <Typography align="center" variant="h5" component="h1">
                    Total Parents
                  </Typography>
                  <br />
                  <Typography align="center" variant="h4" component="h1">
                    {number_of_parents}
                  </Typography>
                </Box>
                <Box
                  m={2}
                  ml={0}
                  mt={2}
                  height={87}
                  width={135}
                  order={2}
                  color="#ffffff"
                  style={{ backgroundColor: '#e91e63' }}
                >
                  <Typography align="center" variant="h5" component="h1">
                    Active Parents
                  </Typography>
                  <br />
                  <Typography align="center" variant="h4" component="h1">
                    {number_of_parents}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* </Box> */}
        </CardContent>

        <CardContent>
          <Box
            flexDirection="column"
            display="flex"
            m={3}
            mt={-5}
            border={1}
            borderRadius="50%"
            borderRadius="borderRadius"
            width={775}
            height={200}
            borderRadius={16}
            style={{ border: '2px solid #E3DDDB' }}
            //padding="20"
          >
            <Box flexDirection="row" display="flex">
              <Box
                m={3}
                mt={3}
                ml={4}
                order={1}
                borderRadius="50%"
                borderRadius="borderRadius"
                width={300}
                height={50}
                borderRadius={16}
                style={{ border: '2px solid #E3DDDB' }}
              >
                <Box flexDirection="row" display="flex">
                  <Box m={2} ml={3} mt={0.5} height={20} order={1}>
                    <Avatar
                      src="/static/images/avatars/avatar_14.png"
                      align="left"
                    />
                  </Box>
                  <Box m={2} mt={1.5} height={20} order={2}>
                    <Typography align="right" variant="h4" component="h1">
                      Add Games
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={redirectAddGames}
                    color="primary"
                    className={classes.customizedButton}
                  >
                    <AddIcon style={{ fontSizeLarge: 'small' }} />
                  </IconButton>
                </Box>
              </Box>
              {/*            <Button
              variant="contained"
              color="white"
              align="center"
              //className={classes.button}
              startIcon={<AssessmentIcon />}
            >

            </Button>*/}
              <Box
                m={3}
                mt={3}
                ml={8}
                order={2}
                borderRadius="50%"
                borderRadius="borderRadius"
                width={300}
                height={50}
                borderRadius={16}
                style={{ border: '2px solid #E3DDDB' }}
              >
                <Box flexDirection="row" display="flex">
                  <Box m={2} ml={3} mt={0.5} height={20} order={1}>
                    <Avatar
                      src="/static/images/avatars/avatar_14.png"
                      align="left"
                    />
                  </Box>
                  <Box m={2} mt={1.5} height={20} order={2}>
                    <Typography align="right" variant="h4" component="h1">
                      View Games
                    </Typography>
                  </Box>
                  <IconButton
                    autoFocus
                    onClick={redirectViewGames}
                    color="primary"
                    className={classes.customizedButton}
                  >
                    <VisibilityIcon style={{ fontSizeLarge: 'small' }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box flexDirection="row" display="flex">
              <Box
                m={3}
                mt={3}
                ml={4}
                order={1}
                borderRadius="50%"
                borderRadius="borderRadius"
                width={300}
                height={50}
                borderRadius={16}
                style={{ border: '2px solid #E3DDDB' }}
              >
                <Box flexDirection="row" display="flex">
                  <Box m={2} ml={3} mt={0.5} height={20} order={1}>
                    <Avatar
                      src="/static/images/avatars/avatar_15.png"
                      align="left"
                    />
                  </Box>
                  <Box m={2} mt={1.5} height={20} order={2}>
                    <Typography align="right" variant="h4" component="h1">
                      Add Plans
                    </Typography>
                  </Box>
                  <IconButton
                    autoFocus
                    onClick={redirectAddPlans}
                    color="primary"
                    className={classes.customizedButton}
                  >
                    <AddIcon style={{ fontSizeLarge: 'small' }} />
                  </IconButton>
                </Box>
              </Box>
              <Box
                m={3}
                mt={3}
                ml={8}
                order={2}
                borderRadius="50%"
                borderRadius="borderRadius"
                width={300}
                height={50}
                borderRadius={16}
                style={{ border: '2px solid #E3DDDB' }}
              >
                <Box flexDirection="row" display="flex">
                  <Box m={2} ml={3} mt={0.5} height={20} order={1}>
                    <Avatar
                      src="/static/images/avatars/avatar_15.png"
                      align="left"
                    />
                  </Box>
                  <Box m={2} mt={1.5} height={20} order={2}>
                    <Typography align="right" variant="h4" component="h1">
                      View Plans
                    </Typography>
                  </Box>
                  <IconButton
                    autoFocus
                    onClick={redirectViewPlans}
                    color="primary"
                    className={classes.customizedButton}
                  >
                    <VisibilityIcon style={{ fontSizeLarge: 'small' }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default DashboardView;
