import React, { useEffect, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import {
  activeGridFilterItemsSelector,
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer,
  useGridSlotComponentProps
} from '@material-ui/data-grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Box,
  CardHeader,
  Divider,
  Card,
  CardContent,
  Grid,
  form,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl
} from '@material-ui/core';
import axios from 'axios';
import { FormGroup } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { BorderColor } from '@material-ui/icons';
import { GiConsoleController } from 'react-icons/gi';
import data from 'src/views/Configure/Games/ViewGame/GameView/data';
import { ConfigureContext } from '../../../Context/ConfigureContext';
import api from '../../../../../api/Axios';
import apis from 'src/api/apis';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(3)
    },
    height: '100%'
    //paddingBottom: theme.spacing(3),
    //paddingTop: theme.spacing(3),
    //paddingLeft: theme.spacing(15),
    //paddingRight: theme.spacing(3)
  },
  new: {
    //paddingLeft: theme.spacing(15)
  },
  cardHeader: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    //color: '#e91e63',
    //fontSize: 25,
    paddingRight: '40px',
    paddingLeft: '40px'
  },
  main: {
    '& .MuiDataGrid-root .MuiDataGrid-colCellWrapper ': {
      //backgroundColor: '#e91e63',
      backgroundColor: '#008080',
      color: 'white'
    },
    //backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));



function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();
  return (
    <Pagination
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function PublishedPlanView() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [rowsData, setRowsData] = useState([]);
  const [hide, setHide] = useState(false);
  const [hideButtonPublished, setHideButtonPublished] = useState(false);
  const [hideButtonUnpublished, setHideButtonUnpublished] = useState(true);
  const [value, setValue] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [open, setOpen] = useState(false);
  const [planNameDetails, setPlanNameDetails] = useState();
  const [ageGroupDetails, setAgeGroupDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleToggle = (e, id, params) => {
    let newRows = rowsData.map(item => {
      if (id == item.id) {
        item.isPublished = e.target.checked;
      }
      return item;
    });
    setRowsData(newRows);

    api
      .publishUnpublishPlan(params.row)

      .then(response => {
        console.log('response', response);
        alert(
          params.row.isPublished
            ? 'Plan published successfully!'
            : 'Plan unpublished successfully!'
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewPublishedPlanDetail = (planName, ageGroup) => {
    setOpen(true);
    setPlanNameDetails(planName);
    setAgeGroupDetails(ageGroup);

    api
      .viewPlanDetails(planName)
      .then(response => {
        setLoading(false);
        const plan = response.data.response.body.entity;
        console.log('plan details', plan);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#e91e63',
      '&$checked': {
        color: '#008080'
      },
      '&$checked + $track': {
        backgroundColor: '#000',
        opacity: 0.38
      }
    },
    checked: {},
    track: {}
  })(Switch);

  const {
    planId,
    setPlanId,
    setPlanName,
    setAgeGroup,
    planGameDependency,
    newRows
  } = useContext(ConfigureContext);

  const handleEdit = (planName, ageGroup, id) => {
    navigate(`/app/config/plans/createplans/${id}`);
    api
      .viewPlanDetails(planName)
      .then(response => {
        console.log(response);
        setPlanId(id);
        console.log(planId, 'planidddddddddddd');
        const editPlanItem = response.data.response.body.entity.map(
          arr => arr.planGameDependency
        )[0];

        console.log('EditPlanItem', editPlanItem);

        newRows(editPlanItem);
        //console.log('in edit plan game dependency', planGameDependency);
      })
      .catch(error => console.log(error));

    setPlanName(planName);
    setAgeGroup(ageGroup);
  };

  const columns = [
    {
      field: 'planId',
      headerName: 'ID',
      width: 100,
      headerAlign: 'center'
    },
    {
      field: 'planName',
      headerName: 'Plan Name',
      width: 250,
      headerAlign: 'center'
    },
    {
      field: 'ageGroup',
      headerName: 'Age Group',
      width: 250,
      headerAlign: 'center'
    },
    {
      field: 'isPublished',
      headerName: 'Published/UnPublished',
      width: 250,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <Tooltip title={toggle ? '' : 'Publish this plan'}>
            <FormControl>
              <FormGroup style={{ marginBottom: 0 }}>
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <CustomSwitch
                      //disabled={toggle ? true : false}
                      checked={params.row.isPublished}
                      onChange={e => {
                        handleToggle(e, params.row.id, params);
                      }}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Tooltip>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <div style={{ display: 'contents' }}>
            <span>
              <IconButton
                style={{ color: '#008080' }}
                onClick={() => {
                  const api = params.api;
                  const fields = api
                    .getAllColumns()
                    .map(c => c.field)
                    .filter(c => c !== '__check__' && !!c);
                  const thisRow = {};
                  fields.forEach(f => {
                    thisRow[f] = params.getValue(f);
                    console.log(thisRow);
                  });
                  handleEdit(
                    thisRow['planName'],
                    thisRow['ageGroup'],
                    thisRow['planId']
                  );
                }}
              >
                <EditIcon />
              </IconButton>
            </span>
            <span>
              <IconButton
                onClick={() => {
                  const api = params.api;
                  const fields = api
                    .getAllColumns()
                    .map(c => c.field)
                    .filter(c => c !== '__check__' && !!c);
                  const thisRow = {};
                  fields.forEach(f => {
                    thisRow[f] = params.getValue(f);
                    //console.log(thisRow);
                  });
                  handleViewPublishedPlanDetail(
                    thisRow['planName'],
                    thisRow['ageGroup']
                  );
                }}
              >
                <VisibilityIcon style={{ color: '#008080' }} />
              </IconButton>
            </span>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              PaperProps={{
                style: {
                  borderRadius: 10,
                  borderWidth: 5,
                  borderStyle: 'solid',
                  borderColor: '#e91e63'
                }
              }}
              BackdropProps={{
                style: {
                  backgroundColor: 'rgba(0,0,0,0.1)'
                }
              }}
            >
              <div style={{ backgroundColor: '#008080' }}>
                <DialogTitle
                  style={{
                    color: 'white'
                  }}
                  align="center"
                  id="form-dialog-title"
                >
                  Plan Details
                </DialogTitle>
              </div>
              <DialogContent>
                <div>Plan Name: {planNameDetails}</div>
                <div>Plan age group: {ageGroupDetails}</div>
                <div>Game names: game1, game 2</div>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                  style={{
                    textTransform: 'none',
                    fontSize: '1rem'
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
    }
  ];

  const redirect = () => {
    navigate('/app/config/plans/createplans');
  };

  function CustomToolbar() {
    const unPublished = () => {
      //unPRows(rowsUnPub);
      //unPColumns(columnsUnPub);
      setHide(!hide);
      setHideButtonPublished(!hideButtonPublished);
      setHideButtonUnpublished(!hideButtonUnpublished);
      setToggle(!toggle);
      setValue(false);
    };
    const published = () => {
      //unPRows(rowsPub)
      //unPColumns(columnsPub)
      setHide(!hide);
      setHideButtonPublished(!hideButtonPublished);
      setHideButtonUnpublished(!hideButtonUnpublished);
      setToggle(true);
      setValue(true);
    };

    return (
      <div>
        {/* <br />
        <br />
        <div style={{ textAlign: 'center', marginLeft: '8%' }}>
          <span
            className={classes.title}
            align="center"
            style={{ color: '#e91e63' }}
          >
            View Plans
          </span>
        </div> */}
        <GridToolbarContainer
          style={{ padding: '10px', justifyContent: 'space-between' }}
        >
          <GridFilterToolbarButton />
          <div className={classes.root}>
            <span>
              <Button
                variant={!hideButtonPublished ? 'outlined' : 'contained'}
                onClick={published}
                disabled={hideButtonPublished ? false : true}
                //color="primary"
                style={{
                  textTransform: 'none',
                  fontSize: '1rem',
                  //backgroundColor: hideButton ? '#008080' : 'primary',
                  backgroundColor:
                    hideButtonPublished === false ? '#008080' : 'white',
                  //color: hideButton ? '' : '#008080',
                  color: hideButtonPublished === false ? '#fff' : '#008080',
                  borderColor: hideButtonPublished === false ? '' : '#008080',
                  border: '1px solid'
                }}
              >
                Published Plans
              </Button>
            </span>
            <span>
              <Button
                variant={hideButtonPublished ? 'contained' : 'outlined'}
                onClick={unPublished}
                disabled={!hideButtonPublished ? false : true}
                //color="primary"
                style={{
                  textTransform: 'none',
                  fontSize: '1rem',
                  /* backgroundColor: hideButton === true ? '#008080' : 'primary',
                  color: hideButton === true ? '' : '#008080',
                  borderColor: hideButton === true ? '' : '#008080', */
                  backgroundColor:
                    hideButtonUnpublished === true ? '' : '#008080',
                  color: hideButtonUnpublished === true ? '#008080' : '#fff',
                  borderColor: hideButtonUnpublished === true ? '#008080' : '',
                  border: '1px solid'
                }}
              >
                Unpublished Plans
              </Button>
            </span>
            {/* <span>
              <Button
                onClick={redirect}
                variant="contained"
                color="primary"
                //style={{ float: 'flex-end' }}
                //edge="end"
                style={{
                  textTransform: 'none',
                  fontSize: '1rem'
                }}
              >
                Create Plans
              </Button>
            </span> */}
          </div>
        </GridToolbarContainer>
      </div>
    );
  }

  useEffect(() => {
    apis
      .get(
        `Plan/get/all?pageSize=${pageSize}&pageNumber=${pageNumber}&isPublished=${!hideButtonPublished}`
      )
      .then(res => {
        setTotalCount(res.data?.response?.body.totalElements);
        const temp = [...res.data?.response?.body?.content];
        const updatedTemp = temp.map(p => {
          const i = { ...p };
          i.id = p.planId;
          return i;
        });
        setRowsData(updatedTemp);
        
      })
      .catch(err => {
        console.log(err);
        setRowsData([]);
      });
  }, [hideButtonPublished, pageNumber, pageSize]);
  console.log(rowsData);
  const handlePageSizeChange = (e, newPageSize) => {
    setPageSize(e.pageSize);
    setPageNumber(0); // Reset to the first page when changing page size
  };

  const handlePageChange = (e, newPage) => {
    setPageNumber(e.page);
  };
  return (
    <div className={classes.new}>
      <div className={classes.cardHeader}>
        <div style={{ width: '25%' }}></div>
        <div
          style={{
            width: '50%',
            textAlign: 'center',
            // justifyContent: 'center',
            color: '#e91e63',
            fontSize: 25
          }}
        >
          View Plans
        </div>
        <div
          style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={redirect}
            variant="contained"
            color="primary"
            //style={{ float: 'flex-end' }}
            //edge="end"
            style={{
              textTransform: 'none',
              fontSize: '1rem'
              //float: 'right'
            }}
          >
            Create Plans
          </Button>
        </div>
      </div>
      <div
        className={classes.main}
        style={{
          height: 420,
          width: '100%',
          paddingBottom: 5
        }}
      >
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              pagination
              components={{
                Toolbar: CustomToolbar
              }}
              rows={rowsData}
              columns={columns}
              pageSize={pageSize}
              page={pageNumber}
              rowsPerPageOptions={[5, 10, 20]}
              rowCount={totalCount}
              disableSelectionOnClick
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              paginationMode="server"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
