//CreateVariablesView
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    form,
    Typography,
    makeStyles,
    Fab,
    Paper
  } from '@material-ui/core';
  import {Form,Dropdown,Row,Col,ButtonGroup }from 'react-bootstrap';
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingLeft:theme.spacing(15)
      
    },
  }));

  const CreateVariablesView = ({ className, ...rest }) => {
    const classes = useStyles();
    const navigate = useNavigate();
   

  
  return (
    <div >
    <span className={classes.title} align='center' style={{color: '#e91e63', }}>
    Create Game</span>
    
    <div style={{margin:"auto"}}>
    <form
      autoComplete="off"
     
      noValidate
      className={clsx(classes.root, className)}
      
      {...rest}
    >
    <Card style={{width:"600px",margin:"auto"}} >

      
        </Card>
    </form>
    </div> 
 </div>
  );
};

CreateVariablesView.propTypes = {
  className: PropTypes.string
};

export default CreateVariablesView;