import React, { useEffect, useState } from 'react';
import {
    Radio, RadioGroup, Grid, FormControlLabel, Button
} from '@material-ui/core';
//import "../ViewGame.css";
import {
    makeStyles,
} from '@material-ui/core';
import GameVersionHistory from '../../CreateGameV2/CreateGameView/GameVersionHistory';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#008080',
            color: 'white'
        },
        backgroundColor: 'theme.palette.background.dark',
        height: '100%',
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(3)
    },
}));
const successButton = {
    color: 'white',
    background: 'green',
    borderRadius: '100px'
}
const warningButton = {
    color: 'white',
    background: 'red',
    borderRadius: '100px'
}
const GameDetailsPartTwo = (props) => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = React.useState('url');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const [gamePackageData, setGamePackageData] = useState({});
    const gamePageInformation = [{
        header: "Game URL",
        content: "-"
    }]

    useEffect(() => {
        if (props.gamePackageData) {
            try {
                const parsedData = JSON.parse(props.gamePackageData);
                setGamePackageData(parsedData)
            }catch(e){
                console.log("not a proper data"+e);
            }
        }
    }, [props.gamePackageData])

    const columns = [

        {
            field: 'version',
            headerName: 'Version',
            width: 200
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300
        },

        {
            field: 'url',
            headerName: 'URL',
            width: 300
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 300
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (cellValues) => (<Button style={cellValues.value === 'Active' ? successButton : warningButton} variant="contained" color="primary">
                {cellValues.value}
            </Button>)
        },
    ];

    return <>
        <Grid container spacing={3}>
            <Grid item xs={2} sm={2} md={2} lg={2}>Upload game Package</Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>

                <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                >
                    <FormControlLabel value="end" control={<Radio checked={selectedValue === 'url'}
                        onChange={handleChange}
                        value="url"
                        disabled={true}
                        name="radio-buttons" />} label="URL" />
                    <FormControlLabel value="end" control={<Radio checked={selectedValue === 'fileUpload'}
                        onChange={handleChange}
                        value="fileUpload"
                        disabled={true}
                        name="radio-buttons" />} label="File upload" />
                </RadioGroup>
            </Grid>
        </Grid>
        {gamePageInformation.map((row) => (<Grid container spacing={3}>
            <Grid item xs={2} sm={2} md={2} lg={2}>{row.header}</Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>{gamePackageData.gameURL}</Grid>
        </Grid>))}
        <div className={classes.root}>
            <div style={{paddingBottom: '10px'}}>Game History Details</div>
            <GameVersionHistory gameId={props.gameId}/>
        </div>
    </>
}
export default GameDetailsPartTwo;