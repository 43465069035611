import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//import ImageUploader from 'react-images-upload';
import {
  //Box,
  Button,
  //Avatar,
  Card,
  CardContent,
  //CardHeader,
  //Divider,
  Grid,
  TextField,
  //form,
  //Typography,
  makeStyles
} from '@material-ui/core';
import api from '../../../../api/Axios';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    //paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

const AddSubscriptionView = ({ props, className, ...rest }) => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state,"hiiiiii")
  //const navigate = useNavigate();
  const role = localStorage.getItem('role');
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [values, setValues] = useState({
    startDate: '',
    endDate: '',
    noOfChildren: '',
    createdBy: role,
    createdDate: today,
    modifiedBy: '',
    modifiedDate: '',
    isActive: false,
    schoolId: state.schoolId,
    subscriptionChildSchoolId: '',
    subscriptionName: '',
    utilisation: '',
    schoolId:state.schoolId
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  
const redirect_sub_school = schoolId => {
    //navigate('/app/manage/subs_school');
    navigate('/app/manage/subscriptionDetail', { state: { schoolId } });
  };
  const handleSubmit = event => {
    event.preventDefault();
    api
      .addNewSubscriptionToSchool(values)
      .then(response => {console.log(response, 'response')
      redirect_sub_school(values.schoolId)})
      .catch(error => console.log(error, 'error'));

    // alert(`Subscription added successfully!!
    //         subscriptionName:${values.subscriptionName}
    //         startDate: ${values.startDate} 
    //         endDate: ${values.endDate}
    //         numberOfChildren: ${values.noOfChildren}
    //         schoolId: ${values.schoolId}`);
    //      console.log("Valuessss",values)   

    /* const token=localStorage.getItem('Auth');
    const t=token.replace(/['"]+/g, '')
    const Auth=`Bearer ${t}`;
    console.log(Auth)
    console.log(values.stockName)
    
    const data = {
      gameName: values.gameName,
      gameType: values.gameType,
      gameCode:values.gameCode,
      subject:values.subject,
      

    };
    
    
   
    axios.post('http://localhost:3000/transaction', data ,
    {
      headers:{
        'Authorization':Auth,
        'Accept':'application/json',
        'content-Type':'application/json'
      }
    })
      .then(res => {
        console.log(res);
       
        if(res.data.error!=="Please authenticate")
        {
          console.log(res.data.balance);
          localStorage.setItem("Balance",res.data.user.balance)
          navigate('/app/dashboard', { replace: true });
          
         

        }
        else
        {
          alert('You are not Allowed for Transaction ,Authentication needed')
        }
       
      });*/
  console.log("Adding subscriptionsss",values.schoolId)
      
  };

  return (
    <div style={{ alignItems: 'center' }}>
      {/* <span 
            className={classes.title}
            align='center' 
            style={{color: '#e91e63', }}
        >School Name 1 - Subscription
        </span> */}
      <form
        autoComplete="off"
        align="center"
        noValidate
        className={clsx(classes.root, className)}
        {...rest}
      >
       

        <Card>
          <CardContent alignItems="center">
          <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Subscription Name"
                name="subscriptionName"
                onChange={handleChange}
                required
                value={values.subscriptionName}
                variant="outlined"
              ></TextField>
            </Grid>
            <br/>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="date"
                label="Start Date"
                name="startDate"
                type="date"
                onChange={handleChange}
                required
                value={values.startDate}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <br />
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="date"
                label="End Date"
                name="endDate"
                type="date"
                onChange={handleChange}
                required
                value={values.endDate}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <br />
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Number Of Children"
                name="noOfChildren"
                onChange={handleChange}
                required
                value={values.noOfChildren}
                variant="outlined"
              ></TextField>
            </Grid>
            <br />
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="School ID"
                name="schoolId"
                // onChange={handleChange}
                disabled={true}
                required
                value={state.schoolId}
                variant="outlined"
              ></TextField>
            </Grid>
            <br />
          </CardContent>
          <card>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </card>
        </Card>
      </form>
    </div>
  );
};

AddSubscriptionView.propTypes = {
  className: PropTypes.string
};

export default AddSubscriptionView;
