import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';

const GameRewardLevel = ({
  gameLevelRewardPoints,
  handleGameLevelRewardPoints
}) => {
  return (
    <div>
      {gameLevelRewardPoints.map((row, index) => (
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
          spacing={2}
          key={index}
        >
          <Grid item xs={4} md={4}>
            <Typography style={{ textAlign: 'center' }}>
              Level {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <TextField
              variant="outlined"
              margin="dense"
              name="gameRewardPoint"
              fullWidth
              value={row.gameRewardPoint}
              onChange={event => handleGameLevelRewardPoints(event, index)}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default GameRewardLevel;
