import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  makeStyles,
  MenuItem,
  CardActions,
  MobileStepper
} from '@material-ui/core';
import axios from 'axios';
import DragAndDrop from './DragAndDrop';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  cardHeader: {
    textAlign: 'center',
    color: '#e91e63',
    fontSize: 25
  }
}));

const GameInformation = ({
  files,
  setFiles,
  step,
  navigation,
  gameInformationData,
  handleGameInformationChanges,
  activeStep,
  handleNext,
  handleBack,
  ...rest
}) => {
  const classes = useStyles();

  const props = { files, setFiles, step };
  console.log(gameInformationData, 'gameInformationData');
  return (
    <div>
      <form autoComplete="off" noValidate className={classes.root} {...rest}>
        <Card style={{ margin: 'auto' }}>
          <CardContent style={{ paddingTop: '8px' }}>
            <MobileStepper
              variant="dots"
              steps={4}
              position="static"
              activeStep={activeStep}
              style={{
                float: 'right',
                maxWidth: 400,
                flexGrow: 1
              }}
            />
            <div>Game Information</div>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  margin="dense"
                  fullWidth
                  label="Description"
                  name="description"
                  onChange={handleGameInformationChanges}
                  multiline
                  rows={5}
                  value={gameInformationData.description}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  margin="dense"
                  fullWidth
                  label="Objectives"
                  name="objectives"
                  onChange={handleGameInformationChanges}
                  multiline
                  rows={5}
                  required
                  value={gameInformationData.objectives}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Outcome"
                  name="outcome"
                  value={gameInformationData.outcome}
                  onChange={handleGameInformationChanges}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Game Tech"
                  name="gameTech"
                  value={gameInformationData.gameTech}
                  onChange={handleGameInformationChanges}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ float: 'right' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigation.previous();
                handleBack();
              }}
              style={{
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              Back
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigation.next();
                handleNext();
              }}
              style={{
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              Save & Continue
            </Button>
          </CardActions>
        </Card>
      </form>
    </div>
  );
};

GameInformation.propTypes = {
  className: PropTypes.string
};

export default GameInformation;
