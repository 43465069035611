import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import api from '../../api/Axios';
//import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    }
  });

  //console.log(formik, 'formik values');
  const loginDetail = {
    data: [
      {
        id: 1,
        email: 'abc@abc.com',
        password: 'abc',
        role: 'superadmin',
        username: 'Amit'
      },
      {
        id: 2,
        email: 'def@def.com',
        password: 'def',
        role: 'siteadmin',
        username: 'Mahendra'
      },
      {
        id: 3,
        email: 'ghi@ghi.com',
        password: 'ghi',
        role: 'user',
        username: 'Richa'
      }
    ]
  };
  //const [token, setToken] = useState('');
  const [loginDetails, setLoginDetails] = useState({
    password: 'admin@super.com',
    username: 'admin@super.com'
  });
  //console.log(loginDetails, 'loginDetails');
  //password: "abcdef121"
  //username: "hello@abc.com"
  const [loginValues, setLoginValues] = useState({
    loginData: {
      email: '',
      password: '',
      role: ''
    }
  });

  const handleAuthentication = (/* e, */ values) => {
    //e.preventDefault();
    console.log(values, 'Login pageeee');
    //console.log('loginDetails', loginDetails);
    /* axios
      .post(
        'http://kredooserviceapi-env-dev.us-east-2.elasticbeanstalk.com/users/authenticate',
        {
          password: 'admin@super.com',
          username: 'admin@super.com'
        },
        {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      )
      .then(res => {
        setToken(res.data.response.body.token);
        localStorage.setItem('token', res.data.response.body.token);
        console.log(res, 'res');
      })
      .catch(err => console.log(err, 'err'));
    console.log(token, 'token');
    localStorage.setItem('role', 'superadmin');
    //localStorage.setItem('token', token);
    navigate('/app/dashboard', { replace: true }); */
    api
      //.authenticateUser(loginDetails)
      .authenticateUser(values)
      .then(response => {
        //setToken(response.data.response.body.token);
        localStorage.setItem(
          'token',
          JSON.stringify(response.data.response.body.token)
        );
        localStorage.setItem(
          'userId',
          JSON.stringify(response.data.response.body.user?.userId)
        );
        localStorage.setItem(
          'createdBy',
          JSON.stringify(response.data.response.body.user?.createdBy)
        );
        
        //localStorage.setItem('token', token);
        localStorage.setItem('role', 'superadmin');
        console.log(
          response,
          '===========================Login====================='
        );
        api.apiCall();
        navigate('/app/dashboard', { replace: true });
      })
      .catch(error => {
        console.log(error.request, 'error');
        if (error.request.status === 500) {
          alert('Invalid Credential');
          navigate('/login');
        }
      });
    //localStorage.setItem('role', 'superadmin');
    //navigate('/app/dashboard', { replace: true });
  };

  //console.log(token, 'token from state');
  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              //email: '',
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              /* email */ username: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required')
            })}
            /* onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                let obj = JSON.stringify(values, null, 2);
                let userArray = loginDetail.data;
                let user = null;
                for (let i = 0; i < userArray.length; i++) {
                  let obj = userArray[i];
                  if (
                    values.email === userArray[i].email &&
                    values.password === userArray[i].password
                  ) {
                    user = userArray[i];
                  }
                }
                if (user != null) {
                  localStorage.setItem('username', user.username);
                  localStorage.setItem('role', user.role);
                  navigate('/app/dashboard', { replace: true });
                }
                setSubmitting(false);
              }, 400);
            }} */
            onSubmit={values => {
              handleAuthentication(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form /* onClick={handleAuthentication} */
                onSubmit={handleSubmit}
              >
                {console.log(values, 'values')}
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>

                <TextField
                  //error={Boolean(touched.email && errors.email)}
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  //helperText={touched.email && errors.email}
                  helperText={touched.username && errors.username}
                  label="Email Address"
                  margin="normal"
                  //name="email"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  //value={values.email}
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    //disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    //onClick={e => handleAuthentication(e)}
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Don&apos;t have an account?{' '}
                  <Link component={RouterLink} to="/register" variant="h6">
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
