import * as React from 'react';
import { useState } from 'react';
import {
  DataGrid,
  GridFilterToolbarButton,
  GridToolbarContainer
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import Icon from '@material-ui/core/Icon';
//import SaveIcon from '@material-ui/icons/View';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#008080',
      color: 'white'
      //display: 'flex'
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

const LicenseListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const redirect = () => {
    navigate('/app/dashboard');
  };
  const [values, setValues] = useState({
    school_search: ''
  });

  const columns = [
    {
      field: 'child',
      headerName: 'Child Name',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'grade',
      headerName: 'Grade',
      width: 140,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'section',
      headerName: 'Section',
      width: 140,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'license_details',
      headerName: 'License Details',
      width: 240,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    },
    {
      field: 'plan_assigned',
      headerName: 'Plan Assigned',
      flex: 0.5,
      /* width: 225 , */ headerClassName: 'super-app-theme--header',
      headerAlign: 'center'
    }
  ];

  const rows = [
    {
      id: 1,
      child: 'San',
      grade: 'Nursery',
      section: 'Nursery A',
      license_details: '18-03-2020 to 17-03-2021',
      plan_assigned: 'Level 1'
    },
    {
      id: 2,
      child: 'Dugga',
      grade: 'Nursery',
      section: 'Nursery B',
      license_details: '18-03-2020 to 17-03-2021',
      plan_assigned: 'Level 1'
    }
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 420, width: '100%' }} className={classes.root}>
      {/* <div><span className={classes.title}  style={{color: '#e91e63', }}>
    Manage / Schools / License </span></div> */}
      {/* <br/> */}
      <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
        <span
          className={classes.title}
          align="center"
          style={{
            color: '#e91e63',
            /* justifyContent:'center', */ fontSize: 25
          }}
        >
          License Name{' '}
        </span>
        {/* <span style={{float: 'right'}}>
        <Tooltip title="Go to Home" >
        <Button
          color="primary"
          variant="contained"
          startIcon={<HomeIcon />}
          onClick={redirect}
        >
          Home
        </Button>
        </Tooltip>
    </span> */}
      </div>
      {/* <div>      
      <div style={{textAlign:'right'}}>
        <Tooltip title="Go to Home">
        <Button
        color="primary"
        variant="contained"
        startIcon={<HomeIcon />}
        onClick={redirect}
      >
      Home
      </Button>
      </Tooltip></div>
      <br />
    </div> */}
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            pageSize="5"
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default LicenseListView;
