import React,{useState}  from 'react';
import { 
    Typography, 
    Breadcrumbs as UIBreadCrumbs, 
    Link 
} from '@material-ui/core';
import { 
    useLocation, 
    useNavigate 
} from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlined from '@material-ui/icons/FiberManualRecordOutlined';
import {CHANGE_GAME_DETAIL_INDEX} from '../../../actions';

const BreadCrumbs = (props) => {
  const dispatch=useDispatch();
  const isShowIndividualGame=useSelector(state=>state.individualGame);
  const [activeBreadCrumbIndex,setBreadCrumbIndex]=useState(isShowIndividualGame.activeIndex);
    const location = useLocation();
    const navigate = useNavigate();
    const pathnames = location.pathname.split('/').filter(x=>x);
    const pathnamesCapitalized = pathnames.map(x=>{
      const firstLetter = x.charAt(0).toUpperCase();
      const rest = x.slice(1);
      return `${firstLetter}${rest}`});
    //const heading = pathnames[pathnames.length - 1];
    const handleClick=(index)=>{
      setBreadCrumbIndex(index);
      dispatch(CHANGE_GAME_DETAIL_INDEX(index));
    }

    const showGameSteps=()=>{
      let element='';
      switch(activeBreadCrumbIndex){
          case 1:
          element= [
            <div>
         <FiberManualRecord style={{color:"rgba(0, 0, 0, 0.26)"}} onClick={()=>handleClick(1)} />
            </div>,
            <div>
            <FiberManualRecordOutlined onClick={()=>handleClick(2)} />
            </div>,
           <div><FiberManualRecordOutlined onClick={()=>handleClick(3)} /></div>
          ];
              break;
          case 2:
              element= [
                <div>
             <FiberManualRecord style={{color:"#e91e63"}} onClick={()=>handleClick(1)} />
                </div>,
                <div>
                <FiberManualRecord style={{color:"rgba(0, 0, 0, 0.26)"}} onClick={()=>handleClick(2)} />
                </div>,
               <div><FiberManualRecordOutlined onClick={()=>handleClick(3)} /></div>
              ]
              break;
          case 3:
              element=[
                <div>
             <FiberManualRecord style={{color:"#e91e63"}} style={{color:"#e91e63"}} onClick={()=>handleClick(1)} />
                </div>,
                <div>
                <FiberManualRecord style={{color:"#e91e63"}} onClick={()=>handleClick(2)} />
                </div>,
               <div><FiberManualRecord style={{color:"rgba(0, 0, 0, 0.26)"}} onClick={()=>handleClick(3)} /></div>
              ];
              break;
          default:
              break;
  
      }
      return element;
     }
  return (
    <div id="path-breadcrum">
      <UIBreadCrumbs color="inherit" aria-label="breadcrumb" >
        {pathnamesCapitalized.map((name, index) => {
            const routeTo = `/${pathnamesCapitalized.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnamesCapitalized.length - 1;
            //const heading = pathnames[pathnames.length - 1];
            return (
                isLast ?
                <Typography>{name}</Typography>
                : <Link style={{cursor: 'pointer'}} onClick={()=> navigate(routeTo)} >{name}</Link>
              );
        })}
      </UIBreadCrumbs>
      {isShowIndividualGame.isShow && <div id="viewGameSteps"><UIBreadCrumbs style={{float:'right'}} separator="-" aria-label="breadcrumb1">{showGameSteps()}</UIBreadCrumbs></div> }
    </div>
  );
};

export default BreadCrumbs;