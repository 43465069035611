import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  IconButton,
  Button,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  form,
  Typography,
  makeStyles,
  Fab,
  Paper,
  MenuItem
} from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  //padding: '20px',
  paddingTop: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  //borderColor: '#00e676'
  borderColor: '#008080'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const DragAndDrop = ({
  image,
  setImage,
  step,
  setGamePackageData,
  gamePackageData,
}) => {
  // console.log(gamePackageData, 'gamePackageData');
  console.log(image, 'files images');
  
  //const [files, setFiles] = useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    open
  } = useDropzone({
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
       uploadGameImage(acceptedFiles)
    }
  });
  //console.log(acceptedFiles);
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const removeImage = (indexToRemove) => {
    // Create a new array without the image at the specified index
    const updatedImages = image.filter((_, index) => index !== indexToRemove);
  
    // Set the updated array using setImage
    setImage(updatedImages);
  };
  const thumbs = image?.map((file,i) => (
    <div style={thumb} key={file.name}>
      <span onClick={() => removeImage(i)} style={{cursor:'pointer'}}> &#10006;</span>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      image.forEach(file => URL.revokeObjectURL(file.preview));
      console.log(image)
    },
    [image]
  );

  const uploadGameImage = (localAcceptedFiles) => {
    const newFiles = localAcceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      console.log(newFiles)
    setImage(image.concat(newFiles));
   };

  const filepath = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
console.log(image.length,"image")
  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <IconButton color="primary" onClick={open}>
          <CloudUploadOutlinedIcon fontSize="large" color="primary" />
        </IconButton>
        <p>
          Drag and drop your {step.id === 'gamePackage' ? 'package' : 'images'}{' '}
          or{' '}
          {/* <button type="button" onClick={open}>
            browse
          </button> */}
          <Button
            style={{
              color: '#e91e63',
              textTransform: 'lowercase',
              fontSize: '1rem'
            }}
            onClick={open}
          >
            browse
          </Button>
        </p>
      </div>
      <div style={{ textAlign: 'right' }}>
        {step.id === 'gamePackage'
          ? '*Upload the game package'
          : '*Upload 2 images'}
      </div>
      <aside>
        <hr />
        <CardHeader
          style={{
            backgroundColor: '#e91e63',
            //width: '450px',
            color: 'white',
            padding: 1
          }}
          title={
            step.id === 'gamePackage'
              ? 'Game Package uploaded'
              : 'Image uploaded'
          }
        /> 
        {step.id === 'gamePackage' ? (
          <ul>{gamePackageData?.gamePackageImages?.length === 0 ? 'No Game Packages are uploaded.' : filepath}</ul>

        ) : (
          <ul>
            {image.length === 0
              ? 'No images are uploaded.'
              : ""}
          </ul>
        )}
      </aside>
     
      <aside style={thumbsContainer}>{thumbs}</aside>
    </div>
  );
};
export default DragAndDrop;
