import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button }
  from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Link } from 'react-router-dom';

export function CongratulationDialog(props) {
  const { open, handleClose, navigation, setActiveStep } = props;
  const useStyles = makeStyles(theme => ({
    dialogSecondaryButton: {
      color: "black"
    },

    container: {
      paddingTop: '0px',
      paddingBottom: '0px',
      backgroundColor: '#36bf55',
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    actions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    button: {
      backgroundColor: '#e91e63',
    }
  }));
  const classes = useStyles();

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: '50px',
    },
    '& .MuiDialogActions-root': {
      padding: '1px',
    },
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle className={classes.container}>
        <Grid container justify='space-between' direction='row' alignContent='center' alignItems='center'>
          <Grid item>{children} </Grid>
        </Grid>
      </DialogTitle>
    );
  };

  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <BootstrapDialogTitle>Congratulations</BootstrapDialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {props.value}
        </DialogContentText>
        <DialogActions className={classes.actions}>
          <Link to='/app/config/game/viewgame'>  <Button color="secondary" variant="contained" style={{
            textTransform: 'none',
            fontSize: '1rem'
          }} onClick={{}}>View Games</Button></Link>
          <Button color="secondary" variant="contained" style={{
            textTransform: 'none',
            fontSize: '1rem'
          }} onClick={() => { navigation.go(0); setActiveStep(0) }}>Create Games</Button>
        </DialogActions>
      </DialogContent>

    </BootstrapDialog>
  );
}

CongratulationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};
