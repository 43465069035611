import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, Avatar, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  root: {
    //minWidth: 275,
    padding: 20,
    marginRight: 15,
    marginTop: 15
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    align: 'center'
  },
  pos: {
    marginBottom: 12,
  },
});

export default function ReportCard() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const today = new Date();
  //const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
  const [grade, setGrade] = React.useState('Nursery');

  const handleChange = (event) => {
    setGrade(event.target.value);
  };

  const sensorialConceptOne = [
    {id: '1', game: 'Name of the skill 1'},
    {id: '2', game: 'Name of the skill 2'},
    {id: '3', game: 'Name of the skill 3'},
    {id: '4', game: 'Name of the skill 4'},
    {id: '5', game: 'Name of the skill 5'},
    {id: '6', game: 'Name of the skill 6'}
  ];

  const sensorialConceptTwo = [
    {id: '7', game: 'Name of the skill 7'},
    {id: '8', game: 'Name of the skill 8'},
    {id: '9', game: 'Name of the skill 9'},
    {id: '10', game: 'Name of the skill 10'},
    {id: '11', game: 'Name of the skill 11'},
    {id: '12', game: 'Name of the skill 12'}
  ];

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Box
          display="flex"
          //mx="auto"
          //m={3}
          //mb={1}
          //mt={1}
          //padding="20"
          //align="center"
        >
          {date}
        </Box>
        <Box m={1}>
          <Typography align="right">
            <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Grade</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={grade}
              onChange={handleChange}
              label="Grade"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Nursery"}>Nursery</MenuItem>
              <MenuItem value={"LKG"}>LKG</MenuItem>
              <MenuItem value={"UKG"}>UKG</MenuItem>
              </Select>
              </FormControl>
          </Typography>
        </Box>
        {/* <Typography>{date}</Typography> */}
        <Box >
          <Typography  align="center" variant="h5" component="h1">
            Progress Report 
          </Typography>
        </Box>
        <br/>
        <Box 
          display="flex"
          m={3}
          //mb={1}
          mt={1}
          //padding="20"
        >
          <Box 
            //align="center" 
            //display="flex"
            //justifyContent="center"
            m={3}
            mb={1}
            //mt={3}
          >
            <Avatar src="/static/images/avatars/avatar_6.png" align="left"/>
            {/* <Typography variant="h5" component="h1">
              Progress Report
            </Typography> */}
            {/* <Box align="center" display="flex"
              justifyContent="center"> */}
          </Box>
          <Box
            display="flex"
            m={3}
            mb={1}
            ml={20}
          >
            <Box
              //display="flex"
              //align="center"
              //justifyContent="center"
              //mt={3}  
            >
              <Typography /* variant="h5" component="h2" */ >
                Child Name: San
              </Typography>
              <Typography /* className={classes.pos} color="textSecondary" */>
                Grade: Nursery
              </Typography>
            </Box>
            <Box
              //display="flex"
              //align="center"
              //justifyContent="center"
              //mt={5}
              ml={20}
            >
              <Typography /* variant="h5" component="h2" */>
                Start Date:
              </Typography>
              <Typography /* className={classes.title} color="textSecondary" gutterBottom */>
                End Date:
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* </Box> */}
        <hr />
        <Typography variant="h5" component="p">
          Sensorial
        </Typography>
          {/* <br /> */}
        <Typography>
          Concept 1
        </Typography>
        <br/>
        <Grid container spacing={4}>
          {sensorialConceptOne.map(skill =>(
            <Grid item key={skill.id} xs={12} sm={4} md={2}>
              <Card>{skill.game}</Card>
            </Grid>
          ))}
          {/* <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 1</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 2</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 3</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 4</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 5</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 6</Card>
          </Grid> */}
          {/* <Card width="50%">
          Name of the skill 7
          </Card>
          <Card width="50%" variant="outlined" width="16px" margin={30}>
          Name of the skill 8
          </Card> */}
        </Grid>
        <br />
        <Typography>
          Concept 2
        </Typography>
        <br/>
        <Grid container spacing={4}>
          {sensorialConceptTwo.map(skill =>(
              <Grid item key={skill.id} xs={12} sm={4} md={2}>
                <Card>{skill.game}</Card>
              </Grid>
            ))
          }
          {/* <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 7</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 8</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 9</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 10</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 11</Card>
          </Grid>
          <Grid xs={12} sm={6} md={2}>
            <Card>Name of the skill 12</Card>
          </Grid> */}
          {/* <Card width="50%">
          Name of the skill 7
          </Card>
          <Card width="50%" variant="outlined" width="16px" margin={30}>
          Name of the skill 8
          </Card> */}
        </Grid>
        <br/>
        <Typography variant="h5" component="p">
          Language
        </Typography>
          {/* <br /> */}
        <Typography>
          Concept 1
        </Typography>
        <br/>
        <Grid container spacing={4}>
          {sensorialConceptOne.map(skill =>(
            <Grid item key={skill.id} xs={12} sm={4} md={2}>
              <Card>{skill.game}</Card>
            </Grid>
            ))
          }
          {sensorialConceptTwo.map(skill =>(
            <Grid item key={skill.id} xs={12} sm={4} md={2}>
              <Card>{skill.game}</Card>
            </Grid>
            ))
          }
        </Grid>
        {/* <Card width="50%">
          Name of the skill 3
        </Card>
        <Paper width="50%" variant="outlined" width="16px" margin={30}>
          Name of  the skill 1
        </Paper> */}
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
