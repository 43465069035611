import React, { useState, useEffect, useContext } from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  form,
  Typography,
  makeStyles,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem
} from '@material-ui/core';
import { ConfigureContext } from '../../../Context/ConfigureContext';
import api from '../../../../../api/Axios';
import Swal from 'sweetalert2';
import { emailRegex, reportingToName, roleName } from 'src/utils/constants';
import apis from 'src/api/apis';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  title: {
    textAlign: 'center',
    color: '#e91e63',
    fontSize: 25
  }
}));

const CreateAdminUserView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');
  const [state, setState] = useState('');
  const [stateError, setStateError] = useState('');
  const [country, setCountry] = useState('');
  const [countryError, setCountryError] = useState('');
  const [pincodeError, setPincodeError] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [pincode, setPincode] = useState('');
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [roleAssign, setRoleAssign] = useState('');
  const [reportingToAssign, setReportingToAssign] = useState('Byshadh');
  const [address1Error, setAddress1Error] = useState('');

  const { userName } = useParams();
  useEffect(() => {
    if (userName) {
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json' // Added single quotes around 'application/json'
          // Authorization: token ? `Bearer ${token}` : null // Check if token exists before using it
        }
      };
      apis
        .get(`/users/get/${userName}`, config)
        .then(res => {
          setFirstName(res.data.response.body?.firstName || '');
          setLastName(res.data.response.body?.lastName || '');
          setEmail(res.data.response.body?.userEmail || '');
          setMobileNumber(res.data.response.body?.userMobNumber || '');
          setUsername(res.data.response.body?.uname || '');
          setCountry(res.data.response.body?.country || '');
          setState(res.data.response.body?.state || '');
          setCity(res.data.response.body?.city || '');
          setAddress1(res.data.response.body?.address || '');
          setPincode(res.data.response.body?.pincode || '');
          setRoleAssign(res.data.response.body?.roles[0]?.name || '');
          setReportingToAssign(res.data.response.body.reportingToName || '');
        })
        .catch(err => {
          if (err?.response?.data?.response?.body?.message) {
            toast.error(err.response.data.response.body.message);
          } else {
            toast.error('Server Error');
          }
        });
    }
  }, [location.pathname]);

  const handleSubmit = event => {
    event.preventDefault();
    let hasError = false;
    if (firstName.length < 1) {
      setFirstNameError('First name is required');
      hasError = true;
    }
    if (lastName.length < 1) {
      setLastNameError('Last name is required');
      hasError = true;
    }
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email');
      hasError = true;
    }
    if (
      password.length < 1 &&
      location.pathname === '/app/config/adminUser/createadminuserview'
    ) {
      setPasswordError('Password is required');
      hasError = true;
    }
    if (username.length < 1) {
      setUsernameError('Username is required');
      hasError = true;
    }
    if (mobileNumber.length < 1) {
      setMobileError('Mobile number is required');
      hasError = true;
    }
    if (address1.length < 1) {
      setAddress1Error('Address is required');
      hasError = true;
    }
    if (city.length < 1) {
      setCityError('City is required');
      hasError = true;
    }
    if (state.length < 1) {
      setStateError('State is required');
      hasError = true;
    }
    if (country.length < 1) {
      setCountryError('Country is required');
      hasError = true;
    }
    if (pincode.length < 1) {
      setPincodeError('Pincode is required');
      hasError = true;
    }

    if (hasError) {
      return;
    }
    const bodyData = {
      firstName: firstName,
      lastName: lastName,
      userEmail: email,
      pwd: password,
      uname: username,
      userMobNumber: mobileNumber,
      address: address1 + ' ' + address2,

      city,
      state,
      country,
      pincode,
      isSuperUser: roleAssign === 'Super Admin' ? true : false,
      isSiteAdmin: roleAssign === 'Site Admin' ? true : false,
      reportingToName: reportingToAssign,
      isActive: true,
      isMobileUser: false,
      isWebUser: false,
      emailVerified: true,
      regDate: new Date()
    };
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json' // Added single quotes around 'application/json'
        // Authorization: token ? `Bearer ${token}` : null // Check if token exists before using it
      }
    };

    if (location.pathname === '/app/config/adminUser/createadminuserview') {
      apis
        .post('/users/register', bodyData, config)
        .then(() => {
          Swal.fire(
            'Congratulation',
            'Admin User added successfully',
            'success'
          );
          navigate('/app/config/adminUser/viewusers');
        })
        .catch(err => {
          console.log(err.response.data, 'hiiiiiiiiiiiiiiiiiiii');
          if (err?.response?.data?.response?.body?.message) {
            toast.error(err.response.data.response.body.message);
          } else {
            toast.error('Server Error');
          }
        });
    } else {
      bodyData.userId = userName;
      apis
        .post('/users/editAdmin', bodyData, config)
        .then(() => {
          Swal.fire(
            'Congratulation',
            'Admin User updated successfully',
            'success'
          );
          navigate('/app/config/adminUser/viewusers');
        })
        .catch(err => {
          if (err?.response?.data?.response?.body?.message) {
            toast.error(err.response.data.response.body.message);
          } else {
            toast.error('Server Error');
          }
        });
    }

    setOpenDialogBox(false);
  };

  const handleRoleDialogBox = () => {
    setOpenDialogBox(true);
  };
  const handleCloseDialogBox = () => {
    setOpenDialogBox(false);
  };

  const handleRoleAssign = e => {
    setRoleAssign(e.target.value);
  };
  const handleReportingToAssign = e => {
    setReportingToAssign(e.target.value);
  };

  const handleDeleteRole = () => {
    setRoleAssign('');
    setReportingToAssign('');
  };
  const handleChange = () => {};
  return (
    <div>
      <div className={classes.title}>
        {location.pathname === '/app/config/adminUser/createadminuserview'
          ? 'Create Admin User'
          : 'Edit User -' + ' ' + firstName + ' ' + lastName}
      </div>
      <form
        autoComplete="off"
        //align="center"
        noValidate
        className={classes.root}
        //className={clsx(classes.root, className)}
        //{...rest}
      >
        <Card /* container spacing={4} */ style={{ margin: 'auto' }}>
          {/* <CardHeader
            subheader="Please enter the new Admin User details"
            title="New Admin User Details"
          />
          <Divider /> */}
          <CardContent style={{ paddingTop: '8px', paddingBottom: 0 }}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextField
                  error={firstNameError.length > 0}
                  fullWidth
                  margin="dense"
                  label="First Name"
                  name="firstName"
                  onChange={e => {
                    setFirstName(e.target.value);
                    setFirstNameError('');
                  }}
                  helperText={firstNameError}
                  required
                  value={firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={lastNameError.length > 0}
                  fullWidth
                  margin="dense"
                  label="Last Name"
                  name="lastName"
                  onChange={e => {
                    setLastName(e.target.value);
                    setLastNameError('');
                  }}
                  helperText={lastNameError}
                  required
                  value={lastName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={emailError.length > 0}
                  margin="dense"
                  label="E-mail Id"
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError('');
                  }}
                  helperText={emailError}
                  name="userEmail"
                  required
                  value={email}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={passwordError.length > 0}
                  type="password"
                  margin="dense"
                  label="Password"
                  name="pwd"
                  onChange={e => {
                    setPassword(e.target.value);
                    setPasswordError('');
                  }}
                  helperText={passwordError}
                  required
                  value={password}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={usernameError.length > 0}
                  margin="dense"
                  label="User Name"
                  name="uname"
                  onChange={e => {
                    setUsername(e.target.value);
                    setUsernameError('');
                  }}
                  required
                  helperText={usernameError}
                  value={username}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={mobileError.length > 0}
                  margin="dense"
                  label="Mobile Number"
                  name="userMobNumber"
                  onChange={e => {
                    setMobileNumber(e.target.value);
                    setMobileError('');
                  }}
                  helperText={mobileError}
                  required
                  value={mobileNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={address1Error.length > 0}
                  margin="dense"
                  label="Address 1"
                  name="address"
                  onChange={e => {
                    setAddress1(e.target.value);
                    setAddress1Error('');
                  }}
                  helperText={address1Error}
                  required
                  value={address1}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Address 2"
                  name="address1"
                  onChange={e => {
                    setAddress2(e.target.value);
                  }}
                  value={address2}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={cityError.length > 0}
                  margin="dense"
                  label="City"
                  name="city"
                  onChange={e => {
                    setCity(e.target.value);
                    setCityError('');
                  }}
                  helperText={cityError}
                  required
                  value={city}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={stateError.length > 0}
                  margin="dense"
                  label="State"
                  name="state"
                  onChange={e => {
                    setState(e.target.value);
                    setStateError('');
                  }}
                  helperText={stateError}
                  required
                  value={state}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={countryError.length > 0}
                  margin="dense"
                  label="Country"
                  name="country"
                  onChange={e => {
                    setCountry(e.target.value);
                    setCountryError('');
                  }}
                  required
                  helperText={countryError}
                  value={country}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={pincodeError.length > 0}
                  margin="dense"
                  label="Pincode"
                  name="pincode"
                  onChange={e => {
                    setPincode(e.target.value);
                    setPincodeError('');
                  }}
                  required
                  helperText={pincodeError}
                  value={pincode}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>

          <Card>
            <CardHeader
              title="User Role"
              style={{ textAlign: 'center', paddingBottom: '0px' }}
            />
            {(roleAssign.length < 1 || reportingToAssign.length < 1) && (
              <CardActions
                style={{
                  justifyContent: 'flex-end',
                  paddingRight: '24px',
                  paddingTop: '0px'
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleRoleDialogBox}
                  style={{
                    textTransform: 'none',
                    fontSize: '1rem'
                  }}
                >
                  Add Role
                </Button>
              </CardActions>
            )}
            <div>
              <Dialog
                //maxWidth="md"
                fullWidth
                open={openDialogBox}
                onClose={handleCloseDialogBox}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                  style: {
                    borderRadius: 10,
                    borderWidth: 5,
                    borderStyle: 'solid',
                    borderColor: '#e91e63'
                  }
                }}
                BackdropProps={{
                  style: {
                    backgroundColor: 'rgba(0,0,0,0.1)'
                  }
                }}
              >
                <div style={{ backgroundColor: '#008080' }}>
                  <DialogTitle
                    style={{
                      color: 'white'
                    }}
                    align="center"
                    id="form-dialog-title"
                  >
                    Add Role
                  </DialogTitle>
                </div>
                <DialogContent>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {/* <div>Role</div> */}
                    <TextField
                      name="role"
                      id="role"
                      label="Role"
                      placeholder="Select the type of Role"
                      //helperText="Full width!"
                      fullWidth
                      select
                      required
                      margin="dense"
                      value={roleAssign}
                      variant="outlined"
                      onChange={handleRoleAssign}
                    >
                      {roleName.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <TextField
                    name="reportingTo"
                    id="reportingTo"
                    label="Reporting To"
                    fullWidth
                    select
                    required
                    margin="dense"
                    value={reportingToAssign}
                    variant="outlined"
                    onChange={handleReportingToAssign}
                  >
                    {reportingToName.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleCloseDialogBox}
                    color="primary"
                    style={{
                      textTransform: 'none',
                      fontSize: '1rem'
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCloseDialogBox}
                    color="primary"
                    style={{
                      textTransform: 'none',
                      fontSize: '1rem',
                      backgroundColor: '#008080',
                      color: '#fff'
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            {/* <Grid container spacing={1}>
              <Grid item xs={12} ms={6}> */}
            <div
              style={{
                width: '100%',
                overflowX: 'auto',
                paddingLeft: '24px',
                paddingRight: '24px'
              }}
            >
              <table style={{ width: '100%' }}>
                <thead
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#008080',
                    color: 'white'
                  }}
                >
                  <tr
                    style={{
                      border: '1px solid #ddd',
                      minHeight: '56px',
                      maxHeight: '56px',
                      lineHeight: '56px',
                      fontSize: '0.875rem',
                      fontWeight: 500
                    }}
                  >
                    <th>Role</th>
                    <th>Reporting To</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(roleAssign.length > 1 || reportingToAssign.length > 1) && (
                    <tr
                      style={{
                        textAlign: 'center',
                        border: '1px solid #ddd',
                        padding: '15px'
                      }}
                    >
                      <td>{roleAssign}</td>
                      <td>{reportingToAssign}</td>
                      <td>
                        <Button
                          onClick={handleRoleDialogBox}
                          className="mr-2"
                          variant="outlined"
                        >
                          <EditIcon style={{ color: '#e91e63' }} />
                        </Button>
                        <Button onClick={handleDeleteRole} variant="outlined">
                          <DeleteIcon style={{ color: '#e91e63' }} />
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/*  </Grid>
            </Grid> */}
            <br />
          </Card>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              color="default"
              variant="contained"
              //justifyContent="flex-end"
              onClick={handleSubmit}
              style={{
                textTransform: 'none',
                fontSize: '1rem',
                backgroundColor: '#008080',
                color: '#fff'
              }}
            >
              Close
            </Button>
            <Button
              color="primary"
              //justifyContent="flex-end"
              variant="contained"
              onClick={handleSubmit}
              style={{
                textTransform: 'none',
                fontSize: '1rem'
                //backgroundColor: 'primary'
                //color: 'primary'
              }}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    </div>
  );
};

CreateAdminUserView.propTypes = {
  className: PropTypes.string
};

export default CreateAdminUserView;
