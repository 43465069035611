import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  form,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft:theme.spacing(15),
    paddingRight:theme.spacing(3)
  }
}));

const DeletePlansView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [values,setValues]= useState({ 
    planName: '',
    planAgeGroup:'',
});

const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    /* const token=localStorage.getItem('Auth');
    const t=token.replace(/['"]+/g, '')
    const Auth=`Bearer ${t}`;
    console.log(Auth)
    console.log(values.stockName)
    
    const data = {
      gameName: values.gameName,
      gameType: values.gameType,
      gameCode:values.gameCode,
      subject:values.subject,
      

    };
    
    
   
    axios.post('http://localhost:3000/transaction', data ,
    {
      headers:{
        'Authorization':Auth,
        'Accept':'application/json',
        'content-Type':'application/json'
      }
    })
      .then(res => {
        console.log(res);
       
        if(res.data.error!=="Please authenticate")
        {
          console.log(res.data.balance);
          localStorage.setItem("Balance",res.data.user.balance)
          navigate('/app/dashboard', { replace: true });
          
         

        }
        else
        {
          alert('You are not Allowed for Transaction ,Authentication needed')
        }
       
      });*/
  }



  return (
    <div>
    <div style={{textAlign:'center', marginLeft:'8%'}} >
    <span className={classes.title} align='center' style={{color: '#e91e63', }}>
    Delete Plan</span>
    </div>
    <br/>

    <form
      autoComplete="off"
      align='center'
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
    <Card container spacing={4}>
        <CardHeader subheader="Please enter the Plan details for deletion" title="Delete Plan"     />
       
        <Divider />
        <CardContent>
          <Grid  container  spacing={3}  >
          
            <Grid   item md={12}   xs={12} >
             
              <TextField
                fullWidth
                label="Plan Name"
                name="planName"
                onChange={handleChange}
                required
                value={values.planName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
           >
              <TextField
                fullWidth
                label="Plan Age Group"
                name="planAgeGroup"
                onChange={handleChange}
                required
                value={values.planAgeGroup}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
        
          <Button
            color="primary"
            
            variant="contained"
            onClick={handleSubmit}
          >
            Delete
          </Button>
        </Box>
      </Card>
    </form>
 </div>
  );
};

DeletePlansView.propTypes = {
  className: PropTypes.string
};

export default DeletePlansView;
